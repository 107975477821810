import styled from "styled-components";



const TextEditorStyle = styled.div`
position: relative;
.rdw-editor-main {
    background-color: #f5f6f9;
    border: 1px solid#eee;
    margin-bottom: 17px;
    padding: 7px 17px 7px 17px;
    line-height: 27px;
  }
  .public-DraftEditor-content {
    min-height: 300px;
  }
  .rdw-dropdown-optionwrapper {
    width: 100%!important;
  }
  select, textarea, input[type="text"] {
    margin: 0 !important;
    border-radius: 8px;
    border: 1px solid #F0F5F7;
    padding: 5px 20px;
    font-size: 15px;
    background: #F0F5F7;
    width: 100% !important;
    color: #696969;
    transition: all 0.3s ease-in-out 0s;
    box-shadow: none;
    height: 60px;
  }
  input, button, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  label {
    padding: 0;
    font-size: 17px;
    color: #202124;
    font-weight: 500;
    margin: 27px 0 10px;
    display: block;
  }
  .btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    letter-spacing: 0;
    padding: 9px 30px;
    font-size: 15px;
    line-height: 2;
    border-radius: 8px;
    -webkit-user-select: none;
    user-select: none;
    transition: all 0.3s ease-in-out 0s;
  }
  .btn, button {
    outline: none !important;
  }
  .btn__theme {
    color: #fff;
    background-color: #673ab7;
    border-color: #673ab7;
  }
  .App{
    position: relative;
    float: left;
    width: 100%;
    display: block;
    padding: 70px 0;
  }
  .App__tittle {
    text-align: center;
    font-size: 47px;
    font-weight: 700;
    margin-bottom: 17px;
  }
  .App__tittle span{
    color: #673ab7;
  }
  .post__list {
    margin: 37px 0 17px 0;
    border-bottom: 1px solid lavender;
    padding-bottom: 17px;
  }
  .post__list h2 {
    margin-bottom: 17px;
    font-size: 24px;
    font-weight: 600;
  }
  .post__list p {
    margin-bottom: 17px;
    font-size: 18px;
    font-weight: 400;
    color: #524c4c;
  }
  span.required {
    color: #f44336;
  }
  form.update__forms {
    border: 1px solid #1967d2;
    box-shadow: 0 6px 15px 0 rgb(64 79 104 / 5%);
    padding: 37px;
  }
  .errors{
    color: #f44336;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 17px;
  }
  .btn__add{
    margin: 37px auto;
    display: table;
    padding: 14px 37px 14px 37px;
    font-size: 20px;
    line-height: normal;
  }
  .post__description{
    position: relative;
  }
  iframe{
    width: 967px;
    height: 537px;
  }
  
  
  
  /* index.css */
  
  @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
  
  
  .clearfix{clear:both;}
  img {max-width: 100%;height: auto;}
  p{line-height: 1.8;font-family: 'Inter', sans-serif;}
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {font-family: 'Inter', sans-serif;}
  a{ font-family: 'Inter', sans-serif;  text-decoration: none;}
  li{font-family: 'Inter', sans-serif;}
  dl, ol, ul { padding: 0;}
  * {word-break: break-word;}
  *, *::before, *::after {box-sizing: border-box;}
  .align__center{text-align: center;}
  * {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
  }
  * {
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;
     box-sizing: border-box;
  }
  .container, .container-fluid {
    width: 100%;
      padding-right:15px;
      padding-left:15px;
      margin-right:auto;
      margin-left:auto
  }
  
  @media (min-width:768px) {
    .container { max-width:750px}
  }
  @media (min-width:992px) {
    .container {  max-width:970px}
  }
  @media (min-width:1200px) {
    .container {max-width:1200px}
  }
  
   p {
    font-size: 16px;
    color: #565252;
    line-height: 1.8;
    font-family: 'Inter', sans-serif;
  }
  
  
  
  
  
  
  /* Text Editor.css */
  Set content for font-families
  .ql-font-arial,
  .ql-font span[data-value="arial"]::before {
    font-family: Arial, sans-serif;
  }
  .ql-font-comic-sans,
  .ql-font span[data-value="comic-sans"]::before {
    font-family: "Comic Sans MS", cursive, sans-serif;
  }
  .ql-font-courier-new,
  .ql-font span[data-value="courier-new"]::before {
    font-family: "Courier New";
  }
  .ql-font-georgia,
  .ql-font span[data-value="georgia"]::before {
    font-family: Georgia, serif;
  }
  .ql-font-helvetica,
  .ql-font span[data-value="helvetica"]::before {
    font-family: Helvetica, sans-serif;
  }
  .ql-font-Inter,
  .ql-font span[data-value="Inter"]::before {
    font-family: 'Inter', sans-serif;
  }
  .ql-font-lucida,
  .ql-font span[data-value="lucida"]::before {
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  }
  .ql-font-Roboto,
  .ql-font span[data-value="Roboto"]::before {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  /* Set content for sizes */
  .ql-size-extra-small,
  .ql-size span[data-value="extra-small"]::before {
    font-size: 13px !important;
  }
  .ql-size-small,
  .ql-size span[data-value="small"]::before {
    font-size: 14px !important;
  }
  .ql-size-medium,
  .ql-size span[data-value="medium"]::before {
    font-size: 18px !important;
  }
  .ql-size-large,
  .ql-size span[data-value="large"]::before {
    font-size: 20px !important;
  }
  
  .ql-editor {
    min-height: 40vh;
    font-size: 16px;
    padding: 10px;
  }
  .App .ql-snow .ql-tooltip.ql-flip {
    width: auto;
    left: 17px!important;
    white-space: normal;
  }
  
   @page {
    margin: 1in;
  }
  
  @media print {
    body {
      background: none;
    }
    .container .ql-editor {
      width: 6.5in;
      height: 9in;
      padding: 0;
      margin: 0;
      box-shadow: none;
      align-self: flex-start;
    }
    .container .ql-toolbar.ql-snow {
      display: none;
    }
  } 
`


export default TextEditorStyle