import { Stack, Typography } from "@mui/material"
import { creators } from "./creators-data"
import Creator from "./Creator";
import { useBreakpoints } from "../../../../theme/breakpoints";



const Creators = () => {
    const {xs, sm, md, lg} = useBreakpoints()

    return (
        <Stack
        sx={{
            gap: '20px',
            bgcolor: '#F7F8FF',
            p: sm ? '2rem 1rem 5rem 1rem' : '2rem 2.5rem  5rem 2.5rem',
        }}
        >
            <Typography 
            sx={{
                color: 'primary.main', 
                fontSize: sm ? '1.5rem' : md ? '2rem' : '3rem',
                fontWeight: 800,
                textAlign: sm ? 'center' : 'left'
            }}
            >
            1,200 Creators 
            {sm ? '\u00A0' : <br />}
            rely on RENIMAIL:
            </Typography>

            <Typography
            sx={{
                fontSize: sm ? '1rem' : md ? '1.2rem' : '1.3rem',
                textAlign: sm ? 'center' : 'left'
            }}
            >
            Some of your favorite creators use Renimail 
            <br />
            to connect with their audience.
            </Typography>

            <Stack
            sx={{
                '& swiper-container': {
                    
                },
                '& swiper-slide': {
                    width: lg && 'fit-content !important'
                }
            }}
            >
            <swiper-container 
            slides-per-view={sm ? 1.4 :  md ? 3 : 4}
            space-between='20px'
            css-mode="true"
            >
            {
                creators.map( ({name, jobTitle, imageSrc}, k) => (
                    <swiper-slide
                    key={k}
                    >
                    <Creator
                    key={k}
                    name={name}
                    jobTitle={jobTitle}
                    imageSrc={imageSrc}
                    />
                    </swiper-slide>
                ) )
            }
            </swiper-container>
            </Stack>
        </Stack>
    )
}


export default Creators