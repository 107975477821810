import {Stack, Typography } from "@mui/material"
import { useState } from "react"
import { useEffect } from "react"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { useBreakpoints } from "../../../theme/breakpoints"
import { useLayoutContext } from "../../../contexts/layoutContext"
import { fetchData } from "../../../utils/functions/fetchData"
import CustomButton from "../../Button"
import Search from "../../Search"
import Layout from "../../Layouts/DashboardLayout"
import EmailsTable from "./EmailsTable"
import { MdEdit } from "react-icons/md"
import EmailsPageContext from "../../../contexts/emailsPageContext"
import { fetchFromLocalStorage, updateLocalStorage } from "../../../utils/functions/updateStorage"
import client from "../../../utils/axios"



export const EmailsPage = ({embed}) => {
    const navigate = useNavigate()
    const currentTabFromStorage = fetchFromLocalStorage('userSettings')?.emailsCurrentTab
    const [currentTab, setCurrentTab] = useState(currentTabFromStorage || 'overview')
    const {sm, md, mdOnly} = useBreakpoints()
    const {miniSideBar} = useLayoutContext()

    const [emailsLoading, setEmailsLoading] = useState(false)
    const [archivedEmailsLoading, setArchivedEmailsLoading] = useState(false)
    const [draftsLoading, setDraftsLoading] = useState(false)

    const [emails, setEmails] = useState([])
    const [archivedEmails, setArchivedEmails] = useState([])
    const [drafts, setDrafts] = useState([])

    const [totalEmails, setTotalEmails] = useState(0)
    const [totalArchivedEmails, setTotalArchivedEmails] = useState(0)
    const [totalDrafts, setTotalDrafts] = useState(0)

    // Current Pagination Page
    const [emailsPage, setEmailsPage] = useState(1)
    const [archivedPage, setArchivedPage] = useState(1)
    const [draftsPage, setDraftsPage] = useState(1)

    const [reloadEmails, setReloadEmails] = useState(false)
    const [reloadArchivedEmails, setReloadArchivedEmails] = useState(false)
    const [reloadDrafts, setReloadDrafts] = useState(false)




    const fetchEmails = async () => {
        setEmailsLoading(true)
        try{
            const response = await client.post('/getMails', {}, {noError: true, pagination: {page: emailsPage}, version: 3})

            response && response?.data ?
            setEmails( response.data.map( ({listData, real_date, subject, message, id}) => ({
                sentTo: listData?.name,
                date: real_date,
                subject,
                content: message,
                id
            }) ) ) : setEmails([])

            setTotalEmails(response?.total)
        }
        catch(err){
            console.log(err.message)
            toast.error(err.message)
        }
        finally{
            setEmailsLoading(false)
        }
    }

    const fetchArchivedEmails = async () => {
        setArchivedEmailsLoading(true)
        try{
            const response = await client.post('/getArchiveMails', {}, {noError: true, pagination: {page: archivedPage}})

            response && response?.data ?
            setArchivedEmails( response.data.map( ({listData, real_date, subject, message, id}) => ({
                sentTo: listData?.name,
                date: real_date,
                subject,
                content: message,
                id
            }) ) ) : setArchivedEmails([])

            setTotalArchivedEmails(response?.total)
        }
        catch(err){
            console.log(err.message)
            toast.error(err.message)
        }
        finally{
            setArchivedEmailsLoading(false)
        }
    }

    const fetchDrafts = async () => {
        setDraftsLoading(true)
        try{
            const response = await client.post('/getDraftMails', {}, {noError: true, pagination: {page: draftsPage}})

            response && response?.data ?
            setDrafts( response.data.map( ({listData, real_date, subject, message, id}) => ({
                sentTo: listData?.name,
                date: real_date,
                subject,
                content: message,
                id
            }) ) ) : setDrafts([])

            setTotalDrafts(response?.total)
        }
        catch(err){
            console.log(err.message)
            toast.error(err.message)
        }
        finally{
            setDraftsLoading(false)
        }
    }

    useEffect( () => {
        fetchEmails()
    }, [reloadEmails, emailsPage] )

    useEffect( () => {
        fetchArchivedEmails()
    }, [reloadArchivedEmails, archivedPage] )

    useEffect( () => {
        fetchDrafts()
    }, [reloadDrafts, draftsPage] )

    useEffect( () => {
        updateLocalStorage('userSettings', {emailsCurrentTab: currentTab})
    }, [currentTab] )


    const tabs = [
        {
            title: 'Overview',
            id: 'overview',
            action: () => setCurrentTab('overview')
        },
        {
            title: sm ? 'Archives' : 'Archived Emails',
            id: 'archived-emails',
            action: () => setCurrentTab('archived-emails')
        },
        {
            title: 'Draft',
            id: 'draft',
            action: () => setCurrentTab('draft')
        }
    ]

    return (
        <EmailsPageContext.Provider
        value={{
            currentTab,
            setReloadEmails,
            setReloadArchivedEmails,
            setReloadDrafts,
            totalEmails,
            totalArchivedEmails,
            totalDrafts,
            emailsPage, setEmailsPage,
            archivedPage, setArchivedPage,
            draftsPage, setDraftsPage
        }}
        >
        <Stack
            sx={{
                // alignSelf: 'center',
                ...!embed && 
                {p: (!miniSideBar && mdOnly) ? '50px 20px' : sm ? '15px' : '50px 50px'},
                gap: !sm ? '50px' : '30px',
                ...sm && {width: '100%'}
            }}
            >
            <Stack
            direction={!sm ? 'row' : 'column'}
            sx={{
                width: '100%',
                justifyContent: 'space-between',
                ...sm && {gap: '20px'}
            }}
            >
            {
            !embed &&
            <CustomButton
            title='Compose'
            squareCorners
            Icon={MdEdit}
            IconPlacement="left"
            sx={{gap: '5px'}}
            onClick={ () => navigate('/dashboard/compose') }
            />
            }
            {/* <Search /> */}
            </Stack>
            <Stack
            direction='row'
            >
                {
                tabs.map( ({title, id, action}, k) => (
                    <Stack
                    key={k}
                    onClick={action}
                    sx={{
                        borderBottom: '1px solid',
                        borderColor: currentTab === id ? 'primary.main' : 'neutral.900',
                        p: !sm ? '15px 50px' : '15px 10px',
                        cursor: 'pointer',
                        ...sm && {width: '50%'},
                        '& p': {
                            textTransform: 'uppercase',
                            textAlign: 'center'
                        }
                    }}
                    >
                    <Typography
                    sx={{
                        color: currentTab === id ? 'primary.main' : 'neutral.900',
                        fontWeight: 500,
                        ...sm && {fontSize: '.8rem'}
                    }}
                    >
                        {title}
                    </Typography>
                    </Stack>
                ) )
                }
            </Stack>

            <Stack
            >
            {
                currentTab === 'overview' &&
                <Stack >
                <EmailsTable
                emails={emails}
                loading={emailsLoading}
                />
                </Stack>
            }
            {
                currentTab === 'archived-emails' &&
                <Stack>
                <EmailsTable
                emails={archivedEmails}
                loading={archivedEmailsLoading}
                />
                </Stack>
            }
            {
                currentTab === 'draft' &&
                <Stack>
                <EmailsTable
                emails={drafts}
                loading={draftsLoading}
                />
                </Stack>
            }
            </Stack>
        </Stack>
        </EmailsPageContext.Provider>
    )
}


const Emails = () => {

    return (
        <Layout>
            <EmailsPage />
        </Layout>
    )
}

export default Emails