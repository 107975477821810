import React, { useState } from 'react'
import { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { isLoggedIn } from '../utils/functions/fetchData'
import Loader from './Dashboard/Loader'
import { updateLocalStorage } from '../utils/functions/updateStorage'



const ProtectedRoutes = ({ children }) => {
  const currentRoute = useLocation().pathname
  const [userData, setUserData] = useState('loading')

  // return <>{children}</>
  
  const handleVerifyLogin = async () => {
    try{
        const loggedIn = await isLoggedIn()
        if(loggedIn){
          const data = {
            ...(loggedIn?.email && { email: loggedIn.email }),
            ...(loggedIn?.usertoken && { token: loggedIn.usertoken }),
            ...(loggedIn?.fullname && { fullname: loggedIn.fullname }),
            ...(loggedIn?.username && { username: loggedIn.username }),
            ...(loggedIn?.phone && { phonenumber: loggedIn.phone }),
            ...(loggedIn?.photo && { picture: loggedIn.photo }),
            ...(loggedIn?.isBVN && { isBVN: loggedIn.isBVN }),
          }          
          updateLocalStorage('userData', data)
        return setUserData(true)
        }

        localStorage.removeItem('userData')
        return setUserData(false)
    }
    catch(err){
      setUserData(false)
    }
  }

  useEffect( () => {
    handleVerifyLogin()
  }, [userData])


  if(userData === 'loading'){
    return <Loader />
  }


  if(userData === false){
    return <Navigate to='/login' state={{source:  currentRoute}} />
  }

  if(userData === true){
    return <div>{children}</div>
  }
}

export default ProtectedRoutes
