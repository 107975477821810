import { useNavigate } from "react-router-dom";
import CustomButton from "../../Button";

const { Dialog, DialogTitle, DialogActions, Typography } = require("@mui/material");



const SignOutModal = ({ open=false,setOpen}) => {

    const navigate = useNavigate()

    const onClose = () => setOpen && setOpen(false)


    return (
      <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
            bgcolor: 'neutral.50'
        }
      }}
      >
        <DialogTitle component='span'>
        <Typography variant="h5">
        {`Are you sure you want to sign out ?`}
        </Typography>
        </DialogTitle>
        <DialogActions>
          <CustomButton
          onClick={onClose}
          sx={{
            bgcolor: 'neutral.700',
            '&: hover': {
                bgcolor: 'neutral.800'
            }
          }}
          >
            Cancel
          </CustomButton>
          <CustomButton
          autoFocus
          sx={{
            bgcolor: '#ff0000',
            '&: hover': {
                bgcolor: '#cc0000'
            }
          }}
          onClick={() => navigate('/signout')}
          >
            Sign Out
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
};
  

export default SignOutModal


