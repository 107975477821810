import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./TextEditor.css";
import TextEditorStyle from "./TextEditorStyle";
import { useComposePageContext } from "../../contexts/composePageContext";
import { IconButton, Stack, Typography } from "@mui/material";
import { BiReset } from "react-icons/bi";
import toast from "react-hot-toast";


const TextEditor = () => {
  const {emailData, setEmailData} = useComposePageContext()

return ( 
      <TextEditorStyle>
      <EditorToolbar toolbarId={'t1'}/>
      <ReactQuill
        theme="snow"
        value={emailData?.body}
        onChange={(value) => setEmailData((prevValue) => ({...prevValue, body: value}))}
        placeholder={"Write your email text..."}
        modules={modules('t1')}
        formats={formats}
        style={{...emailData?.object && {height: '50vh', filter: 'blur(15px)',}}}
      />
      {
      emailData?.object &&
      <Stack
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 1,
        top: 0,
        bgcolor: 'rgb(0,0,0,0.5)',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
      <Typography 
      sx={{color: '#fff !important', fontWeight: 600}}
      >
      This editor is disabled because you are using the full email editor
      </Typography>
      <Typography
      sx={{color: '#fff !important', fontWeight: 600}}
      >
      Reset mail to use this editor
      </Typography>
      <IconButton
      sx={{
          borderRadius: '5px',
          color: 'red'
      }}
      onClick={() => {
          setEmailData( (prevEmailData) => ({...prevEmailData, body: '', object: null}) );
          toast('Mail Reset!')
      }}
      >
      <BiReset size={25} />
      <Typography component='span'>Reset</Typography>
      </IconButton>
      </Stack>
      }
      </TextEditorStyle>
)
}
export default TextEditor