import Layout from "../../Layouts/DashboardLayout"
import {Stack, Typography } from "@mui/material"
import { useState } from "react"
import { useEffect } from "react"
import { toast } from "react-hot-toast"
import { useBreakpoints } from "../../../theme/breakpoints"
import { fetchData } from "../../../utils/functions/fetchData"
import Search from "../../Search"
import Lists from "./Lists"
import { useLayoutContext } from "../../../contexts/layoutContext"
import ListPageContext from "../../../contexts/listPageContext"
import CustomButton from "../../Button"
import { AiOutlinePlus } from "react-icons/ai"
import { useNavigate } from "react-router-dom"
import { fetchFromLocalStorage, updateLocalStorage } from "../../../utils/functions/updateStorage"
import client from "../../../utils/axios"



const ListPage = () => {
    const navigate = useNavigate()
    const currentTabFromStorage = fetchFromLocalStorage('userSettings')?.listsCurrentTab
    const [currentTab, setCurrentTab] = useState(currentTabFromStorage || 'lists')
    const {sm, md, mdOnly} = useBreakpoints()
    const {miniSideBar} = useLayoutContext()
    const [listLoading, setListLoading] = useState(false)
    const [archivedListLoading, setArchivedListLoading] = useState(false)

    const [lists, setLists] = useState([])
    const [archivedLists, setArchivedLists] = useState([])

    // Current Pagination Page
    const [listsPage, setListsPage] = useState(1)
    const [archivedListsPage, setArchivedListsPage] = useState(1)

    const [totalLists, setTotalLists] = useState(0)
    const [totalArchivedLists, setTotalArchivedLists] = useState(0)

    const [reloadLists, setReloadLists] = useState(false)
    const [reloadArchivedLists, setReloadArchivedLists] = useState(false)


    const fetchLists = async () => {
        setListLoading(true)
        try{
            const response = await client.post('/getUserLists', {}, {noError: true, pagination: {page: listsPage}})

            response && response?.data ?
            setLists( response.data.map( ({name, details, totalContact, id, token, realTime, embed}) => ({
                title: name,
                description: details,
                totalContacts: totalContact,
                id,
                token,
                embed,
                date: realTime
            }) ) ) : setLists([])

            setTotalLists(response?.total)
        }
        catch(err){
            console.log(err.message)
            toast.error(err.message)
        }
        finally{
            setListLoading(false)
        }
    }

    const fetchArchivedLists = async () => {
        setArchivedListLoading(true)
        try{
            const response = await client.post('/getArchiveLists', {}, {noError: true, pagination: {page: archivedListsPage}}) 

            response && response?.data ?
            setArchivedLists( response.data.map( ({name, details, totalContact, id, realTime, embed}) => ({
                title: name,
                description: details,
                totalContacts: totalContact,
                id,
                embed,
                date: realTime
            }) ) ) : setArchivedLists([])

            setTotalArchivedLists(response?.total)
        }
        catch(err){
            console.log(err.message)
            toast.error(err.message)
        }
        finally{
            setArchivedListLoading(false)
        }
    }

    useEffect( () => {
        fetchLists()
    }, [reloadLists, listsPage] )

    useEffect( () => {
        fetchArchivedLists()
    }, [reloadArchivedLists, archivedListsPage] )

    useEffect( () => {
        updateLocalStorage('userSettings', {listsCurrentTab: currentTab})
    }, [currentTab] )


    const tabs = [
        {
            title: 'LISTS',
            id: 'lists',
            action: () => setCurrentTab('lists')
        },
        {
            title: sm ? 'ARCHIVES' : 'ARCHIVED LISTS',
            id: 'archived-lists',
            action: () => setCurrentTab('archived-lists')
        }
    ]

    return (
        <ListPageContext.Provider
        value={{
            currentTab,
            setReloadLists,
            setReloadArchivedLists,
            totalLists,
            totalArchivedLists,
            listsPage, setListsPage,
            archivedListsPage, setArchivedListsPage,
        }}
        >
        <Stack
            sx={{
                // alignSelf: 'center',
                p: (!miniSideBar && mdOnly) ? '50px 20px' : sm ? '15px' : '50px 50px',
                gap: !sm ? '50px' : '30px',
                ...sm && {width: '100%'}
            }}
            >
            <Stack
            direction={!sm ? 'row' : 'column'}
            sx={{
                width: '100%',
                justifyContent: 'space-between',
                ...sm && {gap: '20px'}
            }}
            >
            <CustomButton 
            title='Create New List'
            squareCorners
            Icon={AiOutlinePlus}
            IconPlacement="left"
            sx={{gap: '5px'}}
            onClick={ () => navigate('/dashboard/lists/create-list') }
            />
            {/* <Search /> */}
            </Stack>
            <Stack
            direction='row'
            >
                {
                tabs.map( ({title, id, action}, k) => (
                    <Stack
                    key={k}
                    onClick={action}
                    sx={{
                        borderBottom: '1px solid',
                        borderColor: currentTab === id ? 'primary.main' : 'neutral.900',
                        p: !sm ? '15px 50px' : '15px 10px',
                        cursor: 'pointer',
                        ...sm && {width: '50%'},
                        textAlign: 'center'
                    }}
                    >
                    <Typography
                    sx={{
                        color: currentTab === id ? 'primary.main' : 'neutral.900',
                        fontWeight: 500,
                        ...sm && {fontSize: '.8rem'}
                    }}
                    >
                        {title}
                    </Typography>
                    </Stack>
                ) )
                }
            </Stack>

            <Stack
            sx={{
                // p: '20px',
                // ...(currentTab === 'schedule' && !sm) && {boxShadow: '0 0 10px rgb(0,0,0,.125)'},
                // borderRadius: '15px',
                // alignSelf: 'center'
            }}
            >
            {
                currentTab === 'lists' &&
                <Stack >
                <Lists
                lists={lists}
                loading={listLoading}
                setReloadLists={setReloadLists}
                />
                </Stack>
            }
            {
                currentTab === 'archived-lists' &&
                <Stack>
                <Lists
                lists={archivedLists}
                loading={archivedListLoading}
                setReloadArchivedLists={setReloadArchivedLists}
                />
                </Stack>
            }
            </Stack>
        </Stack>
        </ListPageContext.Provider>
    )
}


const List = () => {

    return (
        <Layout>
            <ListPage />
        </Layout>
    )
}

export default List