import { useEffect, useState } from 'react'
import { Plans } from '../Components/Dashboard/Settings/Subscription/Plans'
import Layout from '../Components/Landingspage/Layout'
import { isLoggedIn } from '../utils/functions/fetchData'
import { Navigate } from 'react-router-dom'
import Loader from '../Components/Dashboard/Loader'


const Plan = () => {
  const [loading, setLoading] = useState(true)
  const [loggedIn, setLoggedIn] = useState(false)

  const handleVerifyLogin = async () => {
    try{
        const loggedIn = await isLoggedIn()
        if(loggedIn){
          return setLoggedIn(true)
        }
        return setLoggedIn(false)
    }
    catch(err){
      setLoggedIn(false)
    }
    finally{
      setLoading(false)
    }
  }

  useEffect( () => {
    handleVerifyLogin()
  }, [] )


  return (
      <>
      {
      loading ?
      <Loader /> :
      loggedIn ? 
      <Navigate to='/dashboard/settings/pricing' /> :
      <Layout>
      <Plans
      landingPage
      />
      </Layout>
      }
      </>
  )
}

export default Plan
