import { createContext } from 'react';
import { useContext } from 'react';

const SettingsPageContext = createContext();


export const useSettingsPageContext = () => {

    return useContext(SettingsPageContext)
}

export default SettingsPageContext