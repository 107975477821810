import CustomButton from "../../Button";
import { IoClose } from "react-icons/io5";
import { BsFiletypeCsv } from "react-icons/bs";
import { useEffect, useRef, useState } from "react";
import { useBreakpoints } from "../../../theme/breakpoints";
import { AiOutlineFileAdd } from "react-icons/ai";
import { useListPageContext } from "../../../contexts/listPageContext";
import { fetchData} from "../../../utils/functions/fetchData";
import { toast } from "react-hot-toast";
import {Grow, IconButton, Tooltip, styled, tooltipClasses} from '@mui/material'
import { neutral, primary, secondary } from "../../../theme/palette";
import { FaInfoCircle } from "react-icons/fa";
import { fetchFromLocalStorage, updateLocalStorage } from "../../../utils/functions/updateStorage";
import InfoView from "../InfoView";
import client from "../../../utils/axios";

const { Dialog, DialogTitle, DialogContent, DialogActions, Typography, TextField, Stack, Alert } = require("@mui/material");

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
      backgroundColor: secondary.main,
      color: neutral[900],
      padding: '20px 15px'
    },
  })


const textFieldStyle = {
    '& .MuiInputBase-root': {
        borderRadius: '8px'
    },
    '& input': {
        bgcolor: 'white',
        color: '#000',
        p: '5px 15px',
        minHeight: '30px'
    },
    '& input:hover': {
        borderColor: 'primary.main'
    },
}


const AddContact = ({ open,setOpen, listData}) => {
    const onClose = () => setOpen(false)
    const {sm} = useBreakpoints()
    const [loading, setLoading] = useState(false)
    const [csvUploadLoading, setCsvUploadLoading] = useState(false)
    const csvFileRef = useRef(null)
    const [errorMessage, setErrorMessage] = useState('')
    const clearError = () => {
        setErrorMessage('')
    }

    const listPageContext = useListPageContext()
    if(listPageContext?.currentTab){
        var {setReloadLists, setReloadArchivedLists} = listPageContext
    }

    if(listPageContext?.viewContacts){
        var {setReloadContacts} = listPageContext
    }

    const initialContactData = {
        fname: null,
        lname: null,
        email: null
    }
    const [contactData, setContactData] = useState(initialContactData)

    const addContactData = [
        {
            title: 'First Name',
            value: contactData?.fullname,
            onChange: (e) => setContactData( (prevVal) =>  ({...prevVal, fname: e.target.value}))
        },
        {
            title: 'Last Name',
            value: contactData?.username,
            onChange: (e) => setContactData( (prevVal) =>  ({...prevVal, lname: e.target.value}))
        },
        {
            title: 'Email Address',
            value: contactData?.email,
            type: 'email',
            onChange: (e) => setContactData( (prevVal) =>  ({...prevVal, email: e.target.value}))
        }
    ]

    const handleAddContact = async (e) => {
        e.preventDefault()
        if(!contactData?.fname){
            return toast.error(`First Name cannot be empty!`)
        }
        if(!contactData?.lname){
            return toast.error(`Last Name cannot be empty!`)
        }
        if(!contactData?.email){
            return toast.error(`Email Address cannot be empty!`)
        }
        setLoading(true)
        try{
            const {message} = await client.post('/addNewContact', {
                "listToken": listData?.token,
                "fname": contactData?.fname,
                "lname": contactData?.lname,
                "mail": contactData?.email,
                "phone": "",
                "wa_phone": "",
            }, {version: 3})

            toast.success(message)
            setOpen(false)
            setContactData(initialContactData)
            if(listPageContext.currentTab){
                listPageContext.currentTab === 'lists' ? setReloadLists((prevVal) => !prevVal) : setReloadArchivedLists((prevVal) => !prevVal)
            }
            if(listPageContext.viewContacts){
                setReloadContacts((prevVal) => !prevVal)
            }
        }
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setLoading(false)
        }
    }

    const handleCsvUpload = async (e) => {
        clearError()
        setCsvUploadLoading(true)
        try{
            const file = e.target.files[0]

            const {message} = await client.post('/uploadContactToList-CSV', {
                "file": file,
                "listToken": listData?.token,
            }, {useFormData: true, version: 3})

            toast.success(message)
            setTimeout( () => {
                setOpen(false)
                setContactData(initialContactData)
                if(listPageContext.currentTab){
                    listPageContext.currentTab === 'lists' ? setReloadLists((prevVal) => !prevVal) : setReloadArchivedLists((prevVal) => !prevVal)
                }
                if(listPageContext.viewContacts){
                    setReloadContacts((prevVal) => !prevVal)
                }
            }, 1500 )
        }
        catch(err){
            setErrorMessage(err.message)
        }
        finally{
            setCsvUploadLoading(false)
            csvFileRef.current.value = null
        }
    }



    const csvInfoSeen = fetchFromLocalStorage('userSettings')?.csvInfoSeen
    const [openCsvInfo, setOpenCsvInfo] = useState(false)

    useEffect( () => {
        if(open){
            setTimeout( () => setOpenCsvInfo(!csvInfoSeen), 2000 )

            updateLocalStorage('userSettings', {
                csvInfoSeen: true
            })
        }
    }, [open] )

    const CSVExampleFile = () => (
        <Stack sx={{gap: '10px'}}>
        <Typography variant="h6">
            Spreadsheet Example File:
        </Typography>
        <img 
        width={1168/3}
        height={362/3}
        src="/assets/images/renimail-example-file.png"
        alt="csv-upload-example file"
        style={{objectFit: 'cover'}}
        />

        <Typography sx={{fontWeight: 500}}>
            {`(i) The first row will not be counted. (Whatever is there doesn't matter)`}<br />
            {`(ii) The first column should contain first names of subscribers`}<br />
            {`(iii) The second column should contain last names of subscribers`}<br />
            {`(iv) The second column should contain email addresses of subscribers`}<br />
        </Typography>
        <Typography
        sx={{
            fontWeight: 600
        }}
        >
            Then Convert Spreadsheet to .CSV format
        </Typography>
        </Stack>
    )


    return (
      <Dialog 
      open={open} 
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
            bgcolor: 'neutral.50',
            width: sm ? '85%' : 'unset'
        }
      }}
      >
      <form
      onSubmit={handleAddContact}
      noValidate
      >
        <DialogTitle 
        sx={{
            display: 'flex',
            justifyContent: 'space-between'
        }}
        >
        <Typography variant="h4">
        Add Contact
        </Typography>
        <IoClose size={30} onClick={onClose} style={{cursor: 'pointer'}} />
        </DialogTitle>
        <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '30px'}}>

        {
        errorMessage &&
        <Alert
        severity="error"
        >
        <Typography>{errorMessage}</Typography>
        </Alert>
        }

        <Stack
        direction='row'
        sx={{
            justifyContent: 'space-between',
            alignItems: 'center'
        }}
        >
        <CustomButton
        title='Upload .CSV File'
        variant='outlined'
        Icon={BsFiletypeCsv}
        IconPlacement="left"
        component='label'
        htmlFor="csv-upload"
        sx={{gap: '5px', alignSelf: 'left', width: 'fit-content'}}
        loading={csvUploadLoading}
        loadingText='uploading...'
        />
        <input
        ref={csvFileRef}
        id="csv-upload"
        type="file"
        accept=".csv"
        style={{ display: 'none' }}
        onChange={handleCsvUpload}
        />

        <InfoView 
        tooltip
        tooltipInfo={<CSVExampleFile />}
        >
        <IconButton
        sx={{width: 'fit-content', color: primary.main}}
        onClick={() => setOpenCsvInfo(true)}
        >
        <FaInfoCircle size={sm ? 18 : 22} />
        </IconButton>
        </InfoView>

        </Stack>
        
        <Stack>
        <Stack sx={{gap: '10px'}}>
        {
            addContactData.map( ({title, value, type, onChange}, k) => (
                <UserInput
                key={k}
                title={title}
                value={value}
                onChange={onChange}
                type={type}
                disabled={csvUploadLoading}
                />
            ) )
        }
        </Stack>
        </Stack>
        </DialogContent>
        <DialogActions>
          <CustomButton
          title='Add Contact'
          Icon={AiOutlineFileAdd}
          IconPlacement="left"
          squareCorners
          sx={{gap: '5px'}}
          onClick={ handleAddContact }
          type='submit'
          loading={loading}
          disabled={csvUploadLoading}
          />
        </DialogActions>
        </form>



        <InfoView
        dialog
        open={openCsvInfo}
        setOpen={setOpenCsvInfo}
        >
        <CSVExampleFile />
        </InfoView>
      </Dialog>
    );
};
  

export default AddContact





const UserInput = ({title, value, type, onChange, disabled=false}) => {
    const {sm} = useBreakpoints()
    

    return (
        <Stack 
        direction={ sm ? "column" : "row"}
        sx={{justifyContent: 'space-between', gap: '10px'}}
        >
        <Typography
        sx={{
            color: disabled && 'neutral.400'
        }}
        >
        {title}
        </Typography>
        <TextField
        sx={textFieldStyle}
        value={value}
        onChange={onChange}
        type={type ? type : 'text'}
        disabled={disabled}
        />
        </Stack>
    )
}