import { IconButton, Stack, SxProps } from "@mui/material"
import { useSwiper } from "swiper/react"
import { useBreakpoints } from "../theme/breakpoints"
import { neutral, primary} from "../theme/palette"

const { MdKeyboardArrowLeft, MdKeyboardArrowRight } = require("react-icons/md")



const SwiperButton = ({sx, prev=false, next=false, type}) => {
    const {xs} = useBreakpoints()
    const swiper = useSwiper()

    const bgcolor = 'neutral.50'
    const hoverBgColor = 'neutral.200'

    const IconColor = primary.main

    const Icon = prev ? MdKeyboardArrowLeft : MdKeyboardArrowRight

    return (
        <IconButton
        onClick={() => prev ? swiper.slidePrev() : next ? swiper.slideNext() : null}
        sx={{
            bgcolor,
            '&:hover': {
                bgcolor: hoverBgColor
            },
            borderRadius: '50%',
            boxShadow: '0 0 10px rgb(0,0,0,.325)',
            ...sx
        }}
        >
            <Icon
            size={xs ? 20 : 30}
            color={IconColor}
            />
        </IconButton>
    )
}

export default SwiperButton