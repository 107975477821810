import { Divider, Skeleton, Stack, Typography } from "@mui/material"


const MobileStatistics = ({totalActiveSubs, totalContacts, newSignUps, bounces, unsubscribers, loading}) => {


    const topStatData = [
        {
            title: 'TOTAL ACTIVE SUBSCRIBERS:',
            count: totalActiveSubs
        },
        {
            title: 'TOTAL CONTACTS:',
            count: totalContacts
        },
    ]

    const bottomStatData = [
        // {
        //     title: 'NEW SIGNUPS:',
        //     count: newSignUps
        // },
        {
            title: 'BOUNCES:',
            count: bounces
        },
        {
            title: 'UNSUBSCRIBERS:',
            count: unsubscribers
        },
    ]


    const TopStat = ({title, count}) => {

        return (
            <Stack
            sx={{
                alignItems: 'center',
                gap: '10px'
            }}
            >
            <Divider sx={{borderColor: 'neutral.400', borderWidth: '1px', width: '100%'}} />
            <Typography sx={{fontWeight: 500}}>
                {title}
            </Typography>
            <Typography variant="h4">
                {!loading ? count : <Skeleton width={30} height={20} />}
            </Typography>
            </Stack>
        )
    }

    const BottomStat = ({title, count}) => {
        

        return (
            <Stack sx={{gap: '5px'}}>
                <Typography sx={{fontWeight: 500}}>
                    {title}
                </Typography>
                <Typography variant="h4" sx={{alignSelf: 'flex-end'}}>
                    {!loading ? count : <Skeleton width={30} height={20} />}
                </Typography>
                <Divider sx={{borderColor: 'neutral.400', borderWidth: '1px', width: '100%'}} />
            </Stack>
        )
    }


    return (
        <Stack
        sx={{gap: '20px'}}
        >
            {
                topStatData.map( ({count, title}, k) => (
                    <TopStat 
                    key={k}
                    count={!loading ? count : <Skeleton width={30} height={20} />}
                    title={title}
                    />
                ) )
            }

            <Stack
            sx={{
                width: '100%',
                p: '10px',
                bgcolor: '#F5F6FF',
                borderRadius: '5px',
                boxShadow: '0 0 3px rgb(0,0,0,.125)'

            }}
            >
                <Typography sx={{fontWeight: 500}}>
                    Past Month Stats:
                </Typography>
            </Stack>
            
            {
                bottomStatData.map( ({title, count}, k) => (
                    <BottomStat 
                    key={k}
                    title={title}
                    count={!loading ? count : <Skeleton width={30} height={20} />}
                    />
                ) )
            }
        </Stack>
    )
}

export default MobileStatistics