

export const shortenText = (text: string): string => {
    const words = text.split(' ');
    const shortened = words.slice(0, 15).join(' ');
    return words.length > 15 ? shortened + '...' : shortened;
};



export const shortenTextByCharacters = (text: string, maxChars: number): string => {
    if (text.length <= maxChars) {
        return text;
    }
    return text.substring(0, maxChars) + (text.length > maxChars ? '...' : '');
};


