import { Divider, Skeleton, Stack, Typography } from "@mui/material"
import { FiArrowDownLeft, FiArrowUpRight } from "react-icons/fi"



const StatisticsCard = ({title, figure, rate, noDivider, loading=false}) => {


    return (
        <Stack direction='row'>
        <Stack
        sx={{
            gap: '8px',
            p: '15px'
        }}
        >
            <Stack
            direction='row'
            sx={{gap: '8px'}}
            >
            <Typography variant="h4" sx={{fontWeight: 500}}>
                {!loading ? figure : <Skeleton width={50} />}
            </Typography>
            {
            rate &&
            <Stack
            direction='row'
            sx={{
                gap: '4px',
                p: '5px 7px',
                borderTopRightRadius: '8px',
                borderTopLeftRadius: '8px',
                bgcolor:  rate >= 0 ? '#D3FAE0' : '#FFE3E3',
                color: rate >= 0 ? '#51CA9B' : '#D37268',
                alignItems: 'center'
            }}
            >
                {
                    rate >= 0 ? <FiArrowUpRight size={10} /> : <FiArrowDownLeft size={10} />
                }
                <Typography sx={{
                    fontSize: '.8rem',
                    color: rate >= 0 ? '#51CA9B' : '#D37268',
                }}>
                    {!loading ? rate + '%' : <Skeleton width={50} />}
                </Typography>
            </Stack>
            }
            </Stack>
            <Typography>
                {title}
            </Typography>
        </Stack>
        {/* {
            !noDivider &&
            <Divider
            sx={{
                borderWidth: '2px',
                borderColor: '#E4E7EC',
                height: '100%',
            }}
            />
        } */}
        </Stack>
    )
}

export default StatisticsCard