import { Skeleton, Stack, Typography } from "@mui/material"
import { BsBox } from "react-icons/bs"
import { neutral, primary } from "../../../theme/palette"
import { FaUser } from "react-icons/fa"
import { TbMail } from "react-icons/tb"
import { useNavigate } from "react-router-dom"
import { useBreakpoints } from "../../../theme/breakpoints"
import { useLayoutContext } from "../../../contexts/layoutContext"
import CustomButton from "../../Button"


const IconContWrappeStyle = (bgcolor) =>  ({width: '55px', height: '55px', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', bgcolor: bgcolor})
const IconContStyle  = (bgcolor) => ({width: '40px', height: '40px', borderRadius: '50%', bgcolor: bgcolor, alignItems: 'center', justifyContent: 'center'})

const MyPlan = ({planText, contactsRemaining, mailsRemaining, loading}) => {
    const navigate = useNavigate()
    const {xs, mdOnly} = useBreakpoints()
    const {miniSideBar, userBalance} = useLayoutContext()

    return (
        <Stack
        sx={{
            width: '100%',
            minHeight: '300px',
            bgcolor: 'neutral.50',
            boxShadow: '0 0 10px rgb(0,0,0,.125)',
            border: '1px solid',
            borderColor: 'neutral.400',
            borderRadius: '20px',
            p: '20px',
            gap: '30px',
            justifyContent: 'center'
        }}
        >
            <Stack
            direction='row'
            sx={{alignItems: 'center', gap: '10px'}}
            >
                <Stack sx={IconContWrappeStyle('#BED9FB')}>
                <Stack
                sx={IconContStyle('primary.main')}
                >
                    <BsBox size={23} color={neutral[50]} />
                </Stack>
                </Stack>
                <Typography variant="h5">
                    My Plan
                </Typography>
            </Stack>
            <Stack
            direction={xs || (mdOnly && !miniSideBar) ? 'column' : 'row'}
            sx={{justifyContent: 'space-between', gap: '10px'}}
            >
                <Typography 
                // variant="h6" 
                sx={{display: 'flex', gap: '5px', fontSize: '1rem'}}>
                    Current Plan: {!loading ? planText : <Skeleton width={30} height={20} />}
                </Typography>
                <CustomButton 
                title='Upgrade Plan'
                variant="outlined"
                sx={{
                    textTransform: 'unset',
                    color: 'primary.main',
                    borderRadius: '8px',
                    alignSelf: 'center',
                    minWidth: 134.69
                }}
                onClick={() => navigate('/dashboard/settings/pricing')}
                />
            </Stack>
            <Stack>
            <Typography sx={{color: 'grey.main'}}>
                <span>ReniTrust</span> Balance: {!userBalance?.loading ? (userBalance?.value ? `N${userBalance?.value}` : 'Unavailable at the moment') : <Skeleton component='span' sx={{display: 'inline-flex'}} width={30} height={20} />}
            </Typography>
            </Stack>
            <Stack
            direction='row'
            sx={{justifyContent: 'space-between', gap: '50px'}}
            >
                <Stack
                direction='row'
                sx={{gap: '10px'}}
                >
                <Stack sx={IconContWrappeStyle('rgba(135, 21, 189, 0.30)')}>
                <Stack
                sx={IconContStyle('#8715BD')}
                >
                    <FaUser size={23} color={neutral[50]} />
                </Stack>
                </Stack>
                <Stack sx={{gap: '5px'}}>
                    <Typography sx={{fontSize: '.9rem', fontWeight: '500'}}>
                        Contacts left
                    </Typography>
                    <Typography>
                    {!loading ? contactsRemaining : <Skeleton width={30} height={20} />}
                    </Typography>
                </Stack>
                </Stack>

                <Stack>
                <Stack
                direction='row'
                sx={{gap: '10px'}}
                >
                <Stack sx={IconContWrappeStyle('#BED9FB')}>
                <Stack
                sx={IconContStyle('primary.main')}
                >
                    <TbMail size={23} color={neutral[50]} />
                </Stack>
                </Stack>
                <Stack sx={{gap: '5px'}}>
                    <Typography sx={{fontSize: '.9rem', fontWeight: '500'}}>
                        Mails left
                    </Typography>
                    <Typography>
                    {!loading ? mailsRemaining : <Skeleton width={30} height={20} />}
                    </Typography>
                </Stack>
                </Stack>   
                </Stack>
            </Stack>
        </Stack>
    )
}

export default MyPlan