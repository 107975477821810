import { createContext } from 'react';
import { useContext } from 'react';

const RecycleBinContext = createContext();


export const useRecycleBinContext = () => {

    return useContext(RecycleBinContext)
}

export default RecycleBinContext

