import React from 'react'


import {Teams} from './AboutData'

import '../AboutPage/MeetTeam.css'
const TeamCard = ({ Team: { img, name, title } }) => (
  <div className='teamprofile'>
    <div className='team-img'>
      <img src={img} alt='' className='img-fluid' />
    </div>
    <div className='team-details'>
      <h4>{name}</h4>
      <p>{title}</p>
    </div>
  </div>
)





const MeetTeam = () => {
  return (
    <>
      <div className='container-fluid team d-flex justify-content-center align-items-center flex-column w-100'>
        <h1>Meet the Team</h1>
        <div className='teams'>
          {Teams.map((Team) => (
            <TeamCard Team={Team} key={Team.id} />
          ))}
        </div>
      </div>
    </>
  )
}

export default MeetTeam