import { Avatar, Popper, Fade, Stack, IconButton, Slide,} from "@mui/material"
import { Typography } from "@mui/material"
import { neutral, primary } from "../../../theme/palette"
import { useEffect, useRef, useState } from "react"
import { useBreakpoints } from "../../../theme/breakpoints"
import UserTabPopOverOption from "./UserTabPopOverOption"
import { CgClose, CgMenu } from "react-icons/cg"
import { useLayoutContext } from "../../../contexts/layoutContext"
import SignOutModal from "../../Auth/SignOut/SignOutModal"
import { RiCustomerService2Fill } from "react-icons/ri"
import { LiaSignOutAltSolid } from "react-icons/lia"
import Logo from "../../Logo"
import { MdEmail, MdWhatsapp } from "react-icons/md"
import { renimailSupportEmail, renimailSupportNumber } from "../../../utils/functions/fetchData"




const TopNav = ({height, mediaHeight, sideBarWidth}) => {
    const {
        userData,
        openMediaMenu,
        setOpenMediaMenu,
        mediaMenuToggleRef
    } = useLayoutContext()
    const [openPopOver, setOpenPopOver] = useState(false)
    const {sm, md} = useBreakpoints()
    const userTabRef = useRef()
    const popperRef = useRef()

    const mediaTopNavRef = useRef()

    const bodyHidePopup = (e) => {
        if (
            !userTabRef.current ||
            !popperRef.current ||
            (!userTabRef.current.contains(e.target) &&
            !popperRef.current.contains(e.target))
          ) {
            setOpenPopOver(false);
          }
    }

    useEffect( () => {
        document.body.addEventListener('click', bodyHidePopup)
        return ( () => {
            document.body.removeEventListener('click', bodyHidePopup)
        } )
    }, [] )

    useEffect( () => {
        sm && setOpenPopOver(false)
    }, [sm] )



    const [openSignOutModal, setOpenSignOutModal] = useState(false)

    const userTabOptions = [
        {
            title: 'Support',
            Icon: RiCustomerService2Fill,
            IconColor: primary.main,
            action: null,
            subTabItems: [
                {
                    title: 'Email',
                    route: `mailto:${renimailSupportEmail}`,
                    Icon: MdEmail,
                    
                },
                {
                    title: 'WhatsApp',
                    route: `https://wa.me/${renimailSupportNumber}`,
                    Icon: MdWhatsapp
                },
            ]
        },
        {
            title: 'Sign Out',
            Icon: LiaSignOutAltSolid,
            IconColor: '#f00',
            action: () => setOpenSignOutModal(true)
        }
    ]



    return (
        <>
            {
            !sm &&
            <Stack
            sx={{
                width: `calc(100% - ${sideBarWidth})`,
                height: height,
                bgcolor: 'white',
                justifyContent: 'center',
                p: !md ? '0 50px' : '0 10px',
                position: 'fixed',
                right: 0,
                backdropFilter: 'blur(15px)',
                transition: 'all 0.2s',
                zIndex: 16,
                boxShadow:  '0 0 10px rgb(0,0,0,.125)',
                overflow: 'hidden'
            }}
            >
                <Stack
                ref={userTabRef}
                direction='row'
                sx={{
                    alignSelf: 'flex-end',
                    alignItems: 'center',
                    gap: '10px',
                    cursor: 'pointer',
                    position: 'relative',
                }}
                onClick={() => setOpenPopOver(!openPopOver)}
                >
                    <Typography
                    variant="h6"
                    sx={{
                        fontWeight: '600'
                    }}
                    >
                        {(userData?.fullname && (userData?.fullname).toUpperCase()) || 'USER'}
                    </Typography>
                    <Avatar
                    src={userData?.picture}
                    sx={{
                        border: '3px solid',
                        borderColor: 'primary.main',
                        width: '50px',
                        height: '50px'
                    }}
                    >
                    {
                        (userData?.fullname && 
                        ((userData?.fullname)?.split(' '))[0]?.slice(0,1) +
                        ((userData?.fullname)?.split(' '))[1]?.slice(0,1) )
                    }
                    </Avatar>
                </Stack>
            </Stack>
            }


            {
            sm &&
            <Stack
            ref={mediaTopNavRef}
            direction='row'
            sx={{
                position: 'fixed',
                width: '100%',
                height: mediaHeight,
                bgcolor: 'primary.main',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '0 12px',
                zIndex: 16
            }}
            >
            <Stack>
                <Typography color='neutral.50'>
                    Hello,
                </Typography>
                <Typography color='neutral.50'>
                {(userData?.fullname && (userData?.fullname).toUpperCase()) || 'USER'}
                </Typography>
            </Stack>

            <Stack
            ref={mediaMenuToggleRef}
            >
            {
                !openMediaMenu ? 
                <IconButton
                onClick={() => setOpenMediaMenu(true)}
                >
                <CgMenu size={35} color={neutral[50]} />
                </IconButton> : 
                <Stack
                direction='row'
                sx={{
                    width: '120px',
                    right: 0,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
                >
                <Logo noRedirect />
                <IconButton
                onClick={() => setOpenMediaMenu(false)}
                >
                <CgClose size={35} color={neutral[50]} />
                </IconButton>
                </Stack>
            }
            </Stack>
            </Stack>
            }


            <Slide 
            direction="down"
            in={openPopOver}
            >
            <Stack
            className="user-tab-pop-over"
            ref={popperRef}
            sx={{
                bgcolor: 'neutral.50',
                boxShadow: '0 0 10px rgb(0,0,0,.125)',
                // pointerEvents: openPopOver ? 'auto' : 'none',
                zIndex: 15,
                width: '250px',
                position: 'fixed',
                top: '80px',
                right: 0
            }}
            onClick={(e) => e.stopPropagation()}
            >
                {
                    userTabOptions.map( ({title, Icon, IconColor, action, subTabItems}, k) => (
                        <UserTabPopOverOption
                        key={k}
                        title={title}
                        Icon={Icon}
                        IconColor={IconColor}
                        action={action}
                        subTabItems={subTabItems}
                        />
                    ) )
                }
            </Stack>
            </Slide>


            <SignOutModal
            open={openSignOutModal}
            setOpen={setOpenSignOutModal}
            />
        </>
    )
}

export default TopNav