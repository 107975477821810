import { Box, Stack, Typography } from "@mui/material"
import { AiOutlineCopyright } from "react-icons/ai"
import Logo2 from '../Images/logo 2.png'
import { primary } from "../../theme/palette"
import { socials } from "./footer-data"
import Grid from "../CustomGrid"
import { Link } from "react-router-dom"
import { useBreakpoints } from "../../theme/breakpoints"
import Logo from "../Logo"



const MiniFooter = () => {
    const {xs, sm, md} = useBreakpoints()

    return (
        <footer>
        <Stack
            direction={sm ? 'column' : 'row'}
            sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '25px'
            }}
            >
                <Stack direction='row'>
                <Box>
                    <Logo />
                </Box>
                <Box><AiOutlineCopyright size={xs ? 15 : sm ? 20 : 25} color={primary.main} /></Box>
                <Typography 
                variant={ xs ? "h6" : sm ? "h5" : "h4"} 
                color='primary'
                >
                2023
                </Typography>
                </Stack>
                <Stack
                direction='row'
                sx={{
                    gap: '5px'
                }}
                >
                {
                    socials.map( ({Icon, route}, k) => (
                        <Link
                        key={k}
                        to={route}
                        target="_blank"
                        >
                            <Icon size={sm ? 20 : md ? 25 :30} color={primary.main} />
                        </Link>
                    ) ) 
                }
                </Stack>
            </Stack>
            </footer>
    )
}

export default MiniFooter