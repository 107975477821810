import React from 'react'
import joincomm from '../Img/joincomm.png'
import { Link } from 'react-router-dom'
import { FaInstagram, FaTwitter, FaFacebook } from 'react-icons/fa'



import '../SupportPage/Joincommunity.css'
const Joincommunity = () => {
  return (
    <div className='joinMain'>
      <div className='container-fluid d-flex justify-content-between align-items-center join flex-column flex-lg-row'>
        <div className='joinimg'>
          <img src={joincomm} alt='' className='img-fluid' />
        </div>
        <div className='jointext'>
          <h1>Join our online community</h1>
          <p>
            Follow us on socials. Be the first to get Lazerpay news and all the
            exclusive juicy stuff before anyone else.
          </p>
          <div className='joinsocials d-flex justify-content-between'>
            <Link to=''>
              <FaInstagram />
            </Link>
            <Link to=''>
              <FaTwitter />
            </Link>
            <Link to=''>
              <FaFacebook />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Joincommunity