import {useEffect, useRef, useState} from 'react'
import UnlayerEmailEditor from 'react-email-editor'
import { useComposePageContext } from '../../contexts/composePageContext'
import { CircularProgress, Stack, Typography } from '@mui/material'
import InfoView from '../Dashboard/InfoView'
import {useWindowSize} from 'react-use'

const EmailEditor = () => {
  const {width} = useWindowSize()
  const {emailData, setEmailData} = useComposePageContext()
  const emailEditorRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [openInfo, setOpenInfo] = useState(false)

  const exportHtml = () => {
    console.log('Exporting HTML')
    if(emailEditorRef.current){
      emailEditorRef.current.editor.exportHtml(async (data) => {
        const { design, html } = data
        const htmlX = html.replace(/"/g, '');
        
        // console.log(data)
        setEmailData( (prevVal) => ({...prevVal, body: htmlX, object: design}) )
      })
    }
  }
  
  const loadDesign = () => {
      if(emailData?.object){
        emailEditorRef.current?.editor.loadDesign(emailData.object)
      }
  }

  const onLoad = () => {
    loadDesign()

    emailEditorRef.current.editor.addEventListener('design:updated', function (data) {
      // console.log('design:updated', type, item, changes)
      exportHtml()
    })
  }

  const onReady = () => {
    exportHtml()
    setLoading(false)
  }


  useEffect( () => {
    if(width<1200){
      setOpenInfo(true)
    }
    else{
      setOpenInfo(false)
    }
  }, [width] )


  return (
    <>
    <Stack sx={{position: 'relative'}}>
    <UnlayerEmailEditor
    ref={emailEditorRef}
    onLoad={onLoad}
    onReady={onReady}
    style={{
      ...loading && {filter: `blur(15px)`}
    }}
    />



    {
    loading &&
    <Stack
    sx={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 1,
      top: 0,
      bgcolor: 'rgb(255,255,255,0.8)',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
    <CircularProgress size={50} />
    </Stack>
    }
    </Stack>



    <InfoView
    dialog
    open={openInfo}
    setOpen={setOpenInfo}
    >
    <Typography sx={{fontWeight: 500, textAlign: 'center'}}>
    <strong><i>{`Use this editor on a device with a larger screen for a better experience.`}</i></strong><br/><br />
    </Typography>
    </InfoView>
    </>
  )
}


export default EmailEditor