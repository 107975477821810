import React from 'react'
import Layout from '../Components/Landingspage/Layout'
import Herosupport from '../Components/Landingspage/SupportPage/Herosupport'
import Joincommunity from '../Components/Landingspage/SupportPage/Joincommunity'
import Questions from '../Components/Landingspage/SupportPage/Questions'
import TypeOfSupport from '../Components/Landingspage/SupportPage/TypeOfSupport.js'

const Support = () => {
  return (
    <>
      <Layout>
        <Herosupport />
        <TypeOfSupport />
        <Questions />
        <Joincommunity />
      </Layout>
    </>
  )
}

export default Support