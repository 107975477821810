import { LocalizationProvider } from '@mui/x-date-pickers'
import { TimePicker as MUITimePicker } from '@mui/x-date-pickers/TimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { IDatePicker } from './types'

const TimePicker = ({date, setDate}: IDatePicker) => {

    const handleTimeChange = (time: any) => {
        const jsDate = new Date(date)
    
        jsDate.setHours(time.hour())
        jsDate.setMinutes(time.minute())
        jsDate.setSeconds(0)
    
        setDate(jsDate)
    }


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MUITimePicker
        defaultValue={dayjs(new Date())}
        onChange={handleTimeChange}
        />
        </LocalizationProvider>
    )
}


export default TimePicker