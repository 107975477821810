import { FiList, FiSettings } from "react-icons/fi";
import { MdEdit, MdEmail } from "react-icons/md";
import { RiDashboardLine } from "react-icons/ri";



export const nav = [
    {
        title: 'Dashboard',
        Icon: RiDashboardLine,
        route: '/dashboard',
        id: 'overview'
    },
    {
        title: 'Compose',
        Icon: MdEdit,
        route: '/dashboard/compose',
        id: 'compose'
    },
    {
        title: 'Emails',
        Icon: MdEmail,
        route: '/dashboard/emails',
        id: 'emails'
    },
    {
        title: 'Lists',
        Icon: FiList,
        route: '/dashboard/lists',
        id: 'lists'
    },
    {
        title: 'Settings',
        Icon: FiSettings,
        route: '/dashboard/settings/account',
        id: 'settings'
    },
]