import { createContext } from 'react';
import { useContext } from 'react';

const LayoutContext = createContext();


export const useLayoutContext = () => {

    return useContext(LayoutContext)
}

export default LayoutContext

