import { Skeleton, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { fetchData } from '../../../utils/functions/fetchData';
import { monthNames } from '../../../utils/functions/dates';
import client from '../../../utils/axios';

const NewSignUpsChart = () => {
  const initialChartData = {
    options: {
      chart: {
        id: 'subscribers-chart'
      },
      xaxis: {
        categories: []},
      stroke: {
        width: 3,
        curve: 'smooth'
      },
    },
    series: [{
      name: 'subscribers',
      data: []
    }],
  }
  const [chartData, setChartData] = useState(initialChartData)
  const [loading, setLoading] = useState(true)


  useEffect( () => {
    const handleLoad = async () => {
      setLoading(true)
      try{
        const {data} = await client.post('/getGraphData')

        if(!data){
          return
        }

        const modData = data?.map( ({date, ...rest}) => ({date: '1 '+date, ...rest}) )

        const sortedData = modData?.sort((a, b) => new Date(a.date) - new Date(b.date))

        const dates = sortedData?.map( ({date}) => monthNames[(new Date(date)).getMonth()] )

        const subscribers = sortedData?.map( ({subscribers}) => parseInt(subscribers) )

        setChartData( prevVal => ({
          ...prevVal,
          options: {
            chart: {
              id: 'subscribers-chart'
            },
            xaxis: {
              categories: dates},
            stroke: {
              width: 3,
              curve: 'smooth'
            },
          },
          series: [{
            name: 'subscribers',
            data: subscribers
          }],
        }) )
      }
      catch(err){
        
      }
      finally{
        setLoading(false)
      }
    }
    handleLoad()
  }, [] )

  return (
    <Stack
    sx={{
      width: '100%',
      minHeight: '300px',
      bgcolor: 'neutral.50',
      boxShadow: '0 0 10px rgb(0,0,0,.125)',
      border: '1px solid',
      borderColor: 'neutral.400',
      borderRadius: '20px',
      p: '10px'
    }}
    >
    <Typography sx={{p: '0 10px', fontWeight: 500}}>
      Subscribers
    </Typography>
    {
      !loading ?
      <Chart
      options={chartData.options} 
      series={chartData.series}
      type="line"
      width='100%' 
      height='100%'
      /> :
      <>
      {
      Array.from({length: 10}).map( (_, k) => (
        <Skeleton 
        key={k}
        sx={{height: '10%'}}
        />
      ) )
      }
      </>
    }
    </Stack>
  );
};

export default NewSignUpsChart;
