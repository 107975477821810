import React from 'react'
import '../SupportPage/Herosupport.css'
const Herosupport = () => {
  return (
    <>
      <div className='container-fluid d-flex justify-content-center align-items-center flex-column support'>
        <h1>{`We’re always here to improve your experience`}</h1>
        <div className="search mt-5">
            <input type="text" placeholder='Ask a question' />
        </div>
      </div>
    </>
  )
}

export default Herosupport