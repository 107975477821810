import { Box, Stack } from "@mui/material"
import SideBar from "../Dashboard/SideBar"
import { useEffect, useState } from "react"
import { useBreakpoints } from "../../theme/breakpoints"
import TopNav from "../Dashboard/TopNav"
import LayoutContext from "../../contexts/layoutContext"
import AppLoader from "../AppLoader"
import LoadingContext from "../../contexts/loadingContext"
import { useLocation } from "react-router-dom"
import MediaMenu from "../Dashboard/TopNav/MediaMenu"
import { useRef } from "react"
import toast from "react-hot-toast"
import { fetchFromLocalStorage, updateLocalStorage } from "../../utils/functions/updateStorage"
import client from "../../utils/axios"



const Layout = ({children}) => {
    const [userData, setUserData] = useState({})
    const [userBalance, setUserBalance] = useState({
        value: 0,
        loading: false
    })
    const [globalLoading, setGlobalLoading] = useState(false)
    const {sm, md} = useBreakpoints()
    const pathname = useLocation().pathname
    const [openBackdrop, setOpenBackdrop] = useState(false)

    const storedMiniSidebar = fetchFromLocalStorage('userSettings')?.storedMiniSidebar
    const [miniSideBar, setMiniSidebar] = useState(storedMiniSidebar || md)
    const sidebarWidth = '260px'
    const miniSideBarWidth = '97.5px'
    const topNavHeight = '80px'
    const topNavMediaHeight = '100px'
    const [openMediaMenu, setOpenMediaMenu] = useState(false)
    const mediaMenuToggleRef = useRef(null)

    const [reniUserDetails, setReniUserDetails] = useState({})
    const [reniUserDetailsLoading, setReniUserDetailsLoading] = useState(false)

    const [userSub, setUserSub] = useState({})
    const [userSubLoading, setUserSubLoading] = useState({})


    useEffect(() => {md ? setMiniSidebar(true) : setMiniSidebar(storedMiniSidebar)}, [md])

    useEffect( () => {
        updateLocalStorage('userSettings', {
            storedMiniSidebar: miniSideBar
        })
    }, [miniSideBar] )

    useEffect( () => {
        if(md && !miniSideBar){
            setOpenBackdrop(true)
        }
        if(md && miniSideBar){
            setOpenBackdrop(false)
        }
    }, [miniSideBar] )

    useEffect( () => {
        setUserData(JSON?.parse(localStorage.getItem('userData')))
    }, [] )

    // Fetch User's Balance
    const fetchUserBalance = async () => {
        setUserBalance((prevVal) => ({...prevVal, loading: true}))
        try{
            const {data, message} = await client.post('/fetch.UserBalance', {}, {noError: true})
            setUserBalance((prevVal) => ({...prevVal, value: data?.WithdrawableBalance_th}))
        }
        catch(err){
            console.log(err.message)
        }
        finally{
            setUserBalance((prevVal) => ({...prevVal, loading: false}))
        }
    }

    const fetchReniUserDetails = async () => {
        setReniUserDetailsLoading(true)
        try{
            const {data, message} = await client.post('/getAccountDetails', {}, {noError: true, useCache: true})
            setReniUserDetails(data)
        }
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setReniUserDetailsLoading(false)
        }
    }

    const fetchUserSubscription = async () => {
        try{
            setUserSubLoading(true)
            const {data, message} = await client.post('/getUserSubscription', {}, {noError: true})
            setUserSub(data)
        }
        catch(err){
            console.error(err.message)
        }
        finally{
            setUserSubLoading(false)
        }
    }

    useEffect( () => {
        fetchUserBalance()
        fetchReniUserDetails()
        fetchUserSubscription()
    }, [] )
    
    return (
        <LayoutContext.Provider
        value={{
            miniSideBar,
            setMiniSidebar,
            miniSideBarWidth,
            sidebarWidth,
            openMediaMenu,
            setOpenMediaMenu,
            mediaMenuToggleRef,
            userData,
            setUserData,
            userBalance,
            reniUserDetails,
            reniUserDetailsLoading,
            userSub,
            userSubLoading
        }}
        >
        <LoadingContext.Provider value={{globalLoading, setGlobalLoading}}>
        <Stack>
        {globalLoading && <AppLoader loading={true} />}
        <Stack
        direction='row'
        >
        <Stack>
        {
            !sm &&
            <SideBar 
            width={!miniSideBar ? sidebarWidth : miniSideBarWidth}
            miniSideBar={miniSideBar}
            setMiniSidebar={setMiniSidebar}
            />   
        }
        </Stack>
        <Stack
        sx={{
            width: '100%',
            pl: !sm ? ( !miniSideBar ? (md ? 0 : sidebarWidth) : miniSideBarWidth ) : 0
        }}
        >
            <Stack
            sx={{
                boxShadow: '0 0 10px rgb(0,0,0,.125)'
            }}
            >
                <TopNav
                height={topNavHeight}
                mediaHeight={topNavMediaHeight}
                sideBarWidth={!miniSideBar ? sidebarWidth : miniSideBarWidth}
                />
            </Stack>
            <Stack
            sx={{
                pt: !sm ? topNavHeight : topNavMediaHeight,
                bgcolor: pathname === '/dashboard' ?  'background' : 'white',
                position: 'relative'
            }}
            >
                <MediaMenu />
                {children}
            </Stack>
        </Stack>
        {
            openBackdrop && 
            <Box 
            sx={{
                position: 'fixed',
                width: '100%',
                height: '100%',
                bgcolor: 'rgb(0,0,0,.225)',
                zIndex: 99
            }}
            onClick={ () => setMiniSidebar(true) }
            />
        }
        </Stack>
        </Stack>
        </LoadingContext.Provider>
        </LayoutContext.Provider>
    )
}

export default Layout