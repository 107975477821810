import { IconButton, Stack, Typography } from "@mui/material"
import { Editor } from "@tinymce/tinymce-react"
import { useRef } from "react"
import { toast } from "react-hot-toast"
import { BiReset } from "react-icons/bi"
import { useComposePageContext } from "../../contexts/composePageContext"





const TinyMCEEditor = () => {
    const {emailData, setEmailData} = useComposePageContext()

    const editorRef = useRef(null)

    const handleChange = (value: string) => {
        setEmailData((prevValue) => ({...prevValue, body: value}))
    }


    return (
        <Stack
        sx={{
            '& .tox-menubar > button': {
                width: 'unset !important'
            },
            position: 'relative',
        }}
        >
        <Editor
        apiKey='9kurhlzgsmjoxffwtzariy6y95fiwkz5wkcekycdww6nwv5f'
        onInit={(evt, editor) => editorRef.current = editor}
        // initialValue="<p>Write your email text...</p>"
        onEditorChange={handleChange}
        value={emailData?.body}
        init={{
        height: 500,
        plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
        ],
        toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
        />


        {
        emailData?.object &&
        <Stack
        sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 2,
            top: 0,
            bgcolor: 'rgb(0,0,0,0.5)',
            alignItems: 'center',
            justifyContent: 'center',
        }}
        >
        <Typography
        sx={{color: '#fff !important', fontWeight: 600}}
        >
        This editor is disabled because you are using the full email editor
        </Typography>
        <Typography
        sx={{color: '#fff !important', fontWeight: 600}}
        >
        Reset mail to use this editor
        </Typography>
        <IconButton
        sx={{
            borderRadius: '5px',
            color: 'red'
        }}
        onClick={() => {
            setEmailData( (prevEmailData) => ({...prevEmailData, body: '', object: null}) );
            toast('Mail Reset!')
        }}
        >
        <BiReset size={25} />
        <Typography component='span'>Reset</Typography>
        </IconButton>
        </Stack>
        }
        </Stack>
    )
}


export default TinyMCEEditor