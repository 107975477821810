import { Alert, Stack, TextField, Typography } from "@mui/material"
import CustomButton from "../../Button"
import VCodeTextField from "../../VCodeTextField"
import { useBreakpoints } from "../../../theme/breakpoints"
import { useRegisterPageContext } from "../../../contexts/registerPageContext"
import { useState } from "react"
import client from "../../../utils/axios"



const EmailVerify = () => {
    const {xs} = useBreakpoints()
    const {registerDetails, setRegisterDetails, setCurrentScreen} = useRegisterPageContext()
    const [sendCodeLoading, setSendCodeLoading] = useState(false)
    const [verifyCodeLoading, setVerifyCodeLoading] = useState(false)
    const [resMessage, setResMessage] = useState({success: false, message: ''})


    const handleVerifyEmail = async (vCode) => {
        setVerifyCodeLoading(true)
        try{
            const {message} = await client.post('/activateMail', {
                mail: registerDetails?.email, 
                authCode: vCode,
            }, {isReniAuth: true, noUserToken: true})

            setResMessage( (prevVal) => ({...prevVal, message, success: true}) )
            setTimeout( () => {
                setCurrentScreen('register')
            }, 1500 )
        }
        catch(err){
            console.log(err.message)
            setResMessage( (prevVal) => ({...prevVal, message: err.message, success: false}) )
        }
        finally{
            setVerifyCodeLoading(false)
        }
    }

    const handleSendCode = async (e) => {
        e.preventDefault()
        setSendCodeLoading(true)
        try{
            const {message} = await client.post('/registerMail', {
                mail: registerDetails?.email,
                usertoken: undefined
            }, {isReniAuth: true})

            setResMessage( (prevVal) => ({...prevVal, message, success: true}) )
        }
        catch(err){
            console.log(err.message)
            setResMessage( (prevVal) => ({...prevVal, message: err.message, success: false}) )
        }
        finally{
            setSendCodeLoading(false)
        }
    }

    const clearResponseMessage = () => {
        setResMessage( (prevVal) => ({...prevVal, message: ''}) )
    }


    return (
        <Stack
        sx={{
            gap: '20px',
            width: '100%'
        }}
        >
        {
        resMessage?.message &&
        <Alert
        severity={resMessage?.success ? "success" : "error"}
        >
        <Typography>{resMessage?.message}</Typography>
        </Alert>
        }
        <form
        onSubmit={handleSendCode}
        style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
        }}
        >
        <Stack sx={{width: '100%'}}>
        <Typography>
        Email
        </Typography>
        <TextField 
        fullWidth
        type="email"
        value={registerDetails?.email}
        onChange={ (e) => setRegisterDetails( (prevVal) => ({...prevVal, email: e.target.value}) ) }
        sx={{
            '& input': {
                p: '10px 15px',
            }
        }}
        />
        </Stack>

        <CustomButton 
        title='Send'
        type='submit'
        variant="contained"
        squareCorners
        onClick={handleSendCode}
        loading={sendCodeLoading}
        sx={{
            alignSelf: 'flex-start',
            textTransform: 'unset'
        }}
        />
        </form>

        <Stack>
        <Typography>
        Verification Code
        </Typography>
        <Stack
        direction='row'
        sx={{justifyContent: 'space-between', alignItems: 'center', gap: xs ? '10px' : '20px'}}
        >
        <VCodeTextField 
        code={registerDetails?.vCode}
        onChange={(val) => setRegisterDetails( (prevVal) => ({...prevVal, vCode: val}) )}
        submit={handleVerifyEmail}
        clearMessage={clearResponseMessage}
        />
        <CustomButton 
        title='Verify'
        variant="contained"
        squareCorners
        onClick={() => handleVerifyEmail(registerDetails?.vCode)}
        sx={{
            height: '100%',
            px: '20px',
            textTransform: 'unset'
        }}
        disabled={registerDetails?.vCode?.length !== 5 || registerDetails?.vCode?.includes(' ')}
        loading={verifyCodeLoading}
        />
        </Stack>
        </Stack>
        </Stack>
    )
}

export default EmailVerify