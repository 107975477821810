import { Stack, Typography } from "@mui/material"
import Layout from '../../../Layouts/DashboardLayout'
import { useBreakpoints } from "../../../../theme/breakpoints"
import { useLayoutContext } from "../../../../contexts/layoutContext"
import BackButton from "../../../BackButton"
import ListForm from "./ListForm"
import ListPageContext from "../../../../contexts/listPageContext"
import { useLocation } from "react-router-dom"



const CreateList = () => {
    const {miniSideBar} = useLayoutContext()
    const {mdOnly, md, sm} = useBreakpoints()
    const pathname = useLocation().pathname

    return (
        <ListPageContext.Provider
        value={{
            editPage: pathname.includes('/dashboard/lists/edit/')
        }}
        >
        <Stack
        sx={{
            p: (!miniSideBar && mdOnly) ? '50px 20px' : sm ? '15px' : '50px 50px',
            gap: '30px'
        }}
        >
        <BackButton />


        <Stack
        sx={{
            ...!sm && {
                width: !md ? '70%' : '85%',
                alignSelf: 'center',
                boxShadow: '0 0 10px rgb(0,0,0,.125)',
                p: '20px',
                borderRadius: '20px'
            }
        }}
        >
        <ListForm
        />
        </Stack>
        </Stack>
        </ListPageContext.Provider>
    )
}




const CreateListPage = () => {


    return (
        <Layout>
        <CreateList />
        </Layout>
    )
}

export default CreateListPage