import { LocalizationProvider } from '@mui/x-date-pickers'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { IDatePicker } from './types'



const DatePicker = ({date, setDate}: IDatePicker) => {


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker 
        disablePast
        defaultValue={dayjs(date)}
        value={dayjs(date)}
        componentsProps={{actionBar: {actions : []}}}
        onChange={(date) => setDate && setDate(new Date(date as unknown as Date))}
        sx={{
            '& .MuiPickersToolbar-root': {
                p: 0
            },
            '& .MuiPickersCalendarHeader-root': {
                pl: 0,
                pr: '35px'
            },
            '& .MuiDayCalendar-header': {
                transform: 'translateX(-32px)'
            },
            '& .MuiDayCalendar-monthContainer': {
                transform: 'translateX(-32px)'
            },
        }}
        />
        </LocalizationProvider>
    )
}


export default DatePicker