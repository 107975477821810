import { Pagination, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import { useBreakpoints } from "../../../theme/breakpoints"
import TableDivider from "../../TableDivider"
import TableButton from "./TableButton"
import { useListPageContext } from "../../../contexts/listPageContext"
import { perPage } from "../../../utils/functions/fetchData"
import { Fragment } from "react"




const Lists = ({lists, loading, setReloadLists}) => {
    const {sm} = useBreakpoints()
    const {currentTab,
        totalLists,
        totalArchivedLists,
        listsPage, setListsPage,
        archivedListsPage, setArchivedListsPage} = useListPageContext()

    return (
        <Stack>
        <TableContainer
        sx={{
            minHeight: '230px',
        }}
        >
        <Table
        sx={{
            minWidth: 650,
            '& th, td': {
                p: '10px',
                fontWeight: 500,
                ...sm && {fontSize: '.9rem'}
            }
        }}
        stickyHeader
        >
            <TableHead sx={{bgcolor: 'transparent'}}>
            <TableRow>
                <TableCell>TITLE</TableCell>
                <TableCell>DESCRIPTION</TableCell>
                <TableCell align="center">TOTAL CONTACTS</TableCell>
                <TableCell align="center"></TableCell>
            </TableRow>
            <TableDivider colSpan={4} />
            </TableHead>
            <TableBody sx={{bgcolor: 'transparent'}}>
            {   !loading && !lists.length ? 
                <TableRow
                sx={{
                    bgcolor: '#F1F1F1'
                }}
                >
                    <TableCell colSpan={4} rowSpan={3} sx={{textAlign: 'center'}}>
                    {currentTab==='lists' ? 'No Lists found' : 'No Archived Lists found'}
                    </TableCell>
                </TableRow> :
                
                !loading ? (
                lists.map(({title, description, totalContacts, id, date}, k, list) => (
                    <List 
                    key={id}
                    id={id}
                    title={title}
                    description={description}
                    totalContacts={totalContacts}
                    date={date}
                    setReloadLists={setReloadLists}
                    list={list[k]}
                    />
                ))
            ) : (
                Array.from({length: 3}).map( (_, k) => (
                    <Fragment key={k}>
                    <TableRow
                    key={k}
                    >
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                    <TableCell align="center"><Skeleton /></TableCell>
                    <TableCell align="center"><Skeleton /></TableCell>
                    </TableRow>
                    <TableDivider colSpan={4} />
                    </Fragment>
                ) )
            )}

            </TableBody>
        </Table>
        </TableContainer>


        {
            !loading && lists.length !== 0 &&
            <Pagination
            count={currentTab==='lists' ? Math.ceil(totalLists/perPage) : Math.ceil(totalArchivedLists/perPage)}
            size={ sm ? 'medium' : 'large'}
            page={currentTab==='lists' ? listsPage : archivedListsPage}
            sx={{
                alignSelf: 'flex-end',
                width: 'fit-content !important'
            }}
              onChange={(e, page) => currentTab==='lists' ? setListsPage(page) : setArchivedListsPage(page)}
            />
        }
        </Stack>
    )
}


export default Lists



const List = ({title, description, totalContacts, id, date, list}) => {


    return (
        <>
        <TableRow
        key={id}
        >
            <TableCell>
            <Typography sx={{color: 'primary.main', fontWeight: 600}}>
            {title}
            </Typography>
            <Typography sx={{fontSize: '.85rem'}}>
                {date}
            </Typography>
            </TableCell>
            <TableCell>
            <Typography>
            {description}
            </Typography>
            </TableCell>
            <TableCell align="center">
            <Typography>
            {totalContacts + ' Contacts'}
            </Typography>
            </TableCell>
            <TableCell align="center">
            <TableButton
            listData={list}
            />
            </TableCell>
        </TableRow>
        <TableDivider colSpan={4} />
        </>
    )
}