import React from 'react'
import AboutFuture from '../Components/Landingspage/AboutPage/AboutFuture'
import HeroAbout from '../Components/Landingspage/AboutPage/HeroAbout'
import MeetTeam from '../Components/Landingspage/AboutPage/MeetTeam'
import ReniCulture from '../Components/Landingspage/AboutPage/ReniCulture'
import Layout from '../Components/Landingspage/Layout'

const About = () => {
  return (
    <>
      <Layout>
        <HeroAbout />
        <ReniCulture />
        <MeetTeam />
        <AboutFuture />
      </Layout>
    </>
  )
}

export default About