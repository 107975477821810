import React, { useState } from 'react'
import trustimg from '../Img/Group 105.png'
import business from '../Img/business3 (2).png'
import creators from '../Img/_Downloader 1.png'
import Musicians from '../Img/man-890877-removebg-preview 1.png'
import Photographs from '../Img/photograph2 (2).png'
import business2 from '../Img/Rectangle 139.png'
import Musicians2 from '../Img/Rectangle 140.png'
import Photographs2 from '../Img/Rectangle 141.png'
import creators2 from '../Img/Rectangle 142.png'
import { FaArrowRight } from 'react-icons/fa'

import '../Homepage/Tabs.css'

const Tabs = () => {
  const [toggleState, setToggleState] = useState(1)

  const toggleTab = (index) => {
    setToggleState(index)
  }
  return (
    <div className='mainTab'>
      <div className='container-fluid contain'>
        <div className='tabscontaniner'>
          <div className='tabcontents'>
            <div className='trust'>
              <img src={trustimg} alt='' className='img-fluid' />
            </div>
            <div className='tabs-bloc d-flex justify-content-around'>
              <div
                className={toggleState === 1 ? 'tabs active-tabs' : 'tabs'}
                onClick={() => toggleTab(1)}
              >
                photograph
              </div>
              <div
                className={toggleState === 2 ? 'tabs active-tabs' : 'tabs'}
                onClick={() => toggleTab(2)}
              >
                musicians
              </div>
              <div
                className={toggleState === 3 ? 'tabs active-tabs' : 'tabs'}
                onClick={() => toggleTab(3)}
              >
                businesses
              </div>
              <div
                className={toggleState === 4 ? 'tabs active-tabs' : 'tabs'}
                onClick={() => toggleTab(4)}
              >
                creators
              </div>
            </div>
            <div
              className={
                toggleState === 1 ? 'contents  active-contents' : 'contents'
              }
            >
              <div className='content-text'>
                <h1>
                  We help <span>photographers</span> tell real stories
                </h1>
                <p>
                  Sharing my journey and telling stories through photography has
                  never been easier. Now I can share my process and help others.{' '}
                </p>
                <small>Lola Craft, Photographer</small>
                <p style={{whiteSpace: 'nowrap'}}>
                  Learn more about Renimail for photographers
                  <FaArrowRight />
                </p>
              </div>

              <div className='contentsimg2 d-lg-none w-100'>
                <img src={Photographs2} alt='' className='img-fluid' />
              </div>
            </div>
            <div
              className={
                toggleState === 2 ? 'contents  active-contents' : 'contents'
              }
            >
              <div className='content-text'>
                <h1>
                  We help <span>musicians</span> dazzle their fans
                </h1>
                <p>
                  “Whether I’m planning a tour or selling records, I need to
                  reach my audience directly with no agent.{' '}
                  <span>ReniMail</span> lets me be in charge of my promotion.”{' '}
                </p>
                <small>-John Craft, Gospel Artist</small>
                <p style={{whiteSpace: 'nowrap'}}>
                  Learn more about Renimail for musicians
                  <FaArrowRight />
                </p>
              </div>
              <div className='contentsimg2 d-lg-none w-100'>
                <img src={Musicians2} alt='' className='w-100' />
              </div>
            </div>
            <div
              className={
                toggleState === 3 ? 'contents  active-contents' : 'contents'
              }
            >
              <div className='content-text'>
                <h1>
                  We help <span>businesses</span> reach more clients
                </h1>
                <p>
                  “ With <span>ReniMail,</span> marketing is more effective and
                  making a living online has never been more efficient. The best
                  email tools for any business.”{' '}
                </p>
                <small>-Lola Craft, Sales Department Head</small>
                <p style={{whiteSpace: 'nowrap'}}>
                  Learn more about Renimail for businesses
                  <FaArrowRight />
                </p>
              </div>

              <div className='contentsimg2 d-lg-none w-100'>
                <img src={business2} alt='' className='w-100' />
              </div>
            </div>
            <div
              className={
                toggleState === 4 ? 'contents  active-contents' : 'contents'
              }
            >
              <div className='content-text'>
                <h1>
                  We help <span>creators</span> express their thoughts
                </h1>
                <p>
                  “As a creator with a focus on making the world a better place,
                  laying out my ideas and plans is as important as getting it
                  done, <span>ReniMail</span> is my go-to{' '}
                </p>
                <small>-John Craft, Brand Strategist</small>
                <p style={{whiteSpace: 'nowrap'}}>
                  Learn more about Renimail for creators
                  <FaArrowRight />
                </p>
              </div>
              <div className='contentsimg2 d-lg-none w-100'>
                <img src={creators2} alt='' className='w-100' />
              </div>
            </div>
          </div>
          <div className='creatorimmg d-none d-lg-flex'>
            <div
              className={
                toggleState === 1 ? 'contents  active-contents' : 'contents'
              }
            >
              <div className='contentsimg d-none d-lg-flex  justify-content-center align-items-end'>
                <img src={Photographs} alt='' />
              </div>
            </div>
            <div
              className={
                toggleState === 2 ? 'contents  active-contents' : 'contents'
              }
            >
              <div className='contentsimg d-none d-lg-flex justify-content-center'>
                <img src={Musicians} alt='' />
              </div>
            </div>
            <div
              className={
                toggleState === 3 ? 'contents  active-contents' : 'contents'
              }
            >
              <div className='contentsimg d-none d-lg-flex  justify-content-center'>
                <img src={business} alt='' />
              </div>
            </div>
            <div
              className={
                toggleState === 4 ? 'contents  active-contents' : 'contents'
              }
            >
              <div className='contentsimg d-none d-lg-flex  justify-content-center'>
                <img src={creators} alt='' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tabs
