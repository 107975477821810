import { Stack, Typography } from "@mui/material"
import { settingsTabs } from "./settings-data"
import { useLocation, useNavigate } from "react-router-dom"
import { useBreakpoints } from "../../../theme/breakpoints"
import { IoMdArrowDropdown } from "react-icons/io"
import { useState } from "react"
import { Fragment } from "react"



const SettingsTabs = () => {
    const pathname = useLocation().pathname
    const navigate = useNavigate()
    const {sm, md} = useBreakpoints()
    const [tabs, setTabs] = useState(settingsTabs)

    // For md and mobile devices
    const [openFullTabs, setOpenFullTabs] = useState(false)

    const currentRoute = (route) => {
        if(pathname === '/settings' && route === '/settings/account'){
            return true
        }
        return pathname === route
    }



    const handleClick = (route, index) => {
        const tabsCopy = [...tabs]
        const tabToMove = tabsCopy.splice(index, 1)[0]
        tabsCopy.unshift(tabToMove)

        md && setTabs(tabsCopy)

        if(!currentRoute(route)){
            return navigate(route)
        }
        return setOpenFullTabs(!openFullTabs)
    }

    return (
        <Stack
        sx={{
            gap: '20px',
            // boxShadow: '0 0 10px rgb(0,0,0,.125)',
            // p: '10px',
            // borderRadius: '15px'
        }}
        >
            {
                tabs.map( ({title, id, route}, k) => (
                    <Fragment
                    key={id}
                    >
                    { ((md && currentRoute(route)) || openFullTabs || !md) &&
                    <Stack
                    direction='row'
                    sx={{
                       cursor: 'pointer',
                       ...(currentRoute(route)) && {
                        color: 'neutral.50',
                        bgcolor: 'primary.main',
                        p: '10px',
                        borderRadius: '25px',
                       },
                       transition: 'all 0.2s linear',
                       justifyContent: 'space-between',
                       whiteSpace: 'nowrap'
                    }}
                    onClick={() => handleClick(route, k)}
                    >
                    <Typography
                    sx={{
                        fontSize: !sm ? '1.2rem' : '1rem',
                        fontWeight: '400',
                        ...(currentRoute(route)) && {fontWeight: '500'}
                    }}
                    >
                    {title}
                    </Typography>

                    {
                        md && (currentRoute(route)) &&
                        <IoMdArrowDropdown size={25}
                        style={{
                            transition: '.2s ease',
                            ...openFullTabs && {transform: 'rotate(180deg)'}
                        }}
                        />
                    }
                    </Stack>
                    }
                    </Fragment>
                ) )
            }           
        </Stack>
    )

}


export default SettingsTabs