

const removeHtmlElements = (inputString) => {
    const parser = new DOMParser();
  
    const document = parser.parseFromString(inputString, 'text/html');
  
    let textContent = document.body.textContent;

    textContent = textContent.replace(/\\n|\\r|\\t|\\f|\\v|\\'/g, '');
  
    return textContent;
}

export default removeHtmlElements