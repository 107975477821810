import React, {useState} from 'react'
import { ScaleLoader } from 'react-spinners'

import './AppLoader.css'
import { primary } from '../theme/palette'
import { Stack } from '@mui/material'
import { useLayoutContext } from '../contexts/layoutContext'
import { useBreakpoints } from '../theme/breakpoints'

const AppLoader = (props) => {

  const {
    miniSideBar,
    miniSideBarWidth,
    sidebarWidth
  } = useLayoutContext()
  const {sm} = useBreakpoints()


  return (
      <Stack
      component='main'
      sx={{
        width: sm ? '100%' : `calc(100% - ${miniSideBar ? miniSideBarWidth : sidebarWidth})`,
        height: '100vh',
        position: 'fixed',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'flex-end',
        bgcolor: 'rgb(255,255,255,.600)',
        zIndex: 999
      }}
      >
        <ScaleLoader
          className='d-flex'
          // color={'#023676'}
          color={primary.main}
          loading={props.loading}
          // height='70'
          // margin='4'
          // radius='10'
          speedMultiplier='1.2'
        />
      </Stack>
  )
}

export default AppLoader
