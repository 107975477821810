import React from 'react'
import { Link } from 'react-router-dom'


import '../AboutPage/AboutFuture.css'
import { neutral } from '../../../theme/palette'
import { Typography } from '@mui/material'
import CustomButton from '../../Button'
const AboutFuture = () => {
  return (
    <>
      <div className='container-fluid d-flex justify-content-center align-items-center aboutfuture'>
        <div className='Aboutfuturecontent d-flex justify-content-center align-items-center flex-column'>
          <h1>Creators determine the future</h1>
          <p>
            Creators change the world. <span>Renimail</span> has helped your
            favourite creatives earn a living online with email. It&apos;s never been
            more effective.And you can do it all for free
          </p>
          <CustomButton
          sx={{p: '15px 20px', borderRadius: '5px', alignSelf: 'center'}}
          link
          linkProps={{
            to: '/register'
          }}
          >
          <Typography component='span' sx={{color: 'neutral.50'}}>Get Started Now</Typography>
          </CustomButton>
        </div>
      </div>
    </>
  )
}

export default AboutFuture