import { Stack } from "@mui/material"
import SubHistory from './SubHistory'
import MyPlan from "./MyPlan"



const Subscription = () => {

    return (
        <Stack sx={{
            gap: '50px'
        }}>
            <MyPlan />
            <SubHistory />
        </Stack>
    )
}

export default Subscription