import { Stack, Typography } from "@mui/material"
import Layout from "../../Layouts/DashboardLayout"
import ComposeCard from "./ComposeCard"
import { useLayoutContext } from "../../../contexts/layoutContext"
import { useBreakpoints } from "../../../theme/breakpoints"
import { useEffect, useState } from "react"
import ScheduleCard from "./ScheduleCard"
import ComposePageContext from "../../../contexts/composePageContext"
import { toast } from "react-hot-toast"
import { useLocation, useParams } from "react-router-dom"
import { useLoadingContext } from "../../../contexts/loadingContext"
import { fetchFromLocalStorage, updateLocalStorage } from "../../../utils/functions/updateStorage"
import removeHtmlElements from "../../../utils/functions/removeHTMLEls"
import { ICurrentScreen, IEditorType, IEmailData, IHandleSendEmail } from "./types"
import { IObjectAny } from "../../../types"
import client from "../../../utils/axios"
import useErrToasterStore from "../../../stores/toasterStore"


let timesMessageShown = 0

const ComposePage = () => {
    
    const [currentScreen, setCurrentScreen] = useState<ICurrentScreen>('default')
    const {miniSideBar} = useLayoutContext()
    const {mdOnly, sm} = useBreakpoints()
    const {setGlobalLoading} = useLoadingContext()
    const pathname = useLocation().pathname
    const isMailEdit = pathname.includes('mail')

    const storedEmailData = fetchFromLocalStorage('userSettings')?.emailData
    const [editorType, setEditorType] = useState<IEditorType>(Number(fetchFromLocalStorage('userSettings')?.editorType) as IEditorType || 0)
    const errorToast = useErrToasterStore(state => state.showToast)

    
    const nullEmailData = {
        data: [],
        subject: '',
        body: '',
        object: null,
        scheduleTime: null,
        scheduleDate: null,
        custom_mail_id: 'default'
    }
    const initialEmailData = {
        data: (!isMailEdit && storedEmailData?.data) || nullEmailData.data,
        subject: (!isMailEdit && storedEmailData?.subject) || nullEmailData.subject,
        body: (!isMailEdit && storedEmailData?.body) || nullEmailData.body,
        object: (!isMailEdit && storedEmailData?.object) || nullEmailData.object,
        scheduleTime: (!isMailEdit && storedEmailData?.scheduleTime) || nullEmailData.scheduleTime,
        scheduleDate: (!isMailEdit && storedEmailData?.scheduleDate) || nullEmailData.scheduleDate,
        custom_mail_id: (!isMailEdit && storedEmailData?.custom_mail_id) || nullEmailData.custom_mail_id,
    }
    const [emailData, setEmailData] = useState<IEmailData>(initialEmailData)


    const showContinueEditingMessage = () => {
        // If storedEmailData exist and at least one of it's keys has a non-empty value
        if(timesMessageShown===0 &&
            storedEmailData && (
                Object.values(storedEmailData).some(value => value !== '' && value !== null && value !== undefined && !(Array.isArray(value) && value.length === 0) && !(typeof value === 'object' && Object.keys(value).length === 0)) &&
                removeHtmlElements(storedEmailData?.body)
                )
        ){
            toast(<Typography variant="h6" sx={{whiteSpace: 'nowrap'}}>Continue where you left off 😊</Typography>)
            ++timesMessageShown
        }
    }

    useEffect( () => {
        showContinueEditingMessage()
    }, [] )
    // useEffect( () => {
    //    if(!(fetchFromLocalStorage('userSettings')?.emailData)){
    //     updateLocalStorage('userSettings', {emailData: initialEmailData})
    //    }
    // } )



    // For Compose Edit Page
    const handleLoadEmailData = async (id: string) => {
        setGlobalLoading(true)
            try{
                const {data} = await client.post(
                    isMailEdit ? '/getMailDetails' : '/draftMailDetails',
                    {
                        ...isMailEdit ? {mailId: id} : {draftId: id},
                    },
                    {noError: true}
                )
                let {listid, subject, message, body, object, custom_mail_id} = data as IObjectAny
                if(object){
                    setEditorType(1)
                }
                else{
                    setEditorType(0)
                }
                if(typeof object === 'string'){
                    try{
                        object =  JSON.parse(decodeURIComponent((atob(object))))
                    }
                    catch(err){}
                }
                try{
                    subject = decodeURIComponent(subject)
                }
                catch(err){}
                try{
                    body = decodeURIComponent(body)
                    message = decodeURIComponent(message)
                }
                catch(err){}

                setEmailData({
                    data: Array.isArray(listid) ? listid : [listid],
                    subject,
                    body: isMailEdit ? message : body,
                    object,
                    custom_mail_id
                })
            }
            catch(err){
                console.error(err.message)
            }
            finally{
                setGlobalLoading(false)
                window.history.replaceState({}, '', '/dashboard/compose')
            }
    }



    const {id} = useParams()
    
    useEffect( () => {
        if(id){
            handleLoadEmailData(id)
        }
    }, [] )

    const resetEmailData = () => {
        setEmailData(nullEmailData);
        updateLocalStorage('userSettings', {emailData: nullEmailData});
        setEditorType(0);
    }


    const handleSendEmail: IHandleSendEmail = async (type, setLoading) => {
        // type can be draft, schedule or send
        setLoading && setLoading(true)
        try{
            const emptySchedule = {scheduleTime: undefined, scheduleDate: undefined}

            let payload = emailData.custom_mail_id==='default' ? {...emailData, custom_mail_id: undefined} : emailData
            const {object, body, subject, ...rest} = payload
            payload = {
                subject: encodeURIComponent(subject),
                body: encodeURIComponent(body),
                object: object ? btoa(encodeURIComponent(JSON.stringify(object))) : undefined,
                ...rest
            }

            if(type === 'send'){
                var {success, message} = await client.post('/sendMailToList', {
                    ...payload, ...emptySchedule
                }, {version: 3})
            }
            if(type === 'draft'){
                const {data, ...draftPayload} = payload
                var {success, message} = await client.post('/saveMailToDraft', {
                    ...draftPayload, ...emptySchedule
                }, {version: 3})
            }
            if(type === 'schedule'){
                var {success, message} = await client.post('/sendMailToList-Schedule', payload, {version: 3})
            }
            toast.success(message)
            if(success){
                resetEmailData()
                setCurrentScreen('default')
            }
        }
        catch(err){
            console.error(err.message)
            errorToast(err.message)
        }
        finally{
            setLoading && setLoading(false)
        }
    }


    return (
        <ComposePageContext.Provider 
        value={{
            currentScreen, 
            setCurrentScreen, 
            handleSendEmail,
            emailData,
            setEmailData,
            initialEmailData,
            resetEmailData,
            editorType,
            setEditorType
        }}>
        <Stack
        sx={{
            p: (!miniSideBar && mdOnly) ? '50px 20px' : sm ? '15px' : '50px 50px',
        }}
        >
        {
            currentScreen === 'default' &&
            <ComposeCard />
        }
        {
            currentScreen === 'schedule-card' &&
            <ScheduleCard />
        }

        {/* <EmailEditor /> */}
        </Stack>
        </ComposePageContext.Provider>
    )
}



const Compose = () => {
    return (
        <Layout>
            <ComposePage />
        </Layout>
    )
}

export default Compose