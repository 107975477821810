import { Skeleton, Slide, Stack, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { FiCalendar } from 'react-icons/fi';
import CustomButton from '../../../Button';
import { useBreakpoints } from '../../../../theme/breakpoints';

const Chart = ({chartData, startDate, endDate, loading, filterData, currentMonth, setMonth}) => {
  const {xs, sm, md} = useBreakpoints()
  const [showFilter, setShowFilter] = useState(false)


    const MIN_HEIGHT = 3
    const MAX_HEIGHT = 300

    const mapValueToHeight = (value) => {
        const minValue = Math.min(...chartData.x)
        const maxValue = Math.max(...chartData.x)
        
        const normalizedValue = (value - minValue) / (maxValue - minValue)
        return MIN_HEIGHT + (normalizedValue * (MAX_HEIGHT - MIN_HEIGHT))
    }

  return (
    <Stack
    sx={{
      width: '100%',
      minHeight: '300px',
      bgcolor: 'transparent',
      gap: '20px',
      overflow: 'hidden'
    }}
    >
    <Typography sx={{p: '0 10px', fontWeight: 500, textDecoration: 'underline'}}>
      Opens
    </Typography>

      <CustomButton
      variant='outlined'
      sx={{
          alignSelf: 'flex-start', alignItems: 'center',
          gap: '7px', p: '7px', ml: '13px',
          border: '1px solid', borderRadius: '15px', borderColor: 'neutral.900',
          position: 'relative',
          zIndex: 1
      }}
      onClick={(e) => {e.stopPropagation(); setShowFilter(!showFilter)}}
      >
          <FiCalendar size={13} />
          <Typography sx={{fontSize: '.8rem'}}>
              {!loading ? `${startDate} - ${endDate}` : <Skeleton width={30} height={20} />}
          </Typography>

          <Slide
          direction="down"
          mountOnEnter
          unmountOnExit
          in={showFilter}
          >
          <Stack
          sx={{
            position: 'absolute', top: '100%', gap: '10px',
            borderRadius: '10px',
            boxShadow: '0 0 10px rgb(0,0,0,.125)',
            maxHeight: '150px', width: '100%',
            overflow: 'scroll',
            bgcolor: 'neutral.50'
          }}
          >
          {
          filterData &&
          filterData.map( ({value, startDate, endDate}, k) => (
            <CustomButton
            key={k}
            component='span'
            variant='outlined'
            sx={{
                alignSelf: 'center', alignItems: 'center',
                gap: '7px', p: '7px',
                border: 'none', borderRadius: 0,
                minWidth: 'unset', width: '100%',
                '&:hover': {
                  bgcolor: 'primary.main',
                  color: 'neutral.50'
                },
              ...currentMonth===value && {
                bgcolor: 'primary.main',
                color: 'neutral.50'
              },
            }}
            onClick={() => setMonth && setMonth(value)}
            >
            <Typography sx={{fontSize: '.8rem'}}>
                {!loading ? `${startDate} - ${endDate}` : <Skeleton width={30} height={20} />}
            </Typography>
            </CustomButton>
          ) )
          }
          </Stack>
          </Slide>
      </CustomButton>

      <Stack
      direction='row'
      sx={{
        justifyContent: 'space-between',
        mt: 'auto',
      }}
      >
        {
            !loading ? 
            chartData?.x?.map( (value, k) => (
                <Bar 
                key={k}
                value={value}
                computeHeight={mapValueToHeight}
                title={chartData?.y[k]}
                />
            ) ) :

            Array.from({length: 30}).map( (_, k) => (
                <BarLoading 
                key={k}
                />
            ) )
        }
      </Stack>

    <Stack 
    direction='row' 
    sx={{
      justifyContent: 'space-between',
      '& p': {
        fontSize: xs ? '.3rem' : sm ? '.5rem' : '.6rem'
      }
    }}>
        <Typography>
            {!loading ? startDate : <Skeleton width={30} height={20} />}
        </Typography>
        <Typography>
        {!loading ? endDate : <Skeleton width={30} height={20} />}
        </Typography>
    </Stack>
    </Stack>
  );
};

export default Chart;



const Bar = ({value, computeHeight, title}) => {
    const {xs, sm, md} = useBreakpoints()

    return (
      <Tooltip 
      title={`${value} opens`}
      placement="top-start">
        <Stack 
        sx={{
            transition: 'all linear .3s',
            width: xs ? '5px' : sm ? '8px' : md ? '10px' : '15px',
            height: `${computeHeight(value)}px`,
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            bgcolor: 'primary.main',
            alignSelf: 'flex-end',
            cursor: 'pointer',
            position: 'relative'
        }}
        >
        <Typography
        sx={{
          position: 'absolute',
          bottom: '-17px',
          fontSize: xs ? '.3rem' : sm ? '.5rem' : '.6rem'
        }}
        >
        {title}
        </Typography>
        </Stack>
      </Tooltip>
    )
}

const BarLoading = () => {
    const {xs, sm, md} = useBreakpoints()
    const [height, setHeight] = useState(3)
    const [bgcolor, setBgColor] = useState('#E0E0E0')

    useEffect(() => {
        const interval = setInterval(() => {
          const randomHeight = Math.floor(Math.random() * (300 - 3 + 1)) + 3
          setHeight(randomHeight)
        }, 500)

        setBgColor((prevColor) => (prevColor === '#E0E0E0' ? '#F5F5F5' : '#E0E0E0'))
    
        return () => {
          clearInterval(interval)
        }
      }, [])

    return (
        <Stack 
        sx={{
            transition: 'linear .3s',
            width: xs ? '5px' : sm ? '8px' : md ? '10px' : '15px',
            height: `${height}px`,
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            bgcolor,
            alignSelf: 'flex-end'
        }}
        />
    )
}