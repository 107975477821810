import { Pagination, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import { useBreakpoints } from "../../../theme/breakpoints"
import TableDivider from "../../TableDivider"
import removeHtmlElements from "../../../utils/functions/removeHTMLEls"
import TableButton from "./TableButton"
import { useEmailsPageContext } from "../../../contexts/emailsPageContext"
import { perPage } from "../../../utils/functions/fetchData"
import { Fragment } from 'react'




const EmailsTable = ({emails, loading}) => {
    const {sm} = useBreakpoints()
    const {currentTab,
        emailsPage, setEmailsPage,
        archivedPage, setArchivedPage,
        draftsPage, setDraftsPage,
        totalEmails,
        totalArchivedEmails,
        totalDrafts} = useEmailsPageContext()


    return (
        <Stack>
        <TableContainer
        sx={{
            minHeight: '230px',
        }}
        >
        <Table
        sx={{
            minWidth: 650,
            '& th, td': {
                p: '10px',
                fontWeight: 500,
                ...sm && {fontSize: '.9rem'}
            }
        }}
        stickyHeader
        >
            <TableHead sx={{bgcolor: 'transparent'}}>
            <TableRow>
                <TableCell>SENT TO</TableCell>
                <TableCell>SUBJECT</TableCell>
                <TableCell align="center">CONTENT</TableCell>
                <TableCell align="center"></TableCell>
            </TableRow>
            <TableDivider colSpan={4} />
            </TableHead>
            <TableBody sx={{bgcolor: 'transparent'}}>
            {   !loading && !emails.length ? 
                <TableRow
                sx={{
                    bgcolor: '#F1F1F1'
                }}
                >
                    <TableCell colSpan={4} rowSpan={3} sx={{textAlign: 'center'}}>
                    {currentTab==='overview' ? 'No Emails found' :
                    currentTab==='archived-emails' ? 'No Archived Emails found' : 
                    'No Draft found'
                    }
                    </TableCell>
                </TableRow> :
                
                !loading ? (
                emails.map(({sentTo, date, subject, content, id}, k, email) => (
                    <Email
                    key={id}
                    sentTo={sentTo}
                    date={date}
                    subject={subject}
                    content={content}
                    email={email[k]}
                    />
                ))
            ) : (
                Array.from({length: 3}).map( (_, k) => (
                    <Fragment
                    key={k}
                    >
                    <TableRow
                    key={k}
                    >
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                    <TableCell align="center"><Skeleton /></TableCell>
                    <TableCell align="center"><Skeleton /></TableCell>
                    </TableRow>
                    <TableDivider colSpan={4} />
                    </Fragment>
                ) )
            )}

            </TableBody>
        </Table>
        </TableContainer>


        {
            !loading && emails.length !== 0 &&
            <Pagination
            count={currentTab==='overview' ? Math.ceil(totalEmails/perPage) :
                    currentTab==='archived-emails' ? Math.ceil(totalArchivedEmails/perPage) : 
                    Math.ceil(totalDrafts/perPage)
                }
            size={ sm ? 'medium' : 'large'}
            page={currentTab==='overview' ? emailsPage :
                    currentTab==='archived-emails' ? archivedPage : 
                    draftsPage
                }
            sx={{
                alignSelf: 'flex-end',
                width: 'fit-content !important'
            }}
              onChange={(e, page) => currentTab==='overview' ? setEmailsPage(page) :
                    currentTab==='archived-emails' ? setArchivedPage(page) : 
                    setDraftsPage(page)
                }
            />
        }
        </Stack>
    )
}


export default EmailsTable



const Email = ({id, sentTo, date, subject, content, email}) => {


    return (
        <>
        <TableRow
        key={id}
        >
            <TableCell>
            <Typography sx={{color: 'primary.main', fontWeight: 600}}>
            {'To: ' + sentTo}
            </Typography>
            <Typography sx={{fontSize: '.85rem'}}>
                {date}
            </Typography>
            </TableCell>
            <TableCell>
            <Typography>
            {subject}
            </Typography>
            </TableCell>
            <TableCell align="center">
            <Typography>
            {content && removeHtmlElements(content).slice(0, 20) + `${removeHtmlElements(content).length >= 20 ? ' ...' : ''}`}
            </Typography>
            </TableCell>
            <TableCell align="center">
            <TableButton
            emailData={email}
            />
            </TableCell>
        </TableRow>
        <TableDivider colSpan={4} />
        </>
    )
}