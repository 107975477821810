import { CircularProgress, Divider, IconButton, Slide, Stack, Typography } from "@mui/material"
import { AiFillCaretDown } from "react-icons/ai"
import { MdScheduleSend } from "react-icons/md"
import { RiDraftLine } from "react-icons/ri"
import { primary } from "../../../theme/palette"
import { useState } from "react"
import { useComposePageContext } from "../../../contexts/composePageContext"
import { toast } from "react-hot-toast"
import removeHtmlElements from "../../../utils/functions/removeHTMLEls"
import useClickOutside from "../../../hooks/useClickOutside"
import { ISendEmailType } from "./types"



const SendButton = () => {
    const [loading, setLoading] = useState(false)
    const {setCurrentScreen, emailData, handleSendEmail} = useComposePageContext()
    const [showPopOver, setShowPopOver] = useState(false)
    const sendButtonRef = useClickOutside( () => setShowPopOver(false) )


    const handleSend = (type: ISendEmailType) => {
        if(!emailData?.data || (emailData?.data).length === 0){
            return toast.error(`You haven't chosen a list!`)
        }
        if(!emailData?.subject){
            return toast.error(`Enter a mail subject`)
        }
            if(typeof emailData?.body === 'string' ? !(removeHtmlElements(emailData?.body)) : !emailData?.body){
            return toast.error(`You cannot send an empty email`)
        }
        if(!loading){
            handleSendEmail(type, setLoading)
        }
    }

    const handleClickSchedule = () => {
        if(!emailData?.data || (emailData?.data).length === 0){
            return toast.error(`You haven't chosen a list!`)
        }
        if(!emailData?.subject){
            return toast.error(`Enter a mail subject`)
        }
            if(typeof emailData?.body === 'string' ? !(removeHtmlElements(emailData?.body)) : !emailData?.body){
            return toast.error(`You cannot schedule an empty email`)
        }
        setCurrentScreen('schedule-card')
    }


    const popOverOptions = [
        {
            title: 'Schedule',
            Icon: MdScheduleSend,
            action: handleClickSchedule
        },
        {
            title: 'Save Draft',
            Icon: RiDraftLine,
            action: () => !loading && handleSend('draft')
        }
    ]


    return (
        <Stack
        ref={sendButtonRef}
        direction='row'
        sx={{
            width: '100px',
            height: '40px',
            alignItems: 'center',
            bgcolor: 'primary.main',
            alignSelf: 'flex-start',
            color: 'neutral.50',
            borderRadius: '8px',
            cursor: !loading ? 'pointer' : 'not-allowed',
            ...loading && {bgcolor: 'neutral.500'},
            '& ::selection': {
                bgcolor: 'transparent'
            },
            // overflow: 'hidden',
            position: 'relative',
            zIndex: 1
        }}
        >
            <Stack
            sx={{
                width: '70%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            onClick={ () => handleSend('send')}
            >
            <Typography sx={{
                color: 'neutral.50',
                alignItems: 'center'
            }}>
                {!loading ? 'Send' : <CircularProgress sx={{color: "neutral.50"}} size={25} />}
            </Typography>
            </Stack>
            <Divider
            sx={{
                borderWidth: '1px',
                borderColor: '#000',
                height: '95%',
            }}
            />
            <Stack
            sx={{
                width: '30%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            onClick={() => !loading && setShowPopOver(!showPopOver)}
            >
            <AiFillCaretDown />
            </Stack>



            {/* Pop Over */}

            <Slide
            direction="up"
            mountOnEnter 
            unmountOnExit
            in={showPopOver}
            >
            <Stack
            sx={{
                width: '150px',
                height: '90px',
                boxShadow: '0 0 10px rgb(0,0,0,.125)',
                borderRadius: '8px',
                bgcolor: 'background',
                transition: '.1s linear',
                pointerEvents: showPopOver ? 'auto' : 'none',
                overflow: 'hidden',
                position: 'absolute',
                top: '-100px'
            }}
            >
            {
                popOverOptions.map( ({title, Icon, action}, k) => (
                    <IconButton
                    key={k}
                    sx={{
                        gap: '10px',
                        height: '50%',
                        width: '100%',
                        p: '10px',
                        borderRadius: '2px',
                        '&:hover':{
                            bgcolor: 'primary.main',
                            color: 'neutral.50'
                        },
                        '&:hover p':{
                            color: 'neutral.50'
                        },
                        '&:hover svg':{
                            fill: '#fff'
                        }
                    }}
                    onClick={action}
                    >
                    <Icon size={25} color={primary.main} />
                    <Typography sx={{color: 'neutral.900', whiteSpace: 'nowrap'}}>
                        {title}
                    </Typography>
                    </IconButton>
                ) )
            }
            </Stack>
            </Slide>
        </Stack>
    )
}


export default SendButton