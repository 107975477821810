import toluade from '../../Img/Ellipse 14.png'
import mannie from '../../Img/Ellipse 15.png'
import ayooluwa from '../../Img/Ellipse 16.png'
import thatboykp from '../../Img/Ellipse 17.png'



export const creators = [
    {
        name: 'Toluade Photography',
        jobTitle: 'Digital Marketer/ Photographer',
        imageSrc: toluade
    },
    {
        name: 'Mannie',
        jobTitle: 'Web Designer/Web Developer',
        imageSrc: mannie
    },
    {
        name: 'Sheda House Humans',
        jobTitle: 'Creatives',
        imageSrc: ayooluwa
    },
    {
        name: 'Thatboi_KP',
        jobTitle: 'Product Designer/Videographer',
        imageSrc: thatboykp
    },
]