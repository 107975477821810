


const FlutterwaveIcon = () => (
    <img 
    alt="flutterwave-icon"
    src="/assets/images/flutterwave.png"
    width={324/14}
    height={266/14}
    style={{
        objectFit: 'contain'
    }}
    />
)

export default FlutterwaveIcon