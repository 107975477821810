import { Stack } from "@mui/material"
import { useState } from "react"
import { useLayoutContext } from "../../../../contexts/layoutContext"
import { useBreakpoints } from "../../../../theme/breakpoints"
import BackButton from "../../../BackButton"
import Layout from "../../../Layouts/DashboardLayout"
import EmailDetails from "./EmailDetails"
import EmailsPageContext from "../../../../contexts/emailsPageContext"



const ViewEmailDetails = () => {
    const {miniSideBar} = useLayoutContext()
    const {mdOnly, sm} = useBreakpoints()
    const [reloadEmailDetails, setReloadEmailDetails] = useState(false)

    return (
        <EmailsPageContext.Provider
        value={{
            viewEmailDetails: true,
            reloadEmailDetails,
            setReloadEmailDetails
        }}
        >
        <Stack
        sx={{
            p: (!miniSideBar && mdOnly) ? '50px 20px' : sm ? '15px' : '50px 50px',
            gap: '30px'
        }}
        >
        <BackButton />
        
        <EmailDetails />
        </Stack>
        </EmailsPageContext.Provider>
    )
}



const ViewEmailDetailsPage = () => {

    return (
        <Layout>
            <ViewEmailDetails />
        </Layout>
    )
}


export default ViewEmailDetailsPage