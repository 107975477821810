import { Collapse, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { neutral, primary } from "../../../theme/palette"
import { SubTab } from "./UserTabPopOverOption"


const MediaMenuOption = ({title, route, action, id, Icon, subTabItems}) => {
    const pathname = useLocation().pathname

    const [isOptionHovered, setIsOptionHovered] = useState(false)
    const currentPage = (route, id) => pathname===route || pathname.includes(id)

    const [expandSubTabs, setExpandSubTabs] = useState(false)

    const MainMenu = () => (
        <Link
        to={route}
        >
        <Stack
        direction='row'
        onClick={() => (action && action()) || setExpandSubTabs(!expandSubTabs)}
        sx={{
            alignItems: 'center',
            gap: '10px',
            cursor: 'pointer',
            ...(isOptionHovered || currentPage(route, id)) && {bgcolor: 'neutral.50', borderRadius: '5px', p: '5px 2px'},
        }}
        onMouseEnter={() => setIsOptionHovered(true)}
        onMouseLeave={() => setIsOptionHovered(false)}
        >
            <Icon size={18} color={!(isOptionHovered || currentPage(route, id)) ? neutral[50] : primary.main} />
            <Typography
            sx={{
                fontWeight: 500,
                fontSize: '.8rem',
                color: (isOptionHovered || currentPage(route, id)) ? 'primary.main' : 'neutral.50'
            }}
            >
                {title}
            </Typography>
        </Stack>
        </Link>
    )

    return (
        <>
        {
        !subTabItems ? 
        <MainMenu /> :

        <Stack sx={{gap: '5px'}}>
            <MainMenu />
            <Collapse in={expandSubTabs}>
            <Stack
            sx={{
                pl: '18px'
            }}
            >
            {
                subTabItems.map( (items) => (
                    <SubTab
                    key={items?.title}
                    {...items}
                    />
                ) )
            }
            </Stack>
            </Collapse>
        </Stack>
        }
        </>
    )
}

export default MediaMenuOption