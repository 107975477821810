import { Autocomplete, CircularProgress, IconButton, InputAdornment, MenuItem, Stack, Tab, Tabs, TextField, Typography } from "@mui/material"

import TextEditor from "../../TextEditor"
import { useBreakpoints } from "../../../theme/breakpoints"
import SendButton from "./SendButton"
import { useEffect, useState } from "react"
import { fetchData } from "../../../utils/functions/fetchData"
import { toast } from "react-hot-toast"
import { useComposePageContext } from "../../../contexts/composePageContext"
import { FaInfoCircle } from "react-icons/fa"
import {primary,} from "../../../theme/palette"
import { fetchFromLocalStorage, updateLocalStorage } from "../../../utils/functions/updateStorage"
import InfoView from "../InfoView"
import EmailEditor from "../../EmailEditor"
import { MdAttachEmail, MdOutlineMarkEmailUnread } from "react-icons/md"
import { BiReset } from "react-icons/bi"
import { useLayoutContext } from "../../../contexts/layoutContext"
import { useLoadingContext } from "../../../contexts/loadingContext"
import TinyMCEEditor from "../../TinyMCE"
import client from "../../../utils/axios"


const textFieldStyle = {
    '.MuiOutlinedInput-notchedOutline': {
        border: 'unset',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'unset',
    },
    '& input': {
        bgcolor: 'white',
        color: '#000',
        p: '5px 15px'
    },
    '& input::placeholder': {
        fontSize: '1rem',
        color: 'black',
    },
    '& .MuiButtonBase-root.MuiChip-root': {
        bgcolor: 'primary.main',
        color: 'neutral.50'
    },
    '& .MuiChip-root .MuiSvgIcon-root': {
        color: 'neutral.50'
    },
    '& .MuiChip-root .MuiSvgIcon-root:hover': {
        color: 'neutral.50'
    },
    '& .MuiFormLabel-root': {
        // color: '#000'
    }
}


const ComposeCard = () => {
    const {sm, md} = useBreakpoints()
    const [lists, setLists] = useState([])
    const [listsLoading, setListsLoading] = useState(true)
    const [customEmails, setCustomEmails] = useState([])
    const [customEmailsLoading, setCustomEmailsLoading] = useState(true)
    const {
        userSub,
        userSubLoading
    } = useLayoutContext()
    const {emailData, setEmailData, resetEmailData, editorType, setEditorType} = useComposePageContext()

    const composeInfoSeen = fetchFromLocalStorage('userSettings')?.composeInfoSeen
    const [openInfo, setOpenInfo] = useState(false)
    const {setMiniSidebar} = useLayoutContext()
    const {globalLoading} = useLoadingContext()


    useEffect( () => {
        const loadLists = async () => {
            setListsLoading(true)
            try{
                const {data} = await client.post('/getUserLists')
                const fetchedList = data
                .filter( ({totalContact}) => totalContact)
                .map( ({token, name, totalContact}) => ({
                    id: token,
                    listName: `${name} (${totalContact})`,
                }) )

                setLists(fetchedList)
            }
            catch(err){
                console.error(err.message)
                toast.error(err.message)
            }
            finally{
                setListsLoading(false)
            }
        }
        loadLists()
    }, [] )

    useEffect( () => {
        const fetchCustomEmails = async () => {
            try{
                setCustomEmailsLoading(true)
                const {data} = await client.post('/getCustomMails', {}, {noError: true, version: 3})
                
                setCustomEmails(data.map( (customEmail) => ({
                    value: customEmail.custom_mail_id,
                    label: customEmail.email,
                }) ))
            }
            catch(err){
                console.log(err.message)
            }
            finally{
                setCustomEmailsLoading(false)
            }
        }
        fetchCustomEmails()
    }, [] )


    useEffect( () => {
        setTimeout( () => setOpenInfo(!composeInfoSeen), 2000 )
    }, [] )
    useEffect( () => {
        updateLocalStorage('userSettings', {
            composeInfoSeen: true
        })
    }, [] )

    // Store Email Data in Local Storage onChange
    useEffect( () => {
        updateLocalStorage('userSettings', {emailData})
    }, [emailData] )

    useEffect( () => {
        updateLocalStorage('userSettings', {editorType})
        if(editorType===1){
            setMiniSidebar(true)
        }
    }, [editorType] )


    const INFO = () => (
        <Typography sx={{fontWeight: 500}}>
                
        <strong><i>{`Need to personalize the subject or email body?`}</i></strong><br/><br />

        {`Use {{firstname}}, {{lastname}} and {{fullname}} respectively.`}<br/>

        eg.<br/>
        <strong>Subject:</strong><br/>
        {`"Hello {{firstname}}, Exclusive Offer Just for You!"`}<br/>

        <strong>Email Body:</strong><br/>
        {`"Dear {{fullname}},`}<br/>
        {`We hope this message finds you well....`}
        </Typography>
    )


    // useEffect( () => {
    //     setEmailData(initialEmailData)
    // }, [editorType] )



    return (
        <>
        <Stack
        sx={{
            boxShadow: '0 0 20px rgb(0,0,0,.125)',
            borderTopLeftRadius: '25px',
            borderTopRightRadius: '25px',
            overflow: 'hidden'
        }}
        >
            <Stack
            direction='row'
            sx={{
                p: '30px 20px',
                bgcolor: '#f7f8ff',
                gap: '10px',
                alignItems: 'center',
                ...md && {justifyContent: 'space-between'}
            }}
            >
                <Typography variant="h5" sx={{fontWeight: 500}}>
                    Compose
                </Typography>

                <InfoView
                tooltip
                tooltipInfo={<INFO />}
                placement="top-start"
                >
                <IconButton
                sx={{width: 'fit-content', color: primary.main}}
                onClick={() => setOpenInfo(true)}
                >
                <FaInfoCircle size={sm ? 18 : 22} />
                </IconButton>
                </InfoView>
            </Stack>
            <Stack>

            <Autocomplete
            multiple
            limitTags={2}
            id="multiple-limit-tags"
            options={lists}
            getOptionLabel={(option) => option?.listName}
            defaultValue={lists.filter(item => emailData?.data?.includes(item.id))}
            value={lists.filter(item => emailData?.data?.includes(item.id))}

            renderInput={(params) => (
                <TextField 
                {...params} 
                label="Choose Lists"
                placeholder="Lists" 
                disabled={listsLoading}
                {
                ...listsLoading && {
                    InputProps: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <CircularProgress size={30}/>
                            </InputAdornment>
                        ),
                    }
                }
                }
                />
            )}
            onChange={(_, value) => setEmailData((prevValue) => ({...prevValue, data: value.map( ({id}) =>  id )}))}
            sx={textFieldStyle}
            />

            <TextField 
            placeholder="Subject"
            sx={textFieldStyle}
            value={emailData?.subject || ''}
            onChange={(e) => setEmailData((prevValue) => ({...prevValue, subject: e.target.value}))}
            />

            <Tabs value={editorType} onChange={(e, newVal) => setEditorType(newVal)}>
                <Tab icon={<MdOutlineMarkEmailUnread />} label="MINIMAL EDITOR" />
                <Tab icon={<MdOutlineMarkEmailUnread />} label="MINIMAL EDITOR 2" />
                <Tab icon={<MdAttachEmail />} label="FULL EMAIL EDITOR" />
            </Tabs>

            {
            editorType===0 &&
            <TextEditor />
            }

            {
            editorType===1 && !globalLoading &&
            <TinyMCEEditor />
            }

            {
            editorType===2 && !globalLoading &&
            <EmailEditor />
            }


            <Stack
            sx={{
                p: '20px 10px',
                gap: '30px'
            }}
            >
            <TextField 
            label="Sender Email"
            helperText={!userSubLoading && !(userSub?.isPremiumUser && Number(userSub?.packageid)>1) ? 'Upgrade your plan send mails with custom sender emails' : ''}
            value={emailData?.custom_mail_id || ''}
            onChange={(e) => setEmailData((prevValue) => ({...prevValue, custom_mail_id: e.target.value}))}
            select
            disabled={customEmailsLoading}
            {
            ...customEmailsLoading && {
                InputProps: {
                    startAdornment: (
                        <InputAdornment position="start">
                            <CircularProgress size={30} />
                        </InputAdornment>
                    ),
                }
            }
            }
            >
            {
            [{label: 'Default', value: 'default'}, ...customEmails].map((option, k) => (
                <MenuItem
                key={k}
                value={option.value}
                >
                    {option.label}
                </MenuItem>
                ))
            }
            </TextField>
            
            <Stack
            direction='row'
            sx={{
                gap: '50px'
            }}
            >

                <SendButton />

                <IconButton
                sx={{
                    borderRadius: '5px',
                    color: 'red'
                }}
                onClick={() => {
                    resetEmailData()
                    toast('Mail Reset!');
                }}
                >
                <BiReset />
                <Typography component='span'>Reset</Typography>
                </IconButton>
            </Stack>
            </Stack>
            </Stack>
        </Stack>

        <InfoView
        dialog
        open={openInfo}
        setOpen={setOpenInfo}
        >
        <INFO />
        </InfoView>
        </>
    )
}

export default ComposeCard

