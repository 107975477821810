import { Divider, Stack, Typography } from "@mui/material"
import { footerContents,} from "./footer-data"
import Grid from "../CustomGrid"
import { useBreakpoints } from "../../theme/breakpoints"

import MiniFooter from "./MiniFooter"
import { Link } from "react-router-dom"




const Footer = () => {    
    const {sm, md} = useBreakpoints()

    return (
        <footer>
        <Stack
        sx={{
            p: !sm ? '2rem 4rem' : '1rem',
            bgcolor: 'white',
            gap: '30px'
        }}
        >
            <Grid 
            columns={md ? 2 : 4}
            sx={{justifyContent: 'space-between'}}
            stackGrids={"row"}
            gridWrapSx={{gap: '50px'}}
            >
                {
                    footerContents.map( ({heading, content}, k) => (
                        <Stack key={k}>
                            <Stack sx={{gap: '29px', width: 'max-content'}}>
                               <Typography variant="h5" color='primary'>
                                {heading}
                               </Typography> 
                                <Stack sx={{gap: '23px'}}>
                                    {
                                        content.map( ({title, route}, k) => (
                                            <Link 
                                            to={route} 
                                            key={k}
                                            {...route.includes('http') && {target: '_blank'}}
                                            >
                                                <Typography color='primary' 
                                                sx={{
                                                    fontWeight: '600',
                                                    '&:hover': {
                                                        textDecoration: 'underline'
                                                    }
                                                }}
                                                >
                                                    {title}
                                                </Typography>
                                            </Link>
                                        ) )
                                    }
                                </Stack>
                            </Stack>
                        </Stack>
                    ) )
                }
            </Grid>
            <Divider sx={{borderColor: 'neutral.400'}} />
            <MiniFooter />
        </Stack>
        </footer>
    )
}


export default Footer