import { Box, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { MdEdit, MdVerified } from "react-icons/md"
import { primary } from "../../../theme/palette"
import { useBreakpoints } from "../../../theme/breakpoints"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import CustomButton from "../../Button"
import { toast } from "react-hot-toast"
import { fetchData, isBVN } from "../../../utils/functions/fetchData"
import client from "../../../utils/axios"



const Security = () => {
    const initialPassState = { oldPass: '', newPass: ''}
    const [pass, setPass] = useState(initialPassState)
    const [bvn, setBvn] = useState('')

    const [changePassLoading, setChangePassLoading] = useState(false)
    const [updateBNVLoading, setUpdateBVNLoading] = useState(false)


    const handleChangePass = async (e) => {
        e && e.preventDefault()
        setChangePassLoading(true)
        try{
            if(!pass.oldPass || !pass.newPass){
                throw new Error('Enter passwords')
            }
            const {data, message} = await client.post('/changePassword', {
                opword: pass.oldPass,
                npword: pass.newPass
            })
            
            setPass(initialPassState)
            return toast.success(message)
        }
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setChangePassLoading(false)
        }
    }

    const handleUpdateBVN = async (e) => {
        e && e.preventDefault()
        setUpdateBVNLoading(true)
        try{
            if(!bvn){
                throw new Error('Enter  BVN')
            }
            const {data, message} = await client.post('/updateBVN', {bvn})

            setBvn('')
            return toast.success(message)
        }
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setUpdateBVNLoading(false)
        }
    }

    return (
        <Stack
        sx={{
            gap: '30px'
        }}
        >
        <form
        noValidate
        onSubmit={handleChangePass}
        >
        <Tab
        title='Update Password'
        >
        <UserInput
        title='Old Password'
        password
        val={pass.oldPass}
        setVal={(val) => setPass( (prevVal) => ({...prevVal, oldPass: val}) )}
        />
        <UserInput
        title='New Password'
        password
        val={pass.newPass}
        setVal={(val) => setPass( (prevVal) => ({...prevVal, newPass: val}) )}
        />

        <CustomButton 
        title='Change Password'
        loading={changePassLoading}
        onClick={handleChangePass}
        type='submit'
        />
        </Tab>
        </form>

        <form>
        <Tab
        title='Update your Bank Verification Number (BVN)'
        >
        <UserInput
        title='Enter your BVN'
        type={'number'}
        val={bvn}
        setVal={setBvn}
        disabled={isBVN()}
        verified={isBVN()}
        />

        <CustomButton
        title='Update BVN'
        loading={updateBNVLoading}
        onClick={handleUpdateBVN}
        type='submit'
        disabled={isBVN()}
        />
        </Tab>
        </form>
        </Stack>
    )
}

export default Security



const Tab = ({title, children}) => {
    const {sm} = useBreakpoints()
    const [edit, setEdit] = useState(false)

    return (
        <Stack
        sx={{
            gap: '20px'
        }}
        >
        <Stack
        direction='row'
        sx={{
            justifyContent: 'space-between'
        }}
        >
            <Typography 
            variant="h6" 
            sx={{
                ...sm && {fontSize: '1rem'}
            }}
            >
            {title}
            </Typography>
            <MdEdit 
            size={sm ? 25 : 30}
            color={primary.main}
            style={{cursor: 'pointer'}}
            onClick={() => setEdit(!edit)}
            />
        </Stack>


        {edit && children}
        </Stack>
    )
}



const textFieldStyle = {
    minWidth: '50%',
    '& .MuiInputBase-root': {
        borderRadius: '8px'
    },
    '& .Mui-focused': {
        // border: 'unset',
    },
    '& input': {
        bgcolor: 'white',
        color: '#000',
        p: '5px 15px',
        minHeight: '30px'
    },
    '& input:hover': {
        borderColor: 'primary.main'
    },
}


const UserInput = ({title, value, password, type, val, setVal, verified=false, ...props}) => {
    const {xs} = useBreakpoints()
    const [showPassword, setShowPassword] = useState(false)
    

    return (
        <Stack 
        direction={ xs ? "column" : "row"}
        sx={{justifyContent: 'space-between', gap: '10px'}}
        >
        <Typography>{title}</Typography>
        <TextField
        sx={textFieldStyle}
        value={val}
        onChange={(e) => {
            if(type==='number' && e.target.value !== '' && !((/^[0-9]+$/).test(e.target.value))){
                return
            }
            return setVal(e.target.value)
        }}
        type={ password ? (showPassword ? 'text' : 'password') : 'text' }

        InputProps={{
            endAdornment: (
                <>
                {
                    password &&
                    <InputAdornment position="end">
                        <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => setShowPassword(!showPassword)}
                        >
                            {
                                showPassword ?
                                <FaEyeSlash size={15} /> :
                                <FaEye size={15} />
                            }
                        </IconButton>
                    </InputAdornment>
                }
                </>
            ),
            ...verified && {
                startAdornment: (
                <Stack 
                direction='row' 
                sx={{alignItems: 'center', gap: '5px'}}>
                <Typography>
                    VERIFIED
                </Typography>
                <MdVerified 
                color="green"
                size={20}
                />
                </Stack>
            )
            }
        }}


        {...props}
        />
        </Stack>
    )
}