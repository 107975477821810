import { useEffect } from "react"
import { Navigate } from "react-router-dom"
import client from "../../../utils/axios"



const SignOut = () => {

    const handleSignOut = async () => {
        try{
            localStorage.removeItem("userData")
        
            await client.post('/logoutUser', {}, {isReniAuth: true})
        }
        catch(err){
            console.log(err.message)
        }
    }
    useEffect( () => {
        handleSignOut()
    }, [])

    return <Navigate to='/login' />
}

export default SignOut

