import { Stack, Typography } from "@mui/material"
import {CgMenu} from 'react-icons/cg'
import { neutral, primary } from "../../../theme/palette"
import { nav } from "./sidebar-data"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useBreakpoints } from "../../../theme/breakpoints"
import Logo from "../../Logo"



const SideBar = ({width, miniSideBar, setMiniSidebar}) => {
    const {md} = useBreakpoints()

    const navigate = useNavigate()
    const pathname = useLocation().pathname

    const currentPage = (route, id) => pathname===route || pathname.includes(id)

    return (
        <Stack
        sx={{
            width: width,
            minHeight: '100%',
            overflowY: 'scroll',
            position: 'fixed',
            bgcolor: 'primary.main',
            gap: '50px',
            p: !miniSideBar ? '85px 0 0 30px' : '85px 0 0 0',
            ...miniSideBar && {alignItems: 'center'},
            transition: 'all .2s',
            zIndex: 999,
            ...(md && !miniSideBar) && {boxShadow: '0 0 7px rgb(0,0,0,.125)'}
        }}
        >
        <Stack
        direction={!miniSideBar ? 'row' : 'column'}
        sx={{
            gap: '20px',
            alignItems: 'center'
        }}
        >
            <Stack
            sx={{
                cursor: 'pointer',
            }}
            onClick={() => setMiniSidebar(!miniSideBar)}
            >
            <CgMenu size={35} color={neutral[50]} />
            </Stack>
            <Logo />
        </Stack>
        <Stack
        sx={{
            gap: '30px',
            alignItems: miniSideBar ? 'center' : 'unset'
        }}
        >
            {
                nav.map( ({title, route, id, Icon}, k) => (
                    <Stack
                    key={k}
                    onClick={() => navigate(route)}
                    sx={{cursor: 'pointer'}}
                    >
                    <Stack
                    direction='row'
                    sx={{
                        gap: '20px',
                        ...currentPage(route, id) && {
                            borderRadius: !miniSideBar ? '7px' : '12px',
                            bgcolor: 'neutral.50',
                            ...!miniSideBar && { width: '80%'},
                            p: !miniSideBar ? '10px 0 10px 10px' : '10px',
                        }
                    }}
                    >
                    <Icon
                    size={25}
                    color={currentPage(route, id) ? primary.main : neutral[50]}
                    />
                    {
                        !miniSideBar &&
                        <Typography
                        variant="h5"
                        sx={{
                            color: currentPage(route, id) ? 'primary.main' : 'neutral.50'
                        }}
                        >
                            {title}
                        </Typography>
                    }
                    </Stack>
                    </Stack>
                ) )
            }
        </Stack>
        </Stack>
    )
}

export default SideBar