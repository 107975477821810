import { createContext } from 'react';
import { useContext } from 'react';

const RegisterPageContext = createContext();


export const useRegisterPageContext = () => {

    return useContext(RegisterPageContext)
}

export default RegisterPageContext