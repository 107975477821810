import { toast } from "react-hot-toast"



const copyToClipboard = async (text, successMessage) => {
    try{
        const copyEl = document.createElement("textarea")
        document.body.appendChild(copyEl)
        copyEl.value = text
        copyEl.select()
        document.execCommand("copy")
        await navigator.clipboard.writeText(copyEl.value)
        copyEl.remove()
        toast.success(successMessage || 'Text Copied to Clipboard')
    }   
    catch(err){
        toast.error('Error copying to clipboard, try later')
    }
}

export default copyToClipboard