import { Divider, Stack, Typography } from "@mui/material"
import Layout from "../../../Layouts/DashboardLayout"
import { useLayoutContext } from "../../../../contexts/layoutContext"
import { useBreakpoints } from "../../../../theme/breakpoints"
import BackButton from "../../../BackButton"
import StatisticsCard from "./StatisticsCard"
import Chart from "./Chart"
import Grid from "../../../CustomGrid"
import { EmailsPage } from ".."
import { useState } from "react"
import { fetchData } from "../../../../utils/functions/fetchData"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import scrollToTop from "../../../../utils/functions/scrollToTop"
import { toast } from "react-hot-toast"
import ClicksTable from "./ClicksTable"
import client from "../../../../utils/axios"


const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    })
}


const ManageEmail = () => {
    const {miniSideBar} = useLayoutContext()
    const {xs, sm, md, mdOnly} = useBreakpoints()
    const [loading, setLoading] = useState(false)
    const [emailData, setEmailData] = useState({
        totalEmailsSent: '',
        clickRate: '',
        openRate: '',
        graph: {},
        graphFilter: ''
    })

    const {id} = useParams()
    const [currentMonth, setCurrentMonth] = useState('')
    const currentMonthData = emailData.graph[currentMonth] || []




    const fetchEmailData = async () => {
        setLoading(true)
        try{
            const {data: emailDataRes} = await client.post('/getClickData', {mailId: id}, {noError: true})

            const graphData = emailDataRes?.graph?.reduce((acc, {date, daily}) => {
                const monthKey = date
                const dailyData = daily.sort((a, b) => new Date(a.day) - new Date(b.day))
                
                acc[monthKey] = dailyData
                return acc
              }, {})

            const graphFilter = emailDataRes?.graph?.map( ({date, daily}) => ({
                value: date,
                startDate: formatDate(daily[0]?.day),
                endDate: formatDate(daily[daily.length-1]?.day)
            }) )

            setEmailData({
                totalEmailsSent: emailDataRes?.details?.contacts,
                clickRate: emailDataRes?.details?.clickRate,
                openRate: emailDataRes?.details?.openRate,
                graph: graphData,
                graphFilter: graphFilter
            })
            setCurrentMonth(Object.keys(graphData)[0])
        } 
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setLoading(false)
        }
    }


    useEffect( () => {
        fetchEmailData()
        scrollToTop()
    }, [id] )




    const statCardData = [
        {
            title: 'Emails sent',
            figure: emailData?.totalEmailsSent,
            // rate: 0.5
        },
        {
            title: 'Avg. open rate',
            figure: emailData?.openRate + '%',
            // rate: -1.7
        },
        {
            title: 'Avg. click rate',
            figure: emailData?.clickRate + '%',
            // rate: -2.3
        }
    ]

    return (
        <Stack
        sx={{
            p: sm ? '10px' : md ? '20px' : '50px',
            gap: '30px'
        }}
        >
        <Stack
        direction='row'
        sx={{justifyContent: 'space-between'}}
        >
            <Typography variant="h4">
                Manage
            </Typography>
            <Stack>
            <BackButton />
            </Stack>
        </Stack>

        <Stack
        sx={{
            width: '100%',
            bgcolor: 'rgba(208, 223, 242, 0.20)',
            p: sm ? '10px' : '20px',
            borderRadius: '15px',
            gap: '50px'
        }}
        >
            <Stack
            sx={{
                width: '100%',
                bgcolor: 'white',
                p: '20px 10px 10px 10px',
                boxShadow: '0 0 10px rgb(0,0,0,.125)',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                gap: '30px'
            }}
            >
                <Grid
                columns={md ? 2 : 4}
                sx={{
                    justifyContent: 'space-between',
                }}
                stackGrids="row"
                gridWrapSx={{
                    ...md && {gap: '20px'}
                }}
                >
                {
                    statCardData.map( ({title, figure, rate}, k) => (
                        <>
                        <StatisticsCard 
                        key={k}
                        title={title}
                        figure={figure}
                        // rate={rate}
                        loading={loading}
                        noDivider={k+1 === statCardData.length}
                        />
                        {
                            k+1 !== statCardData.length && !md &&
                            <Divider
                            sx={{
                                borderWidth: '2px',
                                borderColor: '#E4E7EC',
                                height: '100%',
                            }}
                            />
                        }
                        </>
                    ) )
                }
                </Grid>

                <Chart
                chartData={{
                x: currentMonthData.map(({ opens }) => opens),
                y: currentMonthData.map(({ day }) => (new Date(day)).getDate())
                }}
                startDate={currentMonthData.length && formatDate(currentMonthData[0]?.day)}
                endDate={currentMonthData.length && formatDate(currentMonthData[currentMonthData.length-1]?.day)}
                loading={loading}
                filterData={emailData?.graphFilter}
                currentMonth={currentMonth}
                setMonth={setCurrentMonth}
            />
            </Stack>


            {/* <EmailsPage 
            embed
            /> */}
            <ClicksTable 
            mailId={id}
            />
        </Stack>
        </Stack>
    )
}


const ManageEmailPage = () => {

    return (
        <Layout>
        <ManageEmail />
        </Layout>
    )
}

export default ManageEmailPage