import { Stack, Typography } from "@mui/material"
import Layout from "../Layout"
import { useState } from "react"
import EmailVerify from "./EmailVerify"
import { useEffect } from "react"
import RegisterPageContext from "../../../contexts/registerPageContext"
import { neutral, primary } from "../../../theme/palette"
import CustomButton from "../../Button"
import { useBreakpoints } from "../../../theme/breakpoints"
import RegisterForm from "./RegisterForm"
import { useNavigate } from "react-router-dom"
import Logo from "../../Logo"



const RegisterPage = () => {
    const {xs, sm, md, lg, xl} = useBreakpoints()
    const navigate = useNavigate()

    let regLocalData = localStorage.getItem('register')
    let screen
    if(regLocalData){
        regLocalData = JSON.parse(regLocalData)
        screen = regLocalData?.currentScreen
    }
    const [currentScreen, setCurrentScreen] = useState(screen || 'default')

    const initialRegisterDetails = {
        email: regLocalData ? regLocalData?.email : '',
        vCode: '',
        fName: regLocalData ? regLocalData?.fName : '',
        lName: regLocalData ? regLocalData?.lName : '',
        tel: regLocalData ? regLocalData?.tel : '',
        gender: regLocalData ? regLocalData?.gender : '',
        userName: regLocalData ? regLocalData?.userName : '',
        pass: regLocalData ? regLocalData?.pass : '',
        confirmPass: regLocalData ? regLocalData?.confirmPass : '',
        agree: regLocalData ? regLocalData?.agree : false
    }
    const nullRegisterDetails = {
        email: '',
        vCode: '',
        fName: '',
        lName: '',
        tel: '',
        gender: '',
        userName: '',
        pass: '',
        confirmPass: '',
        agree: false,
    }
    const [registerDetails, setRegisterDetails] = useState(initialRegisterDetails)


    useEffect( () => {
        const dataToStore = JSON.stringify({currentScreen, ...registerDetails})
        localStorage.setItem('register', dataToStore)
    }, [registerDetails, currentScreen] )

    return (
        <Layout>
        <RegisterPageContext.Provider
        value={{
            currentScreen,
            setCurrentScreen,
            registerDetails,
            setRegisterDetails,
            nullRegisterDetails
        }}
        >
        <Stack
        sx={{
            p: sm ? '50px 20px' : '50px',
            alignItems: 'center',
            bgcolor: 'neutral.50',
            borderRadius: '8px',
            boxShadow: '0 0 10px rgba(0,0,0,.125)',
            minWidth: xs ? '97%' : sm ? '90%' : md ? '75%' : '50%',
            gap: '30px',
            height: 'fit-content'
        }}
        >
        <Stack
        sx={{
            textAlign: 'center',
            alignItems: 'center'
        }}
        >
        <Logo />
        <Stack>
        <Typography variant="h5" sx={{color: primary.main}}>
        {currentScreen!==`default` ? `Let's Get To Know You` : `Create Account`}
        </Typography>
        {currentScreen!==`default` && 
        <Typography sx={{color: 'neutral.600'}}>
        Your Information Are All Safe And Secure
        </Typography>
        }
        </Stack>
        </Stack>


        {
        currentScreen==='complete-register' &&
        <CustomButton
        title='Previous Page'
        variant="contained"
        squareCorners
        sx={{alignSelf: 'center', textTransform: 'unset'}}
        onClick={() =>  setCurrentScreen('register')}
        />
        }
        

        {
        currentScreen === 'default' &&
        <EmailVerify />
        }
        {
        (currentScreen === 'register' || currentScreen === 'complete-register') &&
        <RegisterForm />
        }

        
        {
        currentScreen === 'default' &&
        <Typography>
        <span>Already  a member?</span>
        <CustomButton
        title='Sign In'
        variant="outlined"
        component='span'
        noBorder
        sx={{
            display: 'inline-flex'
        }}
        onClick={ () => navigate('/login') }
        />
        </Typography>
        }
        </Stack>
        </RegisterPageContext.Provider>
        </Layout>
    )
}

export default RegisterPage