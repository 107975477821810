import CustomButton from "../../Button";
import { IoClose } from "react-icons/io5";
import { BiCopy } from "react-icons/bi";
import copyToClipboard from "../../../utils/functions/copyToClipboard";

const { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, TextField } = require("@mui/material");

const textFieldStyle = {
    minWidth: '100%',
    '& .MuiInputBase-root': {
        borderRadius: '8px'
    },
    '& input': {
        bgcolor: 'white',
        color: '#000',
        p: '5px 15px',
    },
    '& input:hover': {
        borderColor: 'primary.main'
    },
}



const EmbedList = ({ open,setOpen, listData}) => {


    const onClose = () => setOpen(false)


    return (
      <Dialog 
      open={open} 
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
            bgcolor: 'neutral.50'
        }
      }}
      >
        <DialogTitle 
        sx={{
            display: 'flex',
            justifyContent: 'space-between'
        }}
        >
        <Typography variant="h4">
        Embed List
        </Typography>
        <IoClose size={30} onClick={onClose} style={{cursor: 'pointer'}} />
        </DialogTitle>
        <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <DialogContentText>
            <Typography>
            Get your audience subscribed to your list by embedding the code below on your website.
            </Typography>
            <Typography>
            All you need do is copy the html / javascript code below and paste anywhere on your website.
            </Typography>
          </DialogContentText>

          <TextField 
            multiline
            sx={textFieldStyle}
            value={listData?.embed}
          />
        </DialogContent>
        <DialogActions>
          <CustomButton
          title='Copy Code'
          Icon={BiCopy}
          IconPlacement="left"
          sx={{gap: '5px'}}
          onClick={ () => copyToClipboard(listData?.embed, 'Code Copied to Clipboard') }
          />
        </DialogActions>
      </Dialog>
    );
};
  

export default EmbedList