import { Divider, TableCell, TableRow } from "@mui/material"



const TableDivider = ({colSpan}) => {
    return (
        <TableRow>
        <TableCell colSpan={colSpan} sx={{p: 0}}>
        <Divider sx={{borderColor: 'neutral.700', borderWidth: '1px', width: '100%'}} />
        </TableCell>
        </TableRow>
    )
}

export default TableDivider