import { Dialog, Grow, IconButton, Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import { neutral, secondary } from "../../theme/palette";
import {GrClose} from 'react-icons/gr'
import { Dispatch, ReactElement, SetStateAction } from "react";




interface IInfoView {
  children?: ReactElement;
  dialog?: boolean;
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  tooltip?: boolean;
  placement?: TooltipProps['placement'];
  tooltipInfo?: string | JSX.Element;
  disabled?: boolean;
}

const InfoView = ({
  children,
  dialog=false, 
  open=false, 
  setOpen, 
  tooltip=false, 
  placement='bottom-end',
  tooltipInfo,
  disabled=false
}: IInfoView) => {

  const NoMaxWidthTooltip = styled((props: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: props.className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
      backgroundColor: secondary.main,
      color: neutral[900],
      ...typeof tooltipInfo !== 'string' && {padding: '20px 15px'}
    },
  })



  if(disabled){
    return (
      <>{children}</>
    )
  }
  

  if(dialog){
    return (
      <Dialog
        open={open}
        onClose={() => setOpen && setOpen(false)}
        sx={{
            '& .MuiPaper-root': {
                bgcolor: 'secondary.main',
                padding: '20px 15px',
            }
        }}
        >
        <IconButton
        onClick={() => setOpen && setOpen(false)}
        sx={{
          alignSelf: 'flex-end',
          mt: '-10px'
        }}
        >
        <GrClose />
        </IconButton>
        {children}
        </Dialog>
    )
  }

  if(tooltip){
    return (
      <NoMaxWidthTooltip
        TransitionComponent={Grow}
        TransitionProps={{ timeout: 600 }}
        title={tooltipInfo}
        placement={placement}
        >
        {children}
        </NoMaxWidthTooltip>
    )
  }

  return <></>
}


export default InfoView