import { Stack } from "@mui/material"
import StatisticsCard from "./StatisticsCard"
import { FaUser } from "react-icons/fa"
import {TbMailFilled} from 'react-icons/tb'
import { secondary } from "../../../theme/palette"
import unsubscribersIcon from '../../UserPage.js/Wrapper/userimg/Remove.png'
import bouncesIcon from '../../UserPage.js/Wrapper/userimg/Bounce Arrow.png'
import { useLayoutContext } from "../../../contexts/layoutContext"
import { useBreakpoints } from "../../../theme/breakpoints"
import Grid from "../../CustomGrid"
import MobileStatistics from "./MobileStatistics"
import MyPlan from "./MyPlan"
import NewSignUpsChart from "./NewSignUpsChart"
import RecentlySent from "./RecentlySent"
import { useEffect } from "react"
import { useState } from "react"
import { useLoadingContext } from "../../../contexts/loadingContext"
import Layout from "../../Layouts/DashboardLayout"
import client from "../../../utils/axios"



const DashboardMain = () => {
    const {miniSideBar} = useLayoutContext()
    const {xs, sm, mdOnly, md} = useBreakpoints()
    const [loading, setLoading] = useState(true)
    const {setGlobalLoading} = useLoadingContext()
    const [bouncesCount, setBouncesCount] = useState('')
    const [totalContactsCount, setTotalContactsCount] = useState('')
    const [totalActiveSubsCount, setTotalActiveSubsCount] = useState('')
    const [unsubscribersCount, setUnsubscribersCount] = useState('')
    const [newSignUpsCount, setNewSignUpsCount] = useState('')
    const [contactsLeft, setContactsLeft] = useState('')
    const [mailsLeft, setMailsLeft] = useState('')
    const [currentPlan, setCurrentPlan] = useState('')


    useEffect( () => {
        setLoading(true)
        setGlobalLoading(true)
        const handleFetchData = async () => {
            try{
                const datas = await Promise.all([
                    client.post('/getTotalNoOfContacts'),
                    client.post('/getNoActiveContacts'),
                    client.post('/getNoBouncedMail'),
                    client.post('/getNoUnsubscribeContacts'),
                    client.post('/getNoOfContactsLeft'),
                    client.post('/getNoOfMailsLeft'),
                ])
                // console.log(datas)

                const stateUpdateFunctions = [
                    setTotalContactsCount,
                    setTotalActiveSubsCount,
                    setBouncesCount,
                    setUnsubscribersCount,
                    setContactsLeft,
                    setMailsLeft
                ];
                
                datas.forEach(({ data }, k) => {
                    const updateFunction = stateUpdateFunctions[k]
                    if (updateFunction) {
                        updateFunction(data)
                    }
                })
            }
            catch(err){
                console.error('An error occurred')
            }
            finally{
                setLoading(false)
                setGlobalLoading(false)
            }
        }
        handleFetchData()
    }, [])


    useEffect( () => {
        const handleFetchPlan = async () => {
            try{
                const {data} = await client.post('/getUserSubscription')

                setCurrentPlan(data?.packageDetails?.name)
            }
            catch(err){
                console.error('An error occurred')
            }
        }
        handleFetchPlan()
    }, [])



    return (
        <Stack
        sx={{
            p: (!miniSideBar && mdOnly) ? '50px 20px' : sm ? '15px' : '50px 50px',
            gap: '30px'
        }}
        >
            {
                !md ?

            <Stack
            direction='row'
            sx={{
                gap: '30px',
                width: '100%'
            }}
            >
            <Stack sx={{width: '50%', gap: '30px'}}>
                <Stack direction='row' sx={{justifyContent: 'space-between', gap: '30px'}}>
                <StatisticsCard 
                title='Total contacts'
                count={totalContactsCount}
                Icon={FaUser}
                IconColor='#8715BD'
                loading={loading}
                />
                <StatisticsCard 
                title='Total active subscribers'
                count={totalActiveSubsCount}
                Icon={TbMailFilled}
                IconColor='#C30B90'
                loading={loading}
                />
                </Stack>
                <MyPlan
                planText={currentPlan}
                mailsRemaining={mailsLeft}
                contactsRemaining={contactsLeft}
                loading={loading}
                />
            </Stack>
            <Stack sx={{width: '50%', gap: '30px'}}>
                <Stack direction='row' sx={{justifyContent: 'space-between', gap: '30px'}}>
                <StatisticsCard 
                title='Bounces'
                count={bouncesCount}
                Image={bouncesIcon}
                IconColor={secondary.main}
                loading={loading}
                />
                <StatisticsCard 
                title='Unsubscribers'
                count={unsubscribersCount}
                Image={unsubscribersIcon}
                IconColor='#DC3905'
                loading={loading}
                />
                </Stack>
                <NewSignUpsChart />
            </Stack>
            
            </Stack> : !xs ?

            <Stack sx={{gap: '30px'}}>
            <Grid
            columns={md ? 2 : 4}
            sx={{
                ...!md && {justifyContent: 'space-between'},
                ...md && {gap: '30px'}
            }}
            gridWrapSx={{
                justifyContent: 'space-between', gap: '30px'
            }}
            >
            <StatisticsCard 
            title='Total contacts'
            count={totalContactsCount}
            Icon={FaUser}
            IconColor='#8715BD'
            loading={loading}
            />
            <StatisticsCard 
            title='Total active subscribers'
            count={totalActiveSubsCount}
            Icon={TbMailFilled}
            IconColor='#C30B90'
            loading={loading}
            />
            <StatisticsCard 
            title='Bounces'
            count={bouncesCount}
            Image={bouncesIcon}
            IconColor={secondary.main}
            loading={loading}
            />
            <StatisticsCard 
            title='Unsubscribers'
            count={unsubscribersCount}
            Image={unsubscribersIcon}
            IconColor='#DC3905'
            loading={loading}
            />
            </Grid>

            <Stack sx={{gap: '30px'}}>
            <MyPlan
            planText={currentPlan}
            mailsRemaining={mailsLeft}
            contactsRemaining={contactsLeft}
            loading={loading}
            />
            <NewSignUpsChart />
            </Stack>
            </Stack> : xs ?

            <>
            <MobileStatistics 
            totalContacts={totalContactsCount}
            totalActiveSubs={totalActiveSubsCount}
            bounces={bouncesCount}
            unsubscribers={unsubscribersCount}
            newSignUps={newSignUpsCount}
            loading={loading}
            />
            <MyPlan
            planText={currentPlan}
            mailsRemaining={mailsLeft}
            contactsRemaining={contactsLeft}
            loading={loading}
            />
            <NewSignUpsChart />
            </> : <></>
            }

            <RecentlySent />
        </Stack>
    )
}

const Dashboard = () => {
    return (
        <Layout>
            <DashboardMain />
        </Layout>
    )
}

export default Dashboard