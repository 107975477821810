import React from 'react'



import '../AboutPage/ReniCulture.css'
const ReniCulture = () => {
  return (
    <div className='reniculturemain'>
      <div className='container-fluid reniculture d-flex flex-column flex-lg-row'>
        <div className='first-para'>
          <h5>The Reni Culture</h5>
          <p>Here’s a sneek peek into how we work as a team</p>
        </div>
        <div className='second-para'>
          <p>
            The ReniMail Team is a team of young innovative minds with a drive
            to learn ways to make email more productive for creatives.At
            Renimail, we have a fun, friendly and respecful work envir...{' '}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ReniCulture