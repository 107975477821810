import {Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { useEffect } from "react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { FaHistory } from "react-icons/fa"
import { toast } from "react-hot-toast"
import { TbCurrencyNaira } from "react-icons/tb"
import { fetchData } from "../../../../utils/functions/fetchData"
import { useBreakpoints } from "../../../../theme/breakpoints"
import { neutral } from "../../../../theme/palette"
import TableDivider from "../../../TableDivider"
import client from "../../../../utils/axios"


const dateOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
}
  


const SubHistory = () => {
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect( () => {
        setLoading(true)
        const handleLoadData = async () => {
        try{
            const {data: subHistory} = await client.post('/subscriptionHistory', {}, {noError: true})
            

            subHistory &&
            setTableData(
                subHistory.map( ({from_date, expire_date, packageDetails, amount, status}) => ({
                activationDate: from_date,
                expiryDate: expire_date,
                details: packageDetails?.name,
                amount,
                status
                }) )
            )
        }
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setLoading(false)
        }
        }
        
        handleLoadData()
    }, [])

    const {sm} = useBreakpoints()

    return (
        <Stack
        sx={{
            width: '100%',
            bgcolor: 'neutral.50',
            boxShadow: '0 0 10px rgb(0,0,0,.125)',
            border: '1px solid',
            borderColor: 'neutral.600',
            p: '10px',
            gap: '20px'
        }}
        >
        <Stack direction='row' sx={{alignItems: 'center', gap: '10px'}}>
        <Stack
        sx={{
            width: '40px', height: '40px',
            borderRadius: '50%',
            alignItems: 'center', justifyContent: 'center',
            bgcolor: 'secondary.main',
        }}
        >
            <FaHistory size={25}  color={neutral[50]}/>
        </Stack>
        <Typography sx={{fontWeight: 500}}>
            Subscription History
        </Typography>
        </Stack>

        <TableContainer
        sx={{
            minHeight: '230px',
        }}
        >

        <Table
        sx={{
            minWidth: 650,
            '& th, td': {
                p: sm ? '1px 10px' : '3px 5px',
                fontWeight: 500,
                ...sm && {fontSize: '.9rem'}
            }
        }}
        >
            <TableHead sx={{bgcolor: 'transparent'}}>
            <TableDivider colSpan={6} />
            <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Details</TableCell>
                <TableCell align="center">Amount</TableCell>
                <TableCell align="center">Status</TableCell>
            </TableRow>
            <TableDivider colSpan={6} />
            </TableHead>
            <TableBody>
            {   !loading && !tableData.length ? 
                <TableRow
                sx={{
                    bgcolor: '#F1F1F1'
                }}
                >
                    <TableCell colSpan={4} rowSpan={2} sx={{textAlign: 'center'}}>
                    No subscriptions found
                    </TableCell>
                </TableRow> :
                
                !loading ? (
                tableData.map(({ activationDate, expiryDate, details, amount, status }, k) => (
                    <TableRow
                        key={k}
                        sx={{
                            bgcolor: k % 2 ? 'neutral.50' : '#F1F1F1'
                        }}
                    >
                        <TableCell>
                            <Typography>
                                From: {activationDate}
                            </Typography>
                            <Typography>
                                To: {expiryDate}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography sx={{fontSize: 'unset', fontWeight: 500}}>
                            {details}
                            </Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Typography component='span' sx={{fontWeight: 600}}>
                                <TbCurrencyNaira size={25} />{amount}
                            </Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Typography sx={{ fontWeight: 500, color: status?.toLowerCase()==='active' ? '#01711A' : '#C00'}}>
                                {status}
                            </Typography>
                        </TableCell>
                    </TableRow>
                ))
            ) : (
                Array.from({length: 2}).map( (_, k) => (
                    <TableRow
                        key={k}
                        sx={{
                            bgcolor: k % 2 ? 'neutral.50' : '#F1F1F1'
                        }}
                    >
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                </TableRow>
                ) )
            )}

            </TableBody>
        </Table>        
        </TableContainer>
        </Stack>
    )
}

export default SubHistory