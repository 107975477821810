

export const settingsTabs = [
    {
        title: 'Account',
        id: 'account',
        route: '/dashboard/settings/account'
    },
    {
        title: 'Recycle Bin',
        id: 'bin',
        route: '/dashboard/settings/bin'
    },
    {
        title: 'Security',
        id: 'security',
        route: '/dashboard/settings/security'
    },
    {
        title: 'Subscription',
        id: 'sub',
        route: '/dashboard/settings/subscription'
    },
]