import { createContext } from 'react';
import { useContext } from 'react';

const LoadingContext = createContext();


export const useLoadingContext = () => {

    return useContext(LoadingContext)
}

export default LoadingContext

