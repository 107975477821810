import React, { useState } from 'react'
import Footer from '../Footer'
import AuthPageContext from '../../contexts/authPageContext'
import Header2 from '../Header/header'
import MediaMenu from '../Header/MediaMenu'
import { Stack } from '@mui/material'
import { useBreakpoints } from '../../theme/breakpoints'

const Layout = ({children}) => {
  const {md} = useBreakpoints()
  const [openMediaMenu, setOpenMediaMenu] = useState(false)

  return (
    <AuthPageContext.Provider
    value={{
        openMediaMenu,
        setOpenMediaMenu
    }}
    >
        <Header2 />
        <MediaMenu />
        <Stack sx={{pt: md ? '83px' : 0}}>
        {children}
        </Stack>
        <Footer />
    </AuthPageContext.Provider>
  )
}

export default Layout