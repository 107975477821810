import { createContext } from 'react';
import { useContext } from 'react';

const EmailsPageContext = createContext();


export const useEmailsPageContext = () => {

    return useContext(EmailsPageContext)
}

export default EmailsPageContext

