import { createContext } from 'react';
import { useContext } from 'react';

const ListPageContext = createContext();


export const useListPageContext = () => {

    return useContext(ListPageContext)
}

export default ListPageContext

