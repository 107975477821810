import { useBreakpoints } from "../theme/breakpoints"
import OTPInput from "react-otp-input"


const VCodeTextField = ({length=5, code='', onChange, submit, clearMessage}) => {
    const {xs, sm} = useBreakpoints()

    const handleChange = (value) => {
        onChange(value)
        submit && value.length === 5 && submit(value)
    }

    return (
        <OTPInput
        value={code}
        onChange={(val) => handleChange(val)}
        onPaste={e => handleChange(e.clipboardData.getData('text'))}
        numInputs={length}
        renderInput={(props) => <input {...props} onFocus={() => clearMessage()} />}
        
        containerStyle={{
            display: 'flex',
            width: '100%',
            gap: xs ? '0' : '20px',
            ...xs && {justifyContent: 'space-between'}
        }}
        inputStyle={{
            width: xs ? '35px' : sm ? '42px' :  '50px',
            height: xs ? '35px' : sm ? '42px' :  '50px',
            borderColor: 'rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            boxShadow: 'none',
            backgroundColor: '#f8f9fa',
            borderWidth: '1px'
        }}
        />
    )
}

export default VCodeTextField