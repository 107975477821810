import { Alert, Box, Modal, Stack, Typography, Zoom } from "@mui/material";
import useErrToasterStore from "../../stores/toasterStore";
import CustomButton from "../Button";



export const ErrorToaster = () => {
    const [
        show, 
        message, 
        hideToast,
    ] = useErrToasterStore(state => [state.show, state.message, state.hideToast])

    return (
        <>
        {
        show && message &&
        <Modal
        open
        >
            <Box
            minWidth='300px'
            position='absolute'
            top='50%'
            left='50%'
            bgcolor='transparent'
            boxShadow={24}
            sx={{
            transform: 'translate(-50%, -50%)',
            }}>
            <Alert
            severity="error" 
            sx={{
                '& .MuiAlert-message': {
                width: '100%'
                }
            }}
            >
                <Typography>
                {message}
                </Typography>
                <Stack
                alignSelf='flex-end'
                flexDirection='row'
                mt='20px'
                justifyContent='flex-end'
                gap='20px'
                >
                <CustomButton
                title='Report'
                variant='normal'
                noBorder
                sx={{textTransform: 'unset', color: 'error.main'}}
                />
                <CustomButton
                title='Close'
                variant='normal'
                // noBorder
                sx={{textTransform: 'unset'}}
                onClick={
                    () => {
                        hideToast()
                    }
                }
                />
                </Stack>
            </Alert>
            </Box>
        </Modal>
        }
        </>
    )
}