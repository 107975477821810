import {Button, Collapse, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import CustomButton from "../../Button"
import { useBreakpoints } from "../../../theme/breakpoints"


const UserTabPopOverOption = ({title, action, Icon, IconColor, subTabItems}) => {
    const [expandSubTabs, setExpandSubTabs] = useState(false)

    const MainTab = () => (
        <Button
        onClick={() => (action && action()) || setExpandSubTabs(!expandSubTabs)}
        sx={{
            display: 'flex',
            alignSelf: 'flex-start',
            justifyContent: 'left',
            textTransform: 'unset',
            p: '10px',
            gap: '10px',
            cursor: 'pointer',
            width: '100%'
        }}
        >
            <Icon size={30} color={IconColor}/>
            <Typography
            sx={{
                fontWeight: 500,
            }}
            >
                {title}
            </Typography>
        </Button>
    )


    return (
        <>
        {
        !subTabItems ?
        <MainTab /> :

        <Stack>
            <MainTab />
            <Collapse in={expandSubTabs}>
            <Stack
            sx={{
                pl: '50px'
            }}
            >
            {
                subTabItems.map( (items) => (
                    <SubTab 
                    key={items?.title}
                    {...items}
                    />
                ) )
            }
            </Stack>
            </Collapse>
        </Stack>
        }
        </>
    )
}

export default UserTabPopOverOption



export const SubTab = ({title, route, Icon}) => {
    const {sm} = useBreakpoints()

    return (
        <CustomButton 
        title={title}
        link
        linkProps={{to: route}}
        Icon={Icon}
        IconPlacement="left"
        variant="outlined"
        noBorder
        sx={{
            textTransform: 'unset', 
            ...sm && {
                bgcolor: 'transparent', 
                color: 'neutral.50',
                '& span': {fontSize: '.75rem'},
                '&:hover': {bgcolor: 'neutral.50', color: 'primary.main'}
            }
        }}
        />
    )
}