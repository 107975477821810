import { create } from 'zustand';
import { createSetState, SetState } from './util';

interface IErrToasterStates {
    show: boolean;
    message: string;
    showToast: (message: string) => void;
    hideToast: () => void;

    readonly setErrToasterState: SetState<Omit<IErrToasterStates, 'setErrToasterState'>>;
}


const useErrToasterStore = create<IErrToasterStates>((set) => ({
    show: false,
    message: '',

    showToast: (message: string) => set((state) => ({show: true, message})),
    hideToast: () => set((state) => ({show: false})),


    setErrToasterState: createSetState<Omit<IErrToasterStates, 'setErrToasterState'>>(set),
}));

export default useErrToasterStore;