import React from 'react'
import { BsChatDots, BsCreditCardFill } from 'react-icons/bs'
import { RiCustomerService2Fill } from 'react-icons/ri'



import './TypeOfSupport.css'

const TypeOfSupport = () => {
  return (
    <div className='typeMain'>
      <div className='container-fluid d-flex justify-content-center align-items-center flex-column type'>
        <h1>
          Looking for support, information or guidance, we’d love to hear from
          you
        </h1>
        <div className='typeofsupport d-flex flex-column flex-md-row justify-content-between w-100 mt-5'>
          <div className='general'>
            <BsChatDots className='generalIcon' />
            <h2>General</h2>
            <p>
              For general enquiries and feedback, email us at hello@reni.tech
            </p>
          </div>
          <div className='general'>
            <RiCustomerService2Fill className='generalIcon' />
            <h2>support</h2>
            <p>Already a user or have questions? Check out our FAQs here</p>
          </div>
          <div className='general'>
            <BsCreditCardFill className='generalIcon' />
            <h2>payment</h2>
            <p>
              All the information you need to know about your payments on
              ReniMail
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TypeOfSupport