import { IconButton, Skeleton, Stack, Tooltip, Typography } from "@mui/material"
import { useEffect } from "react"
import { useState } from "react"
import {useParams } from "react-router-dom"
import { fetchData } from "../../../../utils/functions/fetchData"
import { useBreakpoints } from "../../../../theme/breakpoints"
import DeleteEmail from "../DeleteEmail"
import { BiSolidArchiveIn, BiSolidArchiveOut } from "react-icons/bi"
import { AiFillDelete } from "react-icons/ai"
import toast from "react-hot-toast"
import client from "../../../../utils/axios"



const EmailDetails = () => {
    const {sm} = useBreakpoints()
    const {id} = useParams()
    const initialEmailData = {
        id,
        listName: null,
        subject: null,
        date: null,
        content: null,
        archive: null
    }
    const [emailData, setEmailData] = useState(initialEmailData)
    const [reloadEmailDetails, setReloadEmailDetails] = useState(false)
    const [loading, setLoading] = useState(false)


    const [openDeleteModal, setOpenDeleteModal] = useState(false)





    useEffect( () => {
        const handleLoadEmailData = async () => {
            setLoading(true)
            try{
                const {data} = await client.post('/getMailDetails', {mailId: id})

                const {listData, subject, real_date, message, archive } = data
                setEmailData({
                    listName: listData?.name,
                    subject,
                    date: real_date,
                    content: message,
                    id,
                    archive: archive!=='Not Archived' ? true : false
                })
            }
            catch(err){
                console.error(err.message)
            }
            finally{
                setLoading(false)
            }
        }

        handleLoadEmailData()
    }, [reloadEmailDetails] )


    const handleArchiveEmail = async () => {
        setLoading(true)
        try{
            const {message} = await client.post('/markEmailAsAchive', {id: emailData?.id})

            toast.success(message)
            setReloadEmailDetails(!reloadEmailDetails)
        }
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setLoading(false)
        }
    }

    const handleUnArchiveEmail = async () => {
        setLoading(true)
        try{
            const {message} = await client.post('/unmarkEmailAsAchive', {id: emailData?.id})

            toast.success(message)
            setReloadEmailDetails(!reloadEmailDetails)
        }
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setLoading(false)
        }
    }


    const details = [
        {
            title: 'To',
            data: emailData?.listName
        },
        {
            title: 'Subject',
            data: emailData?.subject
        },
        {
            title: 'Date',
            data: emailData?.date
        }
    ]


    return (
        <Stack
        sx={{
            width: '100%',
            bgcolor: 'neutral.50',
            boxShadow: '0 0 10px rgb(0,0,0,.125)',
            border: '1px solid',
            borderColor: 'neutral.600',
            borderRadius: '20px',
            gap: '20px',
            overflow: 'hidden'
        }}
        >
        <Stack
        sx={{
            width: '100%',
            bgcolor: '#f5f6ff',
            p: '15px 10px',
            borderBottom: '1px solid',
            borderColor: 'neutral.600',
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
        }}
        >

        <Stack direction='row' sx={{gap: '10px', alignItems: 'center'}}>
        <Tooltip
        title='Archive Mail'
        placement="top-start"
        >
        <IconButton
        disabled={loading}
        onClick={() => emailData?.archive ? handleUnArchiveEmail() : handleArchiveEmail()}
        >
            {emailData?.archive ? <BiSolidArchiveOut /> : <BiSolidArchiveIn /> }
        </IconButton>
        </Tooltip>
        <Tooltip
        title='Delete Mail'
        placement="top-start"
        >
        <IconButton
        disabled={loading}
        onClick={ () => setOpenDeleteModal(true) }
        sx={{color: 'red'}}
        >
            <AiFillDelete />
        </IconButton>
        </Tooltip>
        </Stack>
        </Stack>    



        <Stack
        sx={{
            p: '0 30px 50px 30px',
            gap: '10px'
        }}
        >
        {
            details.map( ({title, data}, k) => (
                <DetailBox 
                key={k}
                title={title}
                data={data}
                loading={loading}
                />
            ) )
        }

        <Stack
        sx={{
            mt: '20px'
        }}
        >
        {
            !loading ?
            <div dangerouslySetInnerHTML={{ __html: emailData?.content }} />:
            Array.from({length: 5}).map( () => (
                <Skeleton />
            ) )
        }
        </Stack>
        </Stack>





        <DeleteEmail
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        emailData={emailData}
        />
        </Stack>
    )
}

export default EmailDetails




const DetailBox = ({title, data, loading}) => {


    return (
        <Stack
        direction='row'
        sx={{
            p: '15px 20px',
            boxShadow: '0 0 10px rgb(0,0,0,.125)',
            borderRadius: '15px',
            gap: '10px'
        }}
        >
        <Typography component='span' sx={{fontWeight: '500'}}>
        {`${title}: `}
        </Typography>
        <Typography component='span'>
        {!loading ? `${data}` : <Skeleton width={80} height={20} />}
        </Typography>
        </Stack>
    )
}
