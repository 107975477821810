import { Stack, Typography } from '@mui/material';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import {useNavigate } from 'react-router-dom';
import CustomButton from '../Button';
import { useBreakpoints } from '../../theme/breakpoints';
import { isLoggedIn } from '../../utils/functions/fetchData';
import { useState } from 'react';
import Loader from '../Dashboard/Loader';

const ErrorPage = () => {
    const {xs, sm, md, lg} = useBreakpoints()
    const navigate = useNavigate()

    const [loggedIn, setLoggedIn] = useState('loading')

    useState( () => {
        (async () => {
            try{
                const res = await isLoggedIn()
                setLoggedIn(res)
            }
            catch(err){
                setLoggedIn(false)
            }
        })()
    }, [] )


    if(loggedIn === 'loading'){
        return <Loader />
    }


    return (
        <Stack
        component="main"
        sx={{
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
            p: '10px'
        }}
        >
        <img
        alt="404 Error"
        src="/assets/images/error-404.png"
        style={{
            display: 'inline-block',
            width: xs ? 492/2.1 : sm ? 492/1.8 : md ? 492/1.5 : 492/1.2,
            height: xs ? 492/2.1 : sm ? 492/1.8 : md ? 492/1.5 : 492/1.2
        }}
        />
        <Typography
        align="center"
        sx={{color: 'neutral.700' }}
        variant="h3"
        >
            404: Oh boy! You&apos;ve missed your way
        </Typography>
        <Typography
        align="center"
        color="neutral.900"
        variant="body1"
        sx={{mt: '-15px'}}
        >
        You either tried some shady route or you came here by mistake.
        Whichever it is, try using the navigation
        </Typography>

        <Stack
        direction={sm ? 'column' : 'row'}
        sx={{
            gap: sm ? '30px' : '10px'
        }}
        >
        <CustomButton
        {...loggedIn && {variant: 'outlined'}}
        title='Go back Home'
        Icon={AiOutlineArrowLeft}
        IconPlacement='left'
        sx={{alignSelf: 'unset'}}
        onClick={() => navigate('/')}
        />

        {
        loggedIn &&
        <CustomButton
        title='Go to Dashboard'
        Icon={AiOutlineArrowRight}
        IconPlacement='right'
        sx={{alignSelf: 'unset'}}
        onClick={() => navigate('/dashboard')}
        />
        }
        
        </Stack>
        </Stack>
    )
}
export default ErrorPage;
