import { Stack } from "@mui/material"
import SettingsTabs from "./SettingsTabs"
import Layout from "../../Layouts/DashboardLayout"
import { useBreakpoints } from "../../../theme/breakpoints"
import { useLayoutContext } from "../../../contexts/layoutContext"
import { useState } from "react"
import { useLocation } from "react-router-dom"
import Account from "./Account"
import Bin from "./Bin"
import Security from "./Security"
import Subscription from "./Subscription"
import SettingsPageContext from "../../../contexts/settingsPageContext"



const SettingsPage = () => {
    const {miniSideBar} = useLayoutContext()
    const {sm, md, mdOnly} = useBreakpoints()
    const pathname = useLocation().pathname


    return (
        <SettingsPageContext.Provider 
        value={{}}
        >
        <Stack
        direction={!md ? "row" : "column"}
        sx={{
            p: (!miniSideBar && mdOnly) ? '50px 20px' : sm ? '15px' : '50px 50px',
            gap: '50px'
        }}
        >
            <Stack
            sx={{width: !md ? '25%' : '100%'}}
            >
                <SettingsTabs />
            </Stack>
            <Stack sx={{width: !md ? '75%' : '100%'}}>
            {
                (pathname === '/dashboard/settings' || pathname === '/dashboard/settings/account') &&
                <Account />
            }
            {
                pathname === '/dashboard/settings/bin' &&
                <Bin />
            }
            {
                pathname === '/dashboard/settings/security' &&
                <Security />
            }
            {
                pathname === '/dashboard/settings/subscription' &&
                <Subscription />
            }
            </Stack>
        </Stack>
        </SettingsPageContext.Provider>
    )
}

const Settings = () => {
    return (
        <Layout>
            <SettingsPage />
        </Layout>
    )
}

export default Settings