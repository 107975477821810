import { Fragment, useState } from "react"
import { useListPageContext } from "../../../../contexts/listPageContext"
import { fetchData } from "../../../../utils/functions/fetchData"
import toast from "react-hot-toast"
import { CircularProgress, IconButton, TableCell, TableRow, TextField as MUITextField, Typography } from "@mui/material"
import InfoView from "../../InfoView"
import { BiSolidEditAlt } from "react-icons/bi"
import { VscSaveAs } from "react-icons/vsc"
import { MdDelete } from "react-icons/md"
import DeleteContactModal from "./DeleteContactModal"
import { useBreakpoints } from "../../../../theme/breakpoints"
import client from "../../../../utils/axios"



const ContactRow = ({fname, lname, email, status, id, k}) => {
    const contactDetail = {
        fname,
        lname,
        email,
        status,
        id
    }
    const [editMode, setEditMode] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const {setContact} =  useListPageContext()

    const handleModifyContact = async () => {
        setLoading(true)
        try{
            const {message} = await client.post('/editContact', {
                contactid: id,
                mail: contactDetail?.email,
                fname: contactDetail?.fname, 
                lname: contactDetail?.lname,
            })

            toast.success(message)
            setEditMode(false)
        }
        catch(err){
            toast.error(err.message)
        }
        finally{
            setLoading(false)
        }
    }


    return (
        <Fragment>
        <TableRow
        sx={{
            bgcolor: k % 2 ? 'neutral.50' : '#F1F1F1'
        }}
        >
            <TableCell>
            {
            !editMode ?
            <Typography>
            {contactDetail?.fname}
            </Typography> :
            <TextField
            value={contactDetail?.fname}
            onChange={ (e) => setContact( (prevVal) => ({...prevVal, fname: e.target.value}), contactDetail?.id) }
            />
            }
            </TableCell>
            <TableCell>
            {
            !editMode ?
            <Typography>
            {contactDetail?.lname}
            </Typography> :
            <TextField 
            value={contactDetail?.lname}
            onChange={ (e) => setContact( (prevVal) => ({...prevVal, lname: e.target.value}), contactDetail?.id) }
            />
            }
            </TableCell>
            <TableCell align="center">
            {
            !editMode ?
            <Typography>
                {contactDetail?.email}
            </Typography> :
            <TextField 
            value={contactDetail?.email}
            onChange={ (e) => setContact( (prevVal) => ({...prevVal, email: e.target.value}), contactDetail?.id) }
            />
            }
            </TableCell>
            <TableCell align="center">
            <Typography sx={{ fontWeight: 500, color: status && status.toLowerCase()==='active' ? '#01711A' : '#C00'}}>
                {status}
            </Typography>
            </TableCell>

            <TableCell align="center">
            {!loading ?
            <InfoView tooltip tooltipInfo={!editMode ? 'Edit' : 'Save'}>
            {
            !editMode ?
            <IconButton
            sx={{color: '#3498db'}}
            onClick={() => setEditMode(true)}
            >
                <BiSolidEditAlt />
            </IconButton> :
            <IconButton
            sx={{color: '#008080', flexDirection: 'column'}}
            onClick={handleModifyContact}
            >
                <VscSaveAs />
                <Typography>Save</Typography>
            </IconButton>
            }
            </InfoView> :
            <CircularProgress size={18} sx={{color: 'neutral.400'}}/>
            }
            </TableCell>

            <TableCell align="center">
            <InfoView tooltip tooltipInfo='Delete'>
                <IconButton 
                sx={{color: '#e74c3c'}}
                onClick={() => setOpenDeleteModal(true)}
                >
                    <MdDelete />
                </IconButton>
            </InfoView>
            </TableCell>
        </TableRow>

        <DeleteContactModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        contactDetail={contactDetail}
        />
        </Fragment>
    )
}


export default ContactRow











const TextField = (props) => {
    const {sm, md} = useBreakpoints()


    const textFieldStyle = {
        minWidth: '50%',
        '& .MuiInputBase-root': {
            borderRadius: '8px'
        },
        '& input': {
            bgcolor: 'white',
            color: '#000',
            p: '5px 15px',
            minHeight: '30px',
            ...md && {
                fontSize: '.9rem'
            },
            ...sm && {
                fontSize: '.8rem'
            }
        },
        '& input:hover': {
            borderColor: 'primary.main'
        },
    }

    return (
    <MUITextField
    sx={textFieldStyle}
    {...props}
    />
    )
}