import React from 'react'
import clips from '../Img/creator clips.png'
import clips1 from '../Img/Rectangle 61 (3).png'
import stories from '../Img/creator stories.png'
import stories2 from '../Img/Rectangle 61.png'
import icreates from '../Img/icreate.png'
import icreates2 from '../Img/Rectangle 61 (2).png'
import determine from '../Img/Group 130.png'
import { FaArrowRight } from 'react-icons/fa'
import '../Homepage/Future.css'
import CustomButton from '../../Button'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useBreakpoints } from '../../../theme/breakpoints'
const Future = () => {
  const {sm, md} = useBreakpoints()
  const navigate = useNavigate()

  return (
    <div className='futuremain'>
      <div className='container-fluid future'>
        <h1 className='future-heading mt-4'>Creators determine the future</h1>
        <p className='future-paragraph'>
          Creators change the world. Check out stories about how{' '}
          <b>Renimail </b> has helped your favourite creatives with their
          creative process.
        </p>
        <div className='clipsandstories d-flex flex-column flex-lg-row justify-content-between align-items-center w-100'>
          <div className='clips position-relative'>
            <div className='img-clip w-100 h-100'>
              <img src={clips} alt='' className='img-fluid d-none d-lg-block' />
              <img
                src={clips1}
                alt=''
                className='img-fluid d-block d-lg-none'
              />
            </div>
            <div className='clips__overlay'></div>
            <div className='clips-text'>
              <p>CREATOR CLIPS</p>
              <div className='stories'>
                <a href='#'>
                  Read creator stories <FaArrowRight className='icon' />
                </a>
              </div>
            </div>
          </div>
          <div className='clips position-relative'>
            <img src={stories} alt='' className='img-fluid d-none d-lg-block' />
            <img
              src={stories2}
              alt=''
              className='img-fluid d-block d-lg-none'
            />
            <div className='clips__overlay'></div>
            <div className='clips-text'>
              <p>CREATOR STORIES</p>
              <div className='stories'>
                <a href='#'>
                  Watch creator clips <FaArrowRight className='icon' />
                </a>
              </div>
            </div>
          </div>
          <div className='clips position-relative'>
            <img
              src={icreates}
              alt=''
              className='img-fluid d-none d-lg-block'
            />
            <img
              src={icreates2}
              alt=''
              className='img-fluid d-block d-lg-none'
            />
            <div className='clips__overlay'></div>
            <div className='clips-text'>
              <p>iCREATE</p>
              <div className='stories'>
                <a href='#'>
                  Creative processes <FaArrowRight className='icon' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='aud'>
        <div className='audience'>
          <div className='hr'></div>
          <h1 className='marketing'>Start marketing with ReniMail</h1>
          <div className='audience-text d-flex flex-column flex-lg-row justify-content-between'>
            <p className='audience-para '>
              Build your audience with ReniMail or bring them with you from your
              current platform.
            </p>
            <div className='imggg d-flex justify-content-center'>
              <div className='audience-img'>
                <img src={determine} alt='' className='img-fluid' />
              </div>
            </div>
          </div>
          <CustomButton
          link
          linkProps={{to: '/register'}}
          Icon={FaArrowRight}
          IconPlacement='right'
          variant='outlined'
          sx={{
            borderRadius: '5px',
            textTransform: 'unset',
            p: '15px 20px',
            mt: '20px',
            mx: md ? 'auto' : 'unset',
            color: 'primary.main',
            width: 'fit-content'
          }}
          >
            <Typography variant='h6' sx={{color: 'primary.main'}}>Get Started For Free</Typography>
          </CustomButton>
        </div>
      </div>
    </div>
  )
}

export default Future
