import {FaXTwitter, FaYoutube, FaFacebook, FaSquareInstagram} from 'react-icons/fa6'

export const socials = [
    {
        title: 'Instagram',
        Icon: FaSquareInstagram,
        route: 'https://instagram.com/ReniTechHq'
    },
    {
        title: 'Facebook',
        Icon: FaFacebook,
        route: 'https://fb.me/ReniTechHq'
    },
    {
        title: 'Twitter',
        Icon: FaXTwitter,
        route: 'https://x.com/ReniTechHq'
    },
    {
        title: 'Youtube',
        Icon: FaYoutube,
        route: '#'
    },
]



const products = [
    {
        title: 'All Offers',
        route: '#',
    },
    {
        title: 'Landing Pages ',
        route: '#',
    },
    {
        title: 'Email Templates',
        route: '#',
    },
    {
        title: 'Sign Up Forms',
        route: '#',
    },
    {
        title: 'Automations',
        route: '#',
    },
    {
        title: 'Updates',
        route: '#',
    },
    {
        title: 'Newsletters',
        route: '#',
    },
    {
        title: 'Log In',
        route: '#',
    },
]

const account = [
    {
        title: 'Profile',
        route: '#'
    },
    {
        title: 'Contacts ',
        route: '#'
    },
    {
        title: 'Settings',
        route: '#'
    },
    {
        title: 'Plans',
        route: '#'
    },
    {
        title: 'Plans',
        route: '#'
    },
    {
        title: 'Compare',
        route: '#'
    },
]

const content = [
    {
        title: 'Blogs',
        route: '#'
    },
    {
        title: 'Tutorials',
        route: '#'
    },
    {
        title: 'Courses',
        route: '#'
    },
    {
        title: 'Guidelines',
        route: '#'
    },
    {
        title: 'Podcasts',
        route: '#'
    },
    {
        title: 'Community',
        route: '#'
    },
    {
        title: 'Workshops',
        route: '#'
    },
    {
        title: 'Conferences',
        route: '#'
    },
    {
        title: 'Social Media',
        route: '#'
    },
]


const renimail = [
    {
        title: 'About Us',
        route: '#'
    },
    {
        title: 'Meet the team',
        route: '#'
    },
    {
        title: 'Mission & Vision',
        route: '#'
    },
    {
        title: 'Customer Support',
        route: '#'
    },
    {
        title: 'FAQs',
        route: '#'
    },
]

const usefulLinks = [
    {
        title: 'About Us',
        route: '/about'
    },
    {
        title: 'Pricing',
        route: '/pricing'
    },
    {
        title: 'Developers',
        route: 'https://sandbox.api.reni.tech/docs'
    },
]


export const footerContents = [
    {
        heading: 'Useful Links',
        content: usefulLinks
    },
    // {
    //     heading: 'PRODUCT',
    //     content: products
    // },
    // {
    //     heading: 'ACCOUNT',
    //     content: account
    // },
    // {
    //     heading: 'CONTENT',
    //     content: content
    // },
    // {
    //     heading: 'RENIMAIL',
    //     content: renimail
    // },
]