import { Button, Divider, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { neutral } from "../../../theme/palette"
import { TbMailFilled } from "react-icons/tb"
import { useBreakpoints } from "../../../theme/breakpoints"
import { useEffect } from "react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import TableDivider from "../../TableDivider"
import client from "../../../utils/axios"


const dateOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
}
  


const RecentlySent = () => {
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    console.log(tableData)

    useEffect( () => {
        setLoading(true)
        const handleLoadData = async () => {
        try{
            const {data: mailsData} = await client.post('/getMails', {}, {version: 3, useBackgroundFetch: true})
            setTableData(
                mailsData.slice(0,5).map( ({subject, listData, clicks, sent, timestamp}) => ({
                campaign: subject,
                list: listData?.name,
                // delivered: '0/0',
                clicks: clicks,
                status: parseInt(sent),
                date: new Date(timestamp * 1000).toLocaleString()
                }) )
            )
        }
        catch(err){
            console.error(err.message)
        }
        finally{
            setLoading(false)
        }
        }
        
        handleLoadData()
    }, [])

    const {sm} = useBreakpoints()

    return (
        <Stack
        sx={{
            width: '100%',
            bgcolor: 'neutral.50',
            boxShadow: '0 0 10px rgb(0,0,0,.125)',
            border: '1px solid',
            borderColor: 'neutral.600',
            borderRadius: '20px',
            p: '15px 10px',
            gap: '20px'
        }}
        >
        <Stack direction='row' sx={{alignItems: 'center', gap: '10px'}}>
        <Stack
        sx={{
            width: '40px', height: '40px',
            borderRadius: '50%',
            alignItems: 'center', justifyContent: 'center',
            bgcolor: '#C30B90',
        }}
        >
            <TbMailFilled size={25}  color={neutral[50]}/>
        </Stack>
        <Typography sx={{fontWeight: 500}}>
            Recently Sent Campaign
        </Typography>
        </Stack>

        <TableContainer
        sx={{
            minHeight: '230px',
        }}
        >

        <Table
        sx={{
            minWidth: 650,
            '& th, td': {
                p: sm ? '1px 10px' : '3px 5px',
                fontWeight: 500,
                ...sm && {fontSize: '.9rem'}
            }
        }}
        >
            <TableHead sx={{bgcolor: 'transparent'}}>
            <TableDivider colSpan={6} />
            <TableRow>
                <TableCell>Campaign</TableCell>
                <TableCell>List</TableCell>
                {/* <TableCell align="center">Delivered</TableCell> */}
                <TableCell align="center">Clicks</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Date</TableCell>
            </TableRow>
            <TableDivider colSpan={6} />
            </TableHead>
            <TableBody>
            {   !loading && !tableData.length ? 
                <TableRow
                sx={{
                    bgcolor: '#F1F1F1'
                }}
                >
                    <TableCell colSpan={6} rowSpan={3} sx={{textAlign: 'center'}}>
                    No Campaign found
                    </TableCell>
                </TableRow> :
                
                !loading ? (
                tableData.map(({ campaign, list, delivered, clicks, status, date }, k) => (
                    <TableRow
                        key={k}
                        sx={{
                            bgcolor: k % 2 ? 'neutral.50' : '#F1F1F1'
                        }}
                    >
                        <TableCell>{campaign}</TableCell>
                        <TableCell>
                            <Typography sx={{fontSize: 'unset', fontWeight: 500}}>
                            {list}
                            </Typography>
                        </TableCell>
                        {/* <TableCell align="center">{delivered}</TableCell> */}
                        <TableCell align="center">{clicks}</TableCell>
                        <TableCell align="center">
                            <Typography sx={{ fontWeight: 500, color: status ? '#01711A' : '#C00', whiteSpace: 'nowrap' }}>
                                {status ? 'SENT' : 'NOT SENT'}
                            </Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Typography sx={{ fontSize: '.95rem', fontWeight: 500 }}>
                                {date}
                            </Typography>
                        </TableCell>
                    </TableRow>
                ))
            ) : (
                Array.from({length: 3}).map( (_, k) => (
                    <TableRow
                        key={k}
                        sx={{
                            bgcolor: k % 2 ? 'neutral.50' : '#F1F1F1'
                        }}
                    >
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                    <TableCell align="center"><Skeleton /></TableCell>
                    <TableCell align="center"><Skeleton /></TableCell>
                    <TableCell align="center"><Skeleton /></TableCell>
                    <TableCell align="center"><Skeleton /></TableCell>
                </TableRow>
                ) )
            )}

            </TableBody>
        </Table>        
        </TableContainer>

        {
            !loading && tableData.length !== 0 &&
            <Button 
            variant="contained"
            sx={{
                alignSelf: 'flex-start'
            }}
            onClick={() => navigate('/dashboard/emails')}
            >
                See More
            </Button>
        }
        </Stack>
    )
}

export default RecentlySent