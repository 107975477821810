import React, {useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { IconButton, Stack } from '@mui/material'
import { useBreakpoints } from '../../theme/breakpoints'
import Logo from '../Logo'
import SignOutModal from '../Auth/SignOut/SignOutModal'
import CustomButton from '../Button'
import { nav } from './nav'
import { CgClose, CgMenu } from 'react-icons/cg'
import { neutral, primary } from '../../theme/palette'
import { useAuthPageContext } from '../../contexts/authPageContext'

const Header2 = () => {
  const {md} = useBreakpoints()
  const [openSignOutModal, setOpenSignOutModal] = useState(false)
  const {openMediaMenu, setOpenMediaMenu} = useAuthPageContext()

  const userData = JSON.parse(localStorage.getItem('userData'))
  
  
  return (
      <>
      <Stack
      component='header'
      direction='row'
      sx={{
        p: !md ? '1rem 3rem !important' : '1rem !important',
        justifyContent: 'space-between',
        boxShadow: '0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0, 0, 0, .125)',
        bgcolor: openMediaMenu ? 'primary.main' : 'neutral.50',
        alignItems: 'center',
        position: md ? 'fixed' : 'static',
        top: 0,
        zIndex: 999,
        width: '100%'
      }}
      >
        <Link to='/' className='logoss'>
          <Logo />
        </Link>

        {
        !md ?
        <>
        <Stack
        direction='row'
        sx={{
          gap: '20px'
        }}
        >
        {
          nav.map( ({title, route}, k) => (
            <LinkButton 
            key={k}
            title={title}
            route={route}
            />
          ) )
        }
        </Stack>

        <Stack
        direction='row'
        sx={{
          gap: '20px'
        }}
        >
        {
        !userData ?
        <LinkButton 
        title='Login'
        route='/login'
        /> :
        <LinkButton 
        title='Dashboard'
        route='/dashboard'
        />
        }

        {
        !userData ?
        <LinkButton 
        title='Join for free'
        route='/register'
        /> :
        <LinkButton 
        title='Sign Out'
        action={() => setOpenSignOutModal(true)}
        />
        }
        </Stack>
        </> :

        <IconButton
        onClick={() => setOpenMediaMenu(!openMediaMenu)}
        >
        {
        !openMediaMenu ?
        <CgMenu size={35} color={primary.main} /> :
        <CgClose size={35} color={neutral[50]} />
        }
        </IconButton>
        }
        
      </Stack>


      <SignOutModal
      open={openSignOutModal}
      setOpen={setOpenSignOutModal}
      />
      </>
  )

}

export default Header2




const LinkButton = ({route, variant='outlined', action, ...props}) => {
  const pathname = useLocation().pathname
 
  return (
    <>
    {
    route ?
    <Link to={route}>
      <CustomButton
      onClick={() => action && action()}
      variant={variant}
      sx={{
        textTransform: 'unset',
        border: 'none',
        color: pathname===route ? 'secondary.main' : 'primary.main',
        '&:hover': {
          color: 'secondary.main'
        },
        '& span': {
          ...pathname===route && {
            fontWeight: 900,
          }
        }
      }}
      {...props}
      />
    </Link> :
    <CustomButton
      onClick={() => action && action()}
      variant={variant}
      sx={{
        textTransform: 'unset',
        border: 'none',
        color: 'primary.main',
        '&:hover': {
          color: 'secondary.main'
        }
      }}
      {...props}
      />
    }
    </>
  )
}