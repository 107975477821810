import { Checkbox, Skeleton, Stack, Typography } from "@mui/material"
import { BsBox, BsCheckLg, BsToggleOff, BsToggleOn } from "react-icons/bs"
import { useNavigate } from "react-router-dom"
import { useBreakpoints } from "../../../../theme/breakpoints"
import { neutral, primary } from "../../../../theme/palette"
import CustomButton from "../../../Button"
import { useState } from "react"
import { TbCurrencyNaira } from "react-icons/tb"
import { fetchData } from "../../../../utils/functions/fetchData"
import { toast } from "react-hot-toast"
import { useEffect } from "react"
import client from "../../../../utils/axios"


const IconContWrappeStyle = (bgcolor) =>  ({width: '55px', height: '55px', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', bgcolor: bgcolor})
const IconContStyle  = (bgcolor) => ({width: '40px', height: '40px', borderRadius: '50%', bgcolor: bgcolor, alignItems: 'center', justifyContent: 'center'})

const MyPlan = () => {
    const navigate = useNavigate()
    const {sm, md, lg} = useBreakpoints()
    const [loading, setLoading] = useState(false)
    const planInitialState = {
        tierName: '',
        amount: '',
        expiryDate: '',
        id: null
    }
    const [planData, setPlanData] = useState(planInitialState)


    const handleLoad = async () => {
        setLoading(true)
        try{
            const {data: planDetails} = await client.post('/getUserSubscription', {}, {noError: true})

            planDetails &&
            setPlanData({
                tierName: planDetails?.packageDetails?.name,
                amount: planDetails?.amount,
                expiryDate: planDetails?.expire_date,
                id: planDetails?.packageDetails?.id
            })
        }
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setLoading(false)
        }

    }

    useEffect( () => {
        handleLoad()
    }, [] )

    return (
        <Stack
        sx={{
            width: '100%',
            minHeight: '300px',
            bgcolor: 'neutral.50',
            boxShadow: '0 0 10px rgb(0,0,0,.125)',
            border: '1px solid',
            borderColor: 'neutral.400',
            borderRadius: '20px',
            p: '20px',
            gap: '30px',
            justifyContent: 'center'
        }}
        >
            <Stack
            direction='row'
            sx={{alignItems: 'center', gap: '10px'}}
            >
                <Stack sx={IconContWrappeStyle('#BED9FB')}>
                <Stack
                sx={IconContStyle('primary.main')}
                >
                    <BsBox size={23} color={neutral[50]} />
                </Stack>
                </Stack>
                <Typography variant="h5">
                    My Plan
                </Typography>
            </Stack>
            <Stack
            sx={{
                gap: '20px',
                position: 'relative',
                width: sm ? '100%' : md ? '95%' : lg ? '90%' : '70%',
                border: '2px solid',
                borderColor: 'primary.main',
                borderRadius: '20px',
                p: '20px'
            }}
            >
            <Stack direction='row' sx={{justifyContent: 'space-between'}}>
                <Typography variant="h6" sx={{display: 'flex', gap: '5px'}}>
                    {!loading ? planData?.tierName : <Skeleton width={30} height={20} />}
                </Typography>
                <Typography variant="h6" sx={{display: 'flex', gap: '5px'}}>
                    {!loading ? <><TbCurrencyNaira size={25} />{planData?.amount}/Month</> : <Skeleton width={30} height={20} />}
                </Typography>
            </Stack>
            <Stack>
                <Typography sx={{color: '#B22222'}}>
                    Expiry Date: {!loading ? (planData.id != 1 ? planData?.expiryDate : 'NA') : <Skeleton width={30} height={20} />}
                </Typography>
            </Stack>
            <Stack direction={ sm ? 'column-reverse' : 'row'} sx={{justifyContent: 'space-between', gap: '20px'}}>
                <CustomButton
                title="Cancel Subscription"
                variant="outlined"
                squareCorners
                disabled={loading}
                />
                <CustomButton
                title="Upgrade"
                variant="contained"
                squareCorners
                onClick={() => navigate('/dashboard/settings/pricing')}
                disabled={loading}
                />
            </Stack>

            <Stack sx={{position: 'absolute', right: -5, top: -10, bgcolor: 'primary.main', borderRadius: '50%', p: '1.5px'}}>
                <BsCheckLg size={20} color={neutral[50]} />
            </Stack>
            </Stack>
            
            <Stack direction='row' sx={{gap: '10px', alignItems: 'center'}}>
            <Typography sx={{color: 'grey.main'}}>
                Enable  Auto-renewal
            </Typography>
            
            <Checkbox 
            icon={<BsToggleOff size={30} color={primary.main} />} 
            checkedIcon={<BsToggleOn size={30} color={primary.main} />}
            onChange={(_, value) => console.log(value)}
            />
            </Stack>
        </Stack>
    )
}

export default MyPlan