import { CircularProgress, Stack, TextField, Typography } from "@mui/material"
import { useBreakpoints } from "../../../theme/breakpoints"
import {IoClose} from 'react-icons/io5'
import { useComposePageContext } from "../../../contexts/composePageContext"
import { useEffect, useState } from "react"
import { TbCalendarTime } from "react-icons/tb"
import DatePicker from "./DatePicker"
import TimePicker from "./TimePicker"
import { BsFillSendFill } from "react-icons/bs"



const formatDate = (date: Date) => date.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
})


const ScheduleCard = () => {
    const {setCurrentScreen, emailData, setEmailData, handleSendEmail} = useComposePageContext()
    const [currentTab, setCurrentTab] = useState('schedule')
    const [date, setDate] = useState(new Date())
    const {sm, md} = useBreakpoints()
    const [loading, setLoading] = useState(false)


    const tabs = [
        {
            title: 'Schedule send',
            id: 'schedule',
            action: () => setCurrentTab('schedule')
        },
        {
            title: 'Send Now',
            id: 'send',
            action: () => setCurrentTab('send')
        }
    ]

    useEffect( () => {
        setEmailData( (prevVal) => ({...prevVal, 
            scheduleTime: formatDate(date),
            scheduleDate: formatDate(date)
        }) )
    }, [emailData] )


    return (
        <Stack
        data-aos="slide-up"
        sx={{
            boxShadow: '0 0 20px rgb(0,0,0,.125)',
            borderTopLeftRadius: '25px',
            borderTopRightRadius: '25px',
            overflow: 'hidden',
            p: '0 0 30px 0',
            width: md ? '100%' : '80%',
            alignSelf: 'center'
        }}
        >
            <Stack
            sx={{
                p: !sm ? '30px 20px' : '20px',
                bgcolor: '#f7f8ff',
                fontWeight: '900'
            }}
            >
                <Stack
                sx={{alignSelf: 'flex-end', cursor: 'pointer'}}
                onClick={() => setCurrentScreen('default')}
                >
                <IoClose size={30} />
                </Stack>
            </Stack>

            <Stack
            sx={{
                // alignSelf: 'center',
                p: !sm ? '50px 0 0 0' : '20px 0 0 0',
                gap: !sm ? '50px' : '30px',
                ...sm && {width: '100%'}
            }}
            >
            <Stack
            direction='row'
            >
                {
                tabs.map( ({title, id, action}, k) => (
                    <Stack
                    key={k}
                    onClick={action}
                    sx={{
                        borderBottom: '1px solid',
                        borderColor: currentTab === id ? 'primary.main' : 'neutral.900',
                        p: !sm ? '15px 50px' : '15px 10px',
                        cursor: 'pointer',
                        ...sm && {width: '50%'}
                    }}
                    >
                    <Typography
                    sx={{
                        color: currentTab === id ? 'primary.main' : 'neutral.900',
                        fontWeight: 500
                    }}
                    >
                        {title}
                    </Typography>
                    </Stack>
                ) )
                }
            </Stack>

            <Stack
            sx={{
                p: '20px',
                ...(currentTab === 'schedule' && !sm) && {boxShadow: '0 0 10px rgb(0,0,0,.125)'},
                borderRadius: '15px',
                alignSelf: 'center'
            }}
            >
            {
                currentTab === 'schedule' &&
                <Stack sx={{gap: sm ? '30px' : 0}}>
                    <Typography sx={{fontWeight: 600}}>
                        Select the date and time
                    </Typography>
                <Stack direction={!sm ? 'row' : 'column'} sx={{gap: sm ? 0 : md ? '20px' : '50px'}}>
                    <Stack>
                        <DatePicker date={date} setDate={setDate} />
                    </Stack>
                    <Stack sx={{gap: '20px'}}>
                        <TextField 
                        disabled
                        value={date.toLocaleString('en-US', {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                        })}
                        />
                        <TimePicker 
                        date={date} setDate={setDate}
                        />
                    </Stack>
                    </Stack>
                    <Stack direction='row' sx={{gap: '10px', alignSelf: 'flex-end', alignItems: 'center'}}>
                        <Typography 
                        sx={{cursor: 'pointer', ...loading && {color: 'neutral.500'}}}
                        onClick={() => !loading && setDate(new Date())}
                        >
                        Reset
                        </Typography>
                        <Stack 
                        direction='row' sx={{gap: '10px', borderRadius: '14px', p: '7px 5px', bgcolor: 'primary.main', color: 'neutral.50', cursor: 'pointer'}}
                        onClick={() => handleSendEmail('schedule', setLoading)}
                        >
                            {!loading ? <Typography>Schedule</Typography> : <CircularProgress sx={{color: "neutral.50"}} size={25} />}
                            {!loading && <TbCalendarTime size={20} />}
                        </Stack>
                    </Stack>
                </Stack>
            }
            {
                currentTab === 'send' &&
                <Stack 
                direction='row'
                sx={{
                    color: 'neutral.50',
                    bgcolor: 'primary.main',
                    gap: '20px',
                    p: '7px 10px',
                    borderRadius: '15px',
                    alignItems: 'center',
                    cursor: !loading ? 'pointer' : 'not-allowed',
                    ...loading && {bgcolor: 'neutral.500'},
                }}
                onClick={() => handleSendEmail('send', setLoading)}
                >
                    <Typography>
                    {!loading ? 'Send Now' : <CircularProgress sx={{color: "neutral.50"}} size={25} />}
                    </Typography>
                    {!loading && <BsFillSendFill size={20} />}
                </Stack>
            }
            </Stack>
            </Stack>
        </Stack>
    )
}

export default ScheduleCard