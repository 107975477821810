import {Dispatch, SetStateAction, createContext, useContext} from 'react'
import { IEditorType, IEmailData, IHandleSendEmail } from '../Components/Dashboard/Compose/types';

interface IComposePageContext {
    currentScreen: any;
    setCurrentScreen:  Dispatch<SetStateAction<any>>;
    initialEmailData: IEmailData;
    emailData: IEmailData;
    setEmailData: Dispatch<SetStateAction<any>>;
    resetEmailData: () => void;
    editorType: IEditorType ;
    setEditorType:  Dispatch<SetStateAction<IEditorType>>;

    handleSendEmail: IHandleSendEmail;
}

const ComposePageContext = createContext<IComposePageContext>({} as IComposePageContext);

export const useComposePageContext = () => {
    return useContext(ComposePageContext);
};

export default ComposePageContext;