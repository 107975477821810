import { Button, CircularProgress, Stack, SxProps, Typography } from "@mui/material"
import { Link, LinkProps } from "react-router-dom"
import { IconType } from "react-icons/lib";
import { FC, PropsWithChildren } from "react";


interface ICustomButton extends PropsWithChildren {
    title?: string;
    Icon?: IconType;
    IconColor?: string;
    IconSize?: number;
    IconPlacement?: string;
    loading?: boolean;
    loadingText?: string;
    sx?: SxProps;
    variant?: string;
    roundedCorners?: boolean;
    squareCorners?: boolean;
    noBorder?: boolean;


    link?: boolean;
    linkProps?: LinkProps;

    [key: string]: any;
}


const CustomButton: FC<ICustomButton> = ({
    title, children, Icon, IconColor, IconSize, IconPlacement='right', 
    loading, loadingText,  sx,  variant='contained',  roundedCorners=true, squareCorners, noBorder=false,
    link=false, linkProps, ...props
    }) => {

    return (
        <Button
        {...link && {LinkComponent: Link, ...(linkProps as any)}}
        disabled={loading}
        sx={{
            display: 'flex',
            ...variant!=='normal' && {
                color: variant==='outlined' ? "neutral.900" : "neutral.50",
                bgcolor: variant==='outlined' ? 'neutral.50' : 'primary.main',
                '&:hover': {
                    bgcolor: variant==='outlined' ? 'neutral.50' : 'primary.main',
                    ...loading && {
                        bgcolor: 'neutral.400'
                    }
                },
                gap: '10px',
                p: '7px 10px',
                borderRadius: squareCorners ? '10px' : '20px',
                alignItems: 'center',
                cursor: !loading ? 'pointer' : 'not-allowed',
                ...loading && variant !== 'outlined' && {bgcolor: 'neutral.400'},
                alignSelf: 'flex-end',
                ...variant==='outlined' && {border: '1.6px solid', borderColor: 'primary.main'},
                '&.Mui-disabled': {
                bgcolor: 'neutral.400',
                color: 'neutral.600',
            },
            },
            ...noBorder && {border: 'none'},
            ...sx,
        }}
        {...props}
        >
            {!loading && IconPlacement==='left' && Icon && <Icon size={20} />}
            {
                !loading ?
                (
                    title ?
                <Typography sx={{fontWeight: 500}} component='span'>
                {title}
                </Typography> :
                    children
                ) :
                <Stack direction='row' sx={{gap: '5px'}}>
                <CircularProgress sx={{color: variant==='outlined' ? "neutral.900" : "neutral.50"}} size={25} />
                {
                    loadingText &&
                    <Typography>
                        {loadingText}
                    </Typography>
                }
                </Stack>
            }
            {!loading && IconPlacement==='right' && Icon && <Icon size={IconSize || 20} color={IconColor} />}
        </Button>
    )

}

export default CustomButton