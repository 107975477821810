import {Dispatch, SetStateAction, createContext, useContext} from 'react'

interface IAuthPageContext {
    openMediaMenu: boolean;
    setOpenMediaMenu: Dispatch<SetStateAction<boolean>>;
}

const AuthPageContext = createContext<IAuthPageContext>({} as IAuthPageContext);

export const useAuthPageContext = () => {
    return useContext(AuthPageContext);
};

export default AuthPageContext;