import { Divider, Skeleton, Stack, Typography } from "@mui/material"
import { neutral } from "../../../theme/palette"
import { useLayoutContext } from "../../../contexts/layoutContext"
import { useBreakpoints } from "../../../theme/breakpoints"



const StatisticsCard = ({title, count, Icon, IconColor, Image, loading}) => {
    const {miniSideBar} = useLayoutContext()
    const {sm, mdOnly, lg, md} = useBreakpoints()


    return (
        <>
        {
            !sm ?
            <Stack
            sx={{
                gap: '10px',
                bgcolor: 'neutral.50',
                boxShadow: '0 0 10px rgb(0,0,0,.125)',
                border: '1px solid',
                borderColor: 'neutral.400',
                alignSelf: 'flex-start',
                alignItems: 'center',
                p: !(!miniSideBar && mdOnly) ? '10px 30px 10px 10px' : '10px',
                borderRadius: '20px',
                width: '100%'
            }}
            >
                <Stack
                direction='row'
                sx={{gap: '7px'}}
                >
                <Stack
                sx={{
                    width: '40px', height: '40px',
                    borderRadius: '50%',
                    alignItems: 'center', justifyContent: 'center',
                    bgcolor: IconColor,
                    alignSelf: 'flex-end'
                }}
                >
                    {Icon && <Icon size={25}  color={neutral[50]}/>}
                    {Image && <img src={Image} width={25} height={25} alt="img-icon" />}
                </Stack>
                <Typography
                    sx={{
                        fontSize: (!miniSideBar && mdOnly) ? '1.8rem' : md ? '2.4rem' : (lg) ? '1.8rem' : '2.8rem',
                        fontWeight: '600',
                        color: 'neutral.600',
                        ...loading && {alignSelf: 'center'}
                    }}
                    >
                        {!loading ? count : <Skeleton width={30} height={20} />}
                </Typography>
                </Stack>

                <Stack
                sx={{
                    alignItems: 'center'
                }}
                >
                <Typography
                sx={{
                    color: 'neutral.600',
                    fontWeight: '500',
                    fontSize: '.9rem',
                    whiteSpace: 'nowrap'
                }}
                >
                    {title}
                </Typography>
                </Stack>
            </Stack> : 

            //For Mobile
            <Stack
            sx={{
                gap: '10px',
                bgcolor: 'neutral.100',
                boxShadow: '0 0 10px rgb(0,0,0,.125)',
                alignSelf: 'flex-start',
                alignItems: 'center',
                p: '10px',
                borderRadius: '20px',
                width: '100%'
            }}
            >
                <Stack 
                direction='row' 
                sx={{
                    alignSelf: 'flex-start', 
                    gap: '5px',
                    alignItems: 'center'
                }}>
                <Stack
                sx={{
                    width: '40px', height: '40px',
                    borderRadius: '7px',
                    alignItems: 'center', justifyContent: 'center',
                    bgcolor: 'primary.main',
                }}
                >
                    {Icon && <Icon size={20}  color={neutral[50]}/>}
                    {Image && <img src={Image} width={20} height={20} alt="img-icon" />}
                </Stack>
                <Typography sx={{fontWeight: 600}}>
                    {!loading ? count : <Skeleton width={30} height={20} />}
                </Typography>
                </Stack>
                <Divider sx={{borderColor: 'neutral.500', borderWidth: '1px', width: '100%'}} />
                <Typography sx={{whiteSpace: 'nowrap'}}>
                    {title}
                </Typography>
            </Stack>
        }
        </>
    )
}

export default StatisticsCard