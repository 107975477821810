import React from 'react'


import '../SupportPage/Questions.css'
import { Stack, TextField, Typography } from '@mui/material'
import CustomButton from '../../Button'
const Questions = () => {

  const form = [
    {
      title: 'Name',
      placeholder: 'Enter your name'
    },
    {
      title: 'Email',
      placeholder: 'Enter your email'
    },
    {
      title: 'Message',
      placeholder: 'Type a message',
      textarea: true
    }
  ]

  return (
    <div className='questionmain'>
      <div className='container-fluid d-flex justify-content-center align-items-center flex-column question mb-5'>
        <div className='question-content  d-flex justify-content-center align-items-center flex-column w-100'>
          <h1>Still have questions</h1>
          <p>
            We hoped the FAQs would help. Since they didn't, please fill this
            short form and we'd give you special attention as quickly as
            possible.
          </p>
          <Stack 
          component='form'
          sx={{gap: '20px'}}
          >
            {
              form.map( ({title, placeholder, textarea}) => (
                <Stack>
                <Typography
                sx={{alignSelf: 'flex-start'}}
                >
                {title}
                </Typography>
                <TextField
                title={title}
                placeholder={placeholder}
                {...textarea && {multiline: true, rows: 5}}
                />
                </Stack>
              ) )
            }

            <CustomButton
            title='Submit'
            sx={{alignSelf: 'flex-start'}}
            />
          </Stack>
        </div>
      </div>
    </div>
  )
}

export default Questions