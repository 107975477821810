import { Slide, Stack } from "@mui/material"
import MediaMenuOption from "./MediaMenuOption"
import { nav } from "../SideBar/sidebar-data"
import { useLayoutContext } from "../../../contexts/layoutContext"
import { useEffect } from "react"
import { useRef } from "react"
import { useBreakpoints } from "../../../theme/breakpoints"
import { RiCustomerService2Fill } from "react-icons/ri"
import { neutral, primary } from "../../../theme/palette"
import { LiaSignOutAltSolid } from "react-icons/lia"
import { useState } from "react"
import SignOutModal from "../../Auth/SignOut/SignOutModal"
import { MdEmail, MdWhatsapp } from "react-icons/md"
import { renimailSupportEmail, renimailSupportNumber } from "../../../utils/functions/fetchData"



const MediaMenu = () => {
    const {
        openMediaMenu,
        setOpenMediaMenu,
    } = useLayoutContext()
    const {sm} = useBreakpoints()

    useEffect( () => {
        !sm && setOpenMediaMenu(false)
    }, [sm] )



    const [openSignOutModal, setOpenSignOutModal] = useState(false)

    const userTabOptions = [
        {
            title: 'Support',
            Icon: RiCustomerService2Fill,
            IconColor: primary.main,
            action: null,
            subTabItems: [
                {
                    title: 'Email',
                    route: `mailto:${renimailSupportEmail}`,
                    Icon: MdEmail,
                    
                },
                {
                    title: 'WhatsApp',
                    route: `https://wa.me/${renimailSupportNumber}`,
                    Icon: MdWhatsapp
                },
            ]
        },
        {
            title: 'Sign Out',
            Icon: LiaSignOutAltSolid,
            IconColor: neutral[900],
            action: () => setOpenSignOutModal(true)
        }
    ]





    return (
        <>
        <Slide
        direction="down"
        mountOnEnter 
        unmountOnExit
        in={openMediaMenu}
        >
        <Stack
        className="media-menu-pop-over"
        sx={{
            bgcolor: 'primary.main',
            zIndex: 15,
            width: '150px',
            height: '380px',
            p: '10px',
            boxShadow: '0 0 10px rgb(0,0,0,.125)',
            pointerEvents: openMediaMenu ? 'auto' : 'none',
            justifyContent: 'space-between',
            position: 'fixed',
            right: 0
        }}
        onClick={(e) => e.stopPropagation()}
        >
        <Stack
        sx={{gap: '20px'}}
        >
        {
            nav.map( ({title, Icon, route, id}, k) => (
                <MediaMenuOption
                key={k}
                title={title}
                Icon={Icon}
                route={route}
                id={id}
                />
            ) )
        }
        </Stack>
        <Stack
        sx={{gap: '20px'}}
        >
        {
            userTabOptions.map( ({title, Icon, action, subTabItems}, k) => (
                <MediaMenuOption
                key={k}
                title={title}
                Icon={Icon}
                action={action}
                subTabItems={subTabItems}
                />
            ) )
        }
        </Stack>
        </Stack>
        </Slide>


        <SignOutModal 
        open={openSignOutModal}
        setOpen={setOpenSignOutModal}
        />
        </>
    )
}

export default MediaMenu