import Tim from '../Img/mr tim.png'
import Toluade from '../Img/toluade.png'
import OluImmanuel from '../Img/olu.png'
import Thatboi_KP from '../Img/kp.png'
import OlayinkaGabriel from '../Img/rema.png'
import OluMayowa from '../Img/mayowa.png'
import TImothyIlegbusi from '../Img/timothy.png'
import AkindePraise from '../Img/praise.png'
import AyanfeOlamide from '../Img/meeday.png'
import Temiloluwa from '../Img/temi.png'
import AyooluwaVictory from '../Img/ayooluwa.png'
import AgunbiadeMercy from '../Img/mercy.png'









//the team pictures and data

export const Teams = [
  {
    id: 1,
    img: Tim,
    name: 'Tim Ayodele',
    title: 'Founder + Software Engineer',
  },
  {
    id: 2,
    img: Toluade,
    name: 'Toluade',
    title: 'CoFounder/Photographer',
  },
  {
    id: 3,
    img: OluImmanuel,
    name: 'Olu Immanuel',
    title: 'Digital Marketer/Product Designer',
  },
  {
    id: 5,
    img: OlayinkaGabriel,
    name: 'Olayinka Gabriel',
    title: 'Product Manager/Researcher',
  },
  {
    id: 4,
    img: Thatboi_KP,
    name: 'Thatboi_KP',
    title: 'Product Designer/Videographer',
  },

  // {
  //   id: 6,
  //   img: OluMayowa,
  //   name: 'Olu Mayowa',
  //   title: 'Web Designer/Video Editor',
  // },
  // {
  //   id: 7,
  //   img: TImothyIlegbusi,
  //   name: 'TImothy Ilegbusi',
  //   title: 'Product Designer/Graphic Designer',
  // },
  // {
  //   id: 8,
  //   img: AkindePraise,
  //   name: 'Akinde Praise',
  //   title: 'Product Designer/Graphic Designer',
  // },
  // {
  //   id: 9,
  //   img: AyanfeOlamide,
  //   name: 'Ayanfe Olamide',
  //   title: 'Product Designer/Graphic Designer',
  // },
  // {
  //   id: 10,
  //   img: Temiloluwa,
  //   name: 'Temiloluwa',
  //   title: 'Web Developer/Designer',
  // },
  {
    id: 11,
    img: AyooluwaVictory,
    name: 'Ayooluwa Victory',
    title: 'Product Designer',
  },
  // {
  //   id: 12,
  //   img: AgunbiadeMercy,
  //   name: 'Agunbiade Mercy',
  //   title: 'Product Designer/Graphic Designer',
  // },
]
