import React from 'react'
// import Creators from '../Components/Landingspage/Homepage/Creators'
import Hero from '../Components/Landingspage/Homepage/Hero'
import Tabs from '../Components/Landingspage/Homepage/Tabs'
import WhyUs from '../Components/Landingspage/Homepage/WhyUs'
import Youtube from '../Components/Landingspage/Homepage/Youtube'
import Future from '../Components/Landingspage/Homepage/Future'
import Layout from '../Components/Landingspage/Layout'
import Creators from '../Components/Landingspage/Homepage/Creators/index'



const Home = () => {
  return (
    <>
        <Layout>
          <Hero />
          {/* <Youtube /> */}
          <WhyUs />
          <Tabs />
          <Creators />
          <Future />
        </Layout>
    </>
  )
}

export default Home