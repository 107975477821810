import React from 'react'
import Heroimg from '../Img/Hero_img.png'
import { Link } from 'react-router-dom'

import '../Homepage/Hero.css'
import CustomButton from '../../Button'
import { FaArrowRight } from 'react-icons/fa'
import { Box, Stack, Typography } from '@mui/material'
import { useBreakpoints } from '../../../theme/breakpoints'
import useMediaQuery from '../../../hooks/useMediaQuery'

const Hero = () => {
  const {md} = useBreakpoints()
  const userData = JSON.parse(localStorage.getItem('userData'))
  
  return (
    
    <div className='mainhero'>
      <div className='container-fluid d-flex justify-content-between cont hero'>
        <Stack 
        className='hero_text'
        sx={useMediaQuery({
          gap: '20px',
          '& h1, p': {
            color: 'neutral.50'
          },
          p: {md: '1rem !important',  default: '1rem 3rem !important'},
        })}
        >
          <Typography 
          variant='h1'
          sx={{
            fontSize: '3rem !important', 
            fontWeight: 900,

          }}
          >
          Your All-in-One Email Marketing Solution for Africa
          </Typography>
          <Typography
          sx={{
            fontSize: '1.3rem',
            fontWeight: 500
          }}
          >
            Craft engaging emails that connect with your audience.
            </Typography>
            <Stack
            sx={useMediaQuery({
              gap: '50px',
              flexDirection: {sm: 'column', md: 'row', lg: 'column', default: 'row'}
            })}
            >
            <CustomButton
            link
            linkProps={{to: userData ? '/pricing' : '/register'}}
            variant='outlined'
            sx={{
              borderRadius: '5px', 
              textTransform: 'unset', 
              p: '15px 20px', 
              mt: '20px', 
              mx: md ? 'auto' : 'unset',
              alignSelf: 'flex-start',
            }}
            >
              <Typography variant='h6' sx={{color: 'primary.main'}}>
                {userData ? 'Upgrade Your Account' : 'Try ReniMail For Free'}
              </Typography>
            </CustomButton>
            <CustomButton
            link
            linkProps={{to: '/pricing'}}
            variant='normal'
            sx={{
              borderRadius: '5px', 
              textTransform: 'unset', 
              p: '15px 20px', 
              mt: '20px', 
              mx: md ? 'auto' : 'unset',
              alignSelf: 'flex-start',
            }}
            >
              <Typography variant='h6' sx={{color: 'secondary.main', textDecoration: 'underline'}}>
                Request a demo
              </Typography>
            </CustomButton>
            </Stack>
        </Stack>
        <div className='heroimg d-none d-lg-block'>
          <img src={Heroimg} style={{objectFit: 'cover'}} alt='HERO IMG' className='' />
        </div>
      </div>
    </div>
  )
}

export default Hero