import { Stack } from "@mui/material"
import { useNavigate } from "react-router-dom"



const Logo = ({noRedirect=false}) => {
    const navigate = useNavigate()

    return (
        <Stack
        sx={{
            width: 400/8, height: 392/8,
            cursor: 'pointer',
            border: '2px solid',
            borderColor: 'neutral.50',
            borderRadius: '8px',
            overflow: 'hidden'
        }}
        onClick = {() => !noRedirect && navigate('/dashboard')}
        >
            <img 
            src='/assets/images/Renimail-logo.png'
            alt="reni-logo" 
            style={{width: '100%', height: '100%', objectFit: 'contain'}} 
            />
        </Stack>
    )
}

export default Logo