import { Stack, TextField, Typography } from "@mui/material"
import { useBreakpoints } from "../../../../../../theme/breakpoints"
import { useSettingsPageContext } from "../../../../../../contexts/settingsPageContext"
import CustomButton from "../../../../../Button"
import { fetchData } from "../../../../../../utils/functions/fetchData"
import { toast } from "react-hot-toast"
import { useState } from "react"
import Logo from "../../../../../Logo"
import client from "../../../../../../utils/axios"



const OTPScreen = () => {
    const {sm} = useBreakpoints()
    const {
        currentTab,
        paymentDetails,
        setPaymentDetails,
        planDetails,
        setOpenOTPModal,
        setOpenAVSModal
    } = useSettingsPageContext()
    const [loading, setLoading] = useState(false)


    const submitOTP = async (e) => {
        e.preventDefault()
        setLoading(true)
        try{
            const {data, message} = await client.post('/chargeCard', {
                "transaction_reference": [paymentDetails.transactionRef],
                "authorization": {
                    "otp": [paymentDetails.otp]
                }
            })
            
            if(data.message !== 'Processing'){
                return toast.success(message)
            }
        }
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setLoading(false)
        }
    }
    

    return (
        <form
        noValidate
        onSubmit={submitOTP}
        >
        <Stack
        sx={{
            gap: '20px'
        }}
        >
            <Stack
            sx={{alignSelf: 'center'}}
            >
            <Logo noRedirect />
            </Stack>

            <Stack
            sx={{
                gap: '20px'
            }}
            >
            <Typography sx={{textAlign: 'center'}}>
            An OTP has been sent to the phone number and email registered with this Card. Enter it below to complete this transaction
            </Typography>


            <UserInput 
            title='OTP:'
            placeholder='123 456'
            val={paymentDetails.otp}
            setVal={(val) => setPaymentDetails( (prevVal) => ({...prevVal, otp: val}))}
            />
            </Stack>

            <CustomButton 
            title={`Continue`}
            sx={{alignSelf: 'center'}}
            loading={loading}
            loadingText='Processing'
            // onClick={submitOTP}
            type='submit'
            />
        </Stack>
        </form>
    )
}

export default OTPScreen








const textFieldStyle = {
    '& .MuiInputBase-root': {
        borderRadius: '8px'
    },
    '& .Mui-focused': {
        // border: 'unset',
    },
    '& input': {
        bgcolor: 'white',
        color: '#000',
        p: '5px 15px',
        minHeight: '30px'
    },
    '& input:hover': {
        borderColor: 'primary.main'
    },
}


const UserInput = ({title, val, setVal, placeholder, maxLength}) => {
    const {xs} = useBreakpoints()
    

    return (
        <Stack
        sx={{gap: '3px'}}
        >
        <Typography>{title}</Typography>
        <TextField
        fullWidth
        sx={textFieldStyle}
        value={val}
        onChange={(e) => {
            if(e.target.value !== '' && !((/^[0-9]+$/).test(e.target.value))){
                return
            }
            if(maxLength && (e.target.value).length > maxLength){
                return
            }
            return setVal(e.target.value)
        }}
        type='text'
        placeholder={placeholder}
        />
        </Stack>
    )
}