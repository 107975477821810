import axios from "axios";
import { getUserToken, objectToFormData, perPage } from "../functions/fetchData";

const client = axios.create({
    baseURL: process.env.REACT_APP_API_V2,
})


client.interceptors.response.use(
    (response) => {
        let data = response.data

        // To decode data received
        if (typeof data === 'string') {
            try{
                const decodedString = atob(data)
                data = JSON.parse(decodedString)
            } catch(err) {}
        }

        // Begin Code to Cache Responses
        let resToBeCached = response.config.useCache
        if(resToBeCached && data?.success){
            let cacheExp = response.config.cacheExpiry || (1000 * 60 * 60 * 24) // Cache expiry defaults to 24 hours
            let expDate = new Date()
            expDate.setTime(expDate.getTime() + cacheExp)

            let reqUrl = response.config.url
            let cachedResponses = JSON.parse(localStorage.getItem('CACHED_RESPONSES')) || {}
            let newCacheResponses = {
                ...cachedResponses,
                [reqUrl.replace(/\.[^/.]+$/, '')]: {...data, expires: expDate},
            }
            localStorage.setItem('CACHED_RESPONSES', JSON.stringify(newCacheResponses))
        }
        // End
        
        if(data?.success || response.config.noError){
            return data
        }
        const customError = new Error(data?.message || 'An error occurred');
        return Promise.reject(customError)
    },
    error => {
        if (error.response) {

            // For cached responses
            if(error.response?.status === 200){
                return Promise.resolve(error.response);
            }


            let errData = error.response.data
            if(errData){
                if (typeof errData === 'string') {
                    try{
                        const decodedString = atob(errData)
                        error.response.data = JSON.parse(decodedString)
                    } catch(err) {}
                }
            }
            
            const customError = new Error(error.response?.data?.message || error?.message || 'An error occurred');
            return Promise.reject(customError);
        }
        else if (error.request) {
            const customError = new Error((error.code || error.message) ? `${error?.code} | ${error?.message}` : 'No response received from server');
            return Promise.reject(customError);
        } 
        else {
            return Promise.reject(error);
        }
    }
)

client.interceptors.request.use(
    (reqConfig) => {
        if(!reqConfig.noUserToken){
            reqConfig.data = {...reqConfig.data||{}, usertoken: getUserToken()}
        }

        // For pagination
        if(reqConfig.pagination){
            let pagination = reqConfig.pagination
            const start = perPage * (pagination.page-1) + 1
            const end = perPage * pagination.page + 1

            reqConfig.data = {...reqConfig.data||{}, start, end}
        }

        if(reqConfig.useFormData && typeof reqConfig.data ==='object'){
            reqConfig.data = objectToFormData(reqConfig.data)
        }

        // To encode data sent
        if (reqConfig.data && !reqConfig.useFormData) {
            const jsonString = JSON.stringify(reqConfig.data);
            const encodedString = btoa(jsonString);
            reqConfig.data = encodedString;
        }

        // For request caching
        if((reqConfig.useCache || reqConfig.useBackgroundFetch) && !reqConfig.usingBackgroundFetch){
            const cachedResponses = JSON.parse(localStorage.getItem('CACHED_RESPONSES'))

            if(reqConfig.useBackgroundFetch){
                let newReqConfig = reqConfig
                newReqConfig['useCache'] = true
                newReqConfig['useBackgroundFetch'] = false
                newReqConfig['usingBackgroundFetch'] = true
                newReqConfig['data'] = JSON.parse(atob(newReqConfig['data']))
                newReqConfig['cacheExpiry'] = 1000 * 60 * 60 * 24 * 30
                try{
                    client(newReqConfig)
                }
                catch(err){}
            }

            if(cachedResponses){
                const cachedData = cachedResponses[reqConfig.url]
                if(cachedData){

                    let expDate = new Date(cachedData?.expires)
                    let expired = expDate < new Date()
                    if(!expired){
                        return Promise.reject({
                            config: reqConfig,
                            response: {
                                status: 200,
                                ...cachedData,
                            }
                        })
                    }
                }
            }
        }


        if(reqConfig.isReniAuth){
            reqConfig.baseURL = process.env.REACT_APP_RENI_AUTH_ENDPOINT
        }
        else{
            let apiVersion = reqConfig.version
            if(apiVersion){
                reqConfig.baseURL = apiVersion===3 ? process.env.REACT_APP_API_V3 : process.env.REACT_APP_API_V2
            }
        }



        // App token added to headers
        reqConfig.headers = {
            ...reqConfig.headers,
            Authorization: `Bearer ${!reqConfig.isReniAuth ? process.env.REACT_APP_API_APPTOKEN : process.env.REACT_APP_RENI_AUTHTOKEN}`
        }


        // Temporary server bug fix
        let url = reqConfig.url
        reqConfig.url = url.includes('.php') ? url : url + '.php'


        return reqConfig
    }
)



export default client