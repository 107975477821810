import React from 'react'
import {AiFillPlayCircle} from "react-icons/ai";
import AboutHeroImg from '../Img/Group 335.png'


import '../AboutPage/HeroAbout.css'
const HeroAbout = () => {
  return (
    <div className='mainheroabout'>
      <div className='container-fluid d-flex flex-column flex-lg-row justify-content-between cont hero-about'>
        <div className='hero_Abouttext'>
          <h1>
            Meet the masterminds at <span>ReniMail</span>
          </h1>
          <p className='tech-firm'>
            We’re not just a tech firm. We're here to help you expand your
            business with the best email tools
          </p>
          <div className='play'>
            <AiFillPlayCircle className='play-icon' />
            <p>
              Find out how we started, and what we are currently up to. Watch
              video
            </p>
          </div>
        </div>
        <div className='hero-aboutimg'>
          <img src={AboutHeroImg} alt='HERO IMG' className='img-fluid' />
        </div>
      </div>
    </div>
  )
}

export default HeroAbout