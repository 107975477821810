import { createContext } from 'react';
import { useContext } from 'react';

const LoginPageContext = createContext();


export const useLoginPageContext = () => {

    return useContext(LoginPageContext)
}

export default LoginPageContext