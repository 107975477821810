import { FaHome } from "react-icons/fa";
import { MdOutlineHelp, MdPermDeviceInformation, MdSubscriptions } from "react-icons/md";



export const nav = [
    {
        title: 'Home',
        route: '/',        
        Icon: FaHome,
        id: 'home'
    },
    {
        title: 'Pricing',
        route: '/pricing',
        Icon: MdSubscriptions,
        id: 'pricing'
    },
    {
        title: 'About Us',
        route: '/about',
        Icon: MdPermDeviceInformation,
        id: 'aboutus'
    },
    {
        title: 'Support',
        route: '/support',
        Icon: MdOutlineHelp,
        id: 'support'
    },
]