import React from 'react'
import whyusimg from '../Img/Rectangle 18.png'

import '../Homepage/WhyUs.css'
import { IoMdDoneAll } from 'react-icons/io';
import { Box } from '@mui/material';
import withMediaQuery from '../../../hooks/useMediaQuery';
const WhyUs = () => {

  const reasons = [
    {
      title: 'Create personalized emails',
      subtitle: 'that resonate with your subscribers.'
    },
    {
      title: 'Track email performance',
      subtitle: 'to understand what works and what doesn\'t.'
    },
    {
      title: 'Schedule emails',
      subtitle: 'in advance for a streamlined workflow.'
    },
    {
      title: 'Manage your subscriber list',
      subtitle: 'effortlessly, including opt-ins and unsubscribers.'
    },
    {
      title: 'Gain valuable insights',
      subtitle: 'with powerful analytics and business intelligence tools.'
    },
    {
      title: 'Integrate seamlessly',
      subtitle: 'with your favorite third-party platforms.'
    },
    {
      title: 'Pay in Naira',
      subtitle: 'for a convenient experience.'
    }
  ];
  

  return (
    <div className='container-fluid d-flex justify-content-between align-items-center why'>
      <div className='whyus_img d-none d-lg-block'>
        <img src={whyusimg} alt='logo pic' className='img-fluid' />
      </div>
      <div className='whyus_text'>
        <h3>Why Choose Reni?</h3>
        <p className='focus'>
          We&apos;re focused on helping you build your brand, grow your business,
          increase your visibility and reach the right audience.
        </p>
        <div className='reasons'>
          {
          reasons.map( ({title, subtitle}, k) => (
            <div key={k} className='first_reason'>
              <div className='index' style={{flexShrink: 0}}>
              <Box 
              component={IoMdDoneAll} 
              sx={withMediaQuery({
                fontSize: {sm: '1.2rem', default: '2rem'}
              })}/>
              </div>
              <div className='reason'>
                <h4>{title}</h4>
                <p>{subtitle}</p>
              </div>
            </div>
          ) )
          }
        </div>
      </div>
    </div>
  )
}

export default WhyUs