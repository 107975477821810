import { Stack } from '@mui/material'
import bgImage from '../Images/Rectangle 75.png'
import Header2 from '../Header/header'
import { useState } from 'react'
import AuthPageContext from '../../contexts/authPageContext'
import MediaMenu from '../Header/MediaMenu'
import { useBreakpoints } from '../../theme/breakpoints'


const Layout = ({children}) => {
    const {md} = useBreakpoints()
    const [openMediaMenu, setOpenMediaMenu] = useState(false)

    return (
        <AuthPageContext.Provider
        value={{
            openMediaMenu,
            setOpenMediaMenu
        }}
        >
        <Stack
        sx={{
            minHeight: '100vh',
            position: 'relative',
        }}
        >
            <Header2 />
            <Stack>
            <MediaMenu />
            </Stack>
            <Stack
            sx={{
                width: '100%',
                minHeight: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundImage: `url("${bgImage}")`,
                backgroundSize: 'cover',
                backgroundPosition: 'top center',
                p: '20px 10px',
                mt: md ? '83px' : 0
            }}
            >
            {children}
            </Stack>
        </Stack>
        </AuthPageContext.Provider>
    )
}


export default Layout