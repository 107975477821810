import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, IconButton, Stack, Typography  } from '@mui/material';
import { shortenTextByCharacters } from '../../../../utils/formatText';
import { IoCopy } from 'react-icons/io5';
import copyToClipboard from '../../../../utils/functions/copyToClipboard';
import InfoView from '../../InfoView';
import { FaInfoCircle } from 'react-icons/fa';
import { useBreakpoints } from '../../../../theme/breakpoints';
import { primary } from '../../../../theme/palette';
import { useState } from 'react';

const CustomEmailDNS = ({ data, loading=true }) => {
  const {sm} = useBreakpoints()
  const [openInfo, setOpenInfo] = useState(false)

  const INFO = () => (
    <Typography sx={{ fontWeight: 500 }}>
      <strong><i>{"After generating the SPF, DKIM, and DMARC records for your domain name, the next step is to add these records to your domain's DNS settings."}</i></strong><br /><br />
  
      {"Here's what you should do next:"}<br />
  
      <strong>1. Access DNS Management:</strong><br />
      {"You should log in to your domain registrar or web hosting provider's account dashboard, most likely CPANEL."}<br />
  
      <strong>2. Locate DNS Settings:</strong><br />
      {"Within the account dashboard, you should look for options related to DNS management or DNS settings. This is where you can add or modify DNS records for your domain."}<br />
  
      <strong>3. Add TXT Records:</strong><br />
      {"- For SPF: Add a TXT record with the SPF policy generated by ReniMail. Set the hostname to your domain name (as seen under the 'SPF NAME' column above) and paste the SPF record value (as seen under the 'SPF Value')."}<br />
      {"- For DKIM: Add two TXT records: one for the DKIM private key and another for the DKIM public key. Set the hostname to a selector followed by your domain name (as seen under the 'DKIM Private Key Name' column) and paste the respective key values."}<br />
      {"- For DMARC: Add a TXT record with the DMARC policy generated by ReniMail. Set the hostname to what you have under the 'DMARC Name' column and paste the DMARC record value as seen under the 'DMARC Value' column."}<br />
  
      <strong>4. Save Changes:</strong><br />
      {"After adding the TXT records for each hostname as seen on the table, save the changes. The DNS changes may take some time to propagate across the internet (DNS propagation), typically up to 24-48 hours."}<br />
  
      <strong>5. Do for all three Hostnames:</strong><br />
      {"Note that there are three hostnames we have generated for you: renimail.com, api.renimail.com, and staging.renimail.com. You will have to repeat the same process for all these hostnames."}<br />
  
      {"By adding these records to your domain's DNS settings, you help improve and approve email deliverability, security, and authenticity for your domain. These records help prevent email spoofing, ensure message integrity, and specify how unauthenticated emails should be handled."}
    </Typography>
  )
    
  return (
    <>
    <Stack>
    <Stack
    direction='row'
    sx={{gap: '20px', alignItems: 'center'}}
    >
      <Typography variant='h6'>
        DNS Records
      </Typography>
      <IconButton
      sx={{width: 'fit-content', color: primary.main}}
      onClick={() => setOpenInfo(true)}
      >
      <FaInfoCircle size={sm ? 18 : 22} />
      </IconButton>
    </Stack>
    <TableContainer component={Paper} sx={{ maxWidth: '100%', marginTop: '20px' }}>
      <Table>
        <TableHead>
          <TableRow>
            {/* <TableCell sx={{whiteSpace: 'nowrap'}}>Host Name</TableCell> */}
            <TableCell sx={{whiteSpace: 'nowrap'}}>SPF Name</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>SPF Value</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>DKIM Private Key Name</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>DKIM Private Key Value</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>DKIM Public Key Name</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>DKIM Public Key Value</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>DMARC Name</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>DMARC Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
        loading ?
        Array.from({length: 3}).map((_, k) => (
            <TableRow key={k}>
              {/* <TableCell><Skeleton /></TableCell> */}
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
            </TableRow>
        ))
        :
        (Array.isArray(data) ? data : [data]).map((row, index) => (
            <TableRow key={index}>
              {/* <TableCell>
                <Box>
                {shortenTextByCharacters(row.hostName, 25)}
                </Box>
                <IconButton 
                onClick={() => copyToClipboard(row.hostName, 'Value copied to clipboard')}
                ><IoCopy size={10} /></IconButton>
              </TableCell> */}
              <TableCell>
                <Box>
                {shortenTextByCharacters(row.SPF.Name, 25)}
                </Box>
                <IconButton 
                onClick={() => copyToClipboard(row.SPF.Name, 'Value copied to clipboard')}
                ><IoCopy size={10} /></IconButton>
              </TableCell>
              <TableCell>
                <Box>
                {shortenTextByCharacters(row.SPF.Value, 25)}
                </Box>
                <IconButton 
                onClick={() => copyToClipboard(row.SPF.Value, 'Value copied to clipboard')}
                ><IoCopy size={10} /></IconButton>
              </TableCell>
              <TableCell>
                <Box>
                {shortenTextByCharacters(row.DKIM.privateKey.Name, 25)}
                </Box>
                <IconButton 
                onClick={() => copyToClipboard(row.DKIM.privateKey.Name, 'Value copied to clipboard')}
                ><IoCopy size={10} /></IconButton>
              </TableCell>
              <TableCell>
                <Box>
                {shortenTextByCharacters(row.DKIM.privateKey.Value, 25)}
                </Box>
                <IconButton 
                onClick={() => copyToClipboard(row.DKIM.privateKey.Value, 'Value copied to clipboard')}
                ><IoCopy size={10} /></IconButton>
              </TableCell>
              <TableCell>
                <Box>
                {shortenTextByCharacters(row.DKIM.publicKey.Name, 25)}
                </Box>
                <IconButton 
                onClick={() => copyToClipboard(row.DKIM.publicKey.Name, 'Value copied to clipboard')}
                ><IoCopy size={10} /></IconButton>
              </TableCell>
              <TableCell>
                <Box>
                {shortenTextByCharacters(row.DKIM.publicKey.Value, 25)}
                </Box>
                <IconButton 
                onClick={() => copyToClipboard(row.DKIM.publicKey.Value, 'Value copied to clipboard')}
                ><IoCopy size={10} /></IconButton>
              </TableCell>
              <TableCell>
                <Box>
                {shortenTextByCharacters(row.DMARC.Name, 25)}
                </Box>
                <IconButton 
                onClick={() => copyToClipboard(row.DMARC.Name, 'Value copied to clipboard')}
                ><IoCopy size={10} /></IconButton>
              </TableCell>
              <TableCell>
                <Box>
                {shortenTextByCharacters(row.DMARC.Value, 25)}
                </Box>
                <IconButton 
                onClick={() => copyToClipboard(row.DMARC.Value, 'Value copied to clipboard')}
                ><IoCopy size={10} /></IconButton>
              </TableCell>
            </TableRow>
          ))
        }
        </TableBody>
      </Table>
    </TableContainer>
    </Stack>


    <InfoView
    dialog
    open={openInfo}
    setOpen={setOpenInfo}
    >
    <INFO />
    </InfoView>
    </>
  );
};

export default CustomEmailDNS;
