import { Stack } from "@mui/material"
import { keyframes } from "@emotion/react"
import styled from "@emotion/styled"
import { useBreakpoints } from "../../theme/breakpoints"

const rotate = keyframes`
  0% {
        transform: perspective(250px) rotateX(0deg) rotateY(0deg);
    }

    10% {
        transform: perspective(250px) rotateX(180deg) rotateY(0deg);
    }

    20% {
        transform: perspective(250px) rotateX(180deg) rotateY(-180deg);
    }
    30% {
        transform: rotate(0deg);
    }
    70% {
        transform: rotate(360deg);
    }
    80% {
        transform: perspective(250px) rotateX(0deg) rotateY(0deg);
    }

    90% {
        transform: perspective(250px) rotateX(180deg) rotateY(0deg);
    }

    100% {
        transform: perspective(250px) rotateX(180deg) rotateY(-180deg);
    }
`


const Image = styled.img`
  animation: ${rotate} 5s linear infinite;
`

const Loader = () => {
    const {xs, sm, md} = useBreakpoints()
  return (
    <Stack
      sx={{
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
      }}
    >
      <Image 
        src="/assets/images/Renimail-logo.png"
        alt='reni-logo'
        width={xs ? 400/3 : sm ? 400/2.5 : 400/2}
        height={xs ? 392/3 : sm ? 392/2.5 : 392/2}
      />
    </Stack>
  )
}

export default Loader
