import { Stack, Typography } from "@mui/material"
import { useBreakpoints } from "../../../../theme/breakpoints"



const Creator = ({imageSrc, name, jobTitle}) => {
    const {sm, md, lg} = useBreakpoints()

    return (
        <Stack sx={{gap: '15px', alignItems: 'center'}}>
            <Stack
            sx={{
                width: sm ? '130px' : md ? '150px' : lg ? '170px' : '200px',
                height: sm ? '130px' : md ? '150px' : lg ? '170px' : '200px',
                borderRadius: '50%'
            }}
            >
            <img src={imageSrc} alt="creator-img" style={{width: '100%', height: '100%', objectFit: 'cover'}} />
            </Stack>
            <Typography variant={md ? "h6" : "h5"} sx={{color: 'primary.main', whiteSpace: 'nowrap'}}>{name}</Typography>
            <Typography sx={{fontSize: md ? '.9rem' : '1.05rem', whiteSpace: 'nowrap'}}>{jobTitle}</Typography>
        </Stack>
    )
}

export default Creator